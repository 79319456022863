// stores/eventCreation.ts
import { defineStore } from 'pinia';
import {
  EventLocation,
  EventData,
  Position,
  DayIndicator,
} from '@/types/events';
import apiClient from '@/services/axios';
import {
  getCurrentTime,
  getCurrentTimeWithSeconds,
  formatDate,
} from '@/utils/timeUtils';
import { useAdminStore } from './admin';

function normalizeSpaces(str: string): string {
  return str.replace(/\s+/g, ' ').trim();
}

function removeExtraSymbols(str: string | null | undefined): string {
  if (!str) return '';
  return str.replace(/\n/g, '');
}

// Store interface
interface EventState {
  selectedId: number;
  description: string;
  chatMessage: string;
  eventMessage: string;
  notesMessage: string;
  editMessage: string;
  location: EventLocation | null;
  locationMessage: string;
  loading: boolean;
  position: Position | null;
  type: string;
  time: string;
  dayIndicator: DayIndicator;
}

export const useEventCreationStore = defineStore('eventCreation', {
  state: (): EventState => ({
    selectedId: -1,
    description: '',
    chatMessage: '',
    eventMessage: '',
    notesMessage: '',
    editMessage: '',
    location: null,
    locationMessage: '',
    loading: false,
    position: null,
    type: '',
    time: '',
    dayIndicator: DayIndicator.CURRENT,
  }),

  getters: {
    formattedChatData: (state) => {
      return (position?: Position, chatMessage?: string) => {
        const resolvedPosition = position || state.position;

        const adminStore = useAdminStore();
        const user = adminStore.currentUser!;
        const squadron = adminStore.squadrons.find(
          (s) => s.id === user.squadronId,
        );
        const battalion = adminStore.battalions.find(
          (b) => b.id === squadron?.battalionId,
        );

        const description = resolvedPosition?.description
          ? ` (${resolvedPosition.description})`
          : '';
        let chatData = `Доповідь від ${resolvedPosition?.name}${description}\n`;

        chatData += `Подія: ${state.eventMessage}\n\n`;

        chatData += `${chatMessage ?? state.chatMessage}`;

        if (state.locationMessage) {
          chatData += `${state.locationMessage}`;
        }

        if (state.notesMessage) {
          chatData += `${state.notesMessage}`;
        }

        const time = state.time
          ? `${state.time}.00`
          : getCurrentTimeWithSeconds();
        chatData += `\n\n${time} ${formatDate(state.dayIndicator, 'en-GB')}\n`;
        chatData += `Черговий: ${user.callsign}${
          squadron ? `, ${squadron.name}` : ''
        }`;
        chatData += battalion ? ` (${battalion.name})` : '';

        return chatData.replace(/\n{2,}/g, '\n\n');
      };
    },
  },

  actions: {
    async createEvent(
      position: Position,
      linkedObject: {},
    ): Promise<{ success: boolean; message: string }> {
      this.loading = true;
      this.position = position; // Set position for chat data formatting

      try {
        const eventData = this.prepareEventData(position, linkedObject);
        await apiClient.createEvent(eventData);

        if (!position.isCustomPosition) {
          await this.updatePositionStatus(position);
        }

        return { success: true, message: 'Подію успішно створено' };
      } catch (error) {
        console.error('Error creating event:', error);
        return { success: false, message: 'Помилка при створенні події' };
      } finally {
        this.loading = false;
      }
    },

    async updatePositionStatus(position: Position) {
      await apiClient.editPosition(position.id, {
        isUnderAttack: position.isUnderAttack,
        isUnderFire: position.isUnderFire,
      });
    },

    prepareEventData(position: Position, linkedObject: {}): EventData {
      return {
        type: this.type,
        description: removeExtraSymbols(
          normalizeSpaces(`${this.description ?? this.eventMessage}`),
        ),
        locationLat: this.location?.lat || null,
        locationLong: this.location?.lng || null,
        notes: this.notesMessage,
        time: `${formatDate(this.dayIndicator)} ${
          this.time || getCurrentTime()
        }:00.000`,
        positionId: position.id,
        distance: this.location?.distance ?? -1,
        azimuth: this.location?.azimuth ?? -1,
        mgrs: this.location?.mgrs || 'N/A',
        positionName: position.positionName?.trim() || position.name,
        isCustomPosition: position.isCustomPosition || false,
        selectedId: this.selectedId,
        chatData: this.editMessage || this.formattedChatData(),
        ...linkedObject,
      };
    },

    reset() {
      this.$reset();
    },
  },
});
