import { EventType } from '@/types/events';

type MissionType = 'Ударна' | 'Мінування' | 'Доставка' | 'РКС';

function targetHelper({
  children,
  canShowStream,
}: {
  children: EventType[];
  canShowStream?: boolean;
}): EventType[] {
  const coordinatesSquareTargetDescription: EventType[] = [
    {
      name: 'Координати або квадрат',
      type: 'coordinates-square-target-destruction',
      linkedObject: {
        parentObject: 'uavData',
      },
      children,
      canShowEventPath: false,
      startCreatedAtEventPath: true,
    },
  ];

  return [
    {
      name: 'ОС',
      type: 'constant',
      displayType: 'Ціль: ОС',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ОС',
      },
      children: [
        {
          name: 'Координати або квадрат',
          type: 'coordinates-square-target-destruction',
          linkedObject: {
            parentObject: 'uavData',
          },
          children: resultHelper({
            missionType: 'Ударна',
            isPersonnel: true,
            canShowStream,
          }),
        },
      ],
    },
    {
      name: 'ІФС',
      type: 'constant',
      displayType: 'Ціль: ІФС',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ІФС',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Автомобільна техніка',
      type: 'constant',
      children: [
        {
          name: 'ВАТ',
          type: 'constant',
          displayType: 'Ціль: Автомобільна техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Автомобільна техніка',
            targetDetails: 'ВАТ',
          },

          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'ЛАТ',
          type: 'constant',
          displayType: 'Ціль: Автомобільна техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Автомобільна техніка',
            targetDetails: 'ЛАТ',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Мотоцикл',
          type: 'constant',
          displayType: 'Ціль: Автомобільна техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Автомобільна техніка',
            targetDetails: 'Мотоцикл',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Квадроцикл',
          type: 'constant',
          displayType: 'Ціль: Автомобільна техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Автомобільна техніка',
            targetDetails: 'Квадроцикл',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Спец. техніка',
          type: 'constant',
          displayType: 'Ціль: Автомобільна техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Автомобільна техніка',
            targetDetails: 'Спец. техніка',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Інше',
          type: 'input-without-display-type',
          displayType: 'Ціль: Автомобільна техніка',
          shouldDisplayInputValue: true,
          linkedObject: {
            parentObject: 'uavData',
            targetDetails: '',
            target: 'Автомобільна техніка',
          },
          children: coordinatesSquareTargetDescription,
        },
      ],
    },
    {
      name: 'Арт. система',
      type: 'constant',
      children: [
        {
          name: 'Міномет',
          type: 'constant',
          displayType: 'Ціль: Арт. система',
          linkedObject: {
            parentObject: 'uavData',
            targetDetails: 'Міномет',
            target: 'Арт. система',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Букс. Артилерія',
          type: 'constant',
          displayType: 'Ціль: Арт. система',
          linkedObject: {
            parentObject: 'uavData',
            targetDetails: 'Букс. Артилерія',
            target: 'Арт. система',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'САУ',
          type: 'constant',
          displayType: 'Ціль: Арт. система',
          linkedObject: {
            parentObject: 'uavData',
            targetDetails: 'САУ',
            target: 'Арт. система',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Інше',
          type: 'input-without-display-type',
          displayType: 'Ціль: Арт. система',
          shouldDisplayInputValue: true,
          linkedObject: {
            parentObject: 'uavData',
            targetDetails: '',
            target: 'Арт. система',
          },
          children: coordinatesSquareTargetDescription,
        },
      ],
    },
    {
      name: 'ББМ',
      type: 'constant',
      displayType: 'Ціль: ББМ',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ББМ',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Танк',
      type: 'constant',
      displayType: 'Ціль: Танк',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Танк',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Радіотехнічне обладнання',
      type: 'constant',
      displayType: 'Ціль: Радіотехнічне обладнання',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Радіотехнічне обладнання',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Склади (БП та ПММ)',
      type: 'constant',
      displayType: 'Ціль: Склади (БП та ПММ)',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Склади (БП та ПММ)',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'ТЗ БпЛА',
      type: 'constant',
      displayType: 'Ціль: ТЗ БпЛА',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ТЗ БпЛА',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'ППО',
      type: 'constant',
      displayType: 'Ціль: ППО',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ППО',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'РСЗВ',
      type: 'constant',
      displayType: 'Ціль: РСЗВ',
      linkedObject: {
        parentObject: 'uavData',
        target: 'РСЗВ',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Плавзасіб',
      type: 'constant',
      displayType: 'Ціль: Плавзасіб',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Плавзасіб',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Інша ціль',
      type: 'input',
      displayType: 'Ціль: ',
      linkedObject: {
        parentObject: 'uavData',
        target: '',
      },
      shouldDisplayInputValue: true,
      children: coordinatesSquareTargetDescription,
    },
  ].map((i) => ({
    ...i,
    canShowEventPath: false,
    startCreatedAtEventPath: true,
  })) as EventType[];
}

function typeOfAmmunitionHelper({
  isFPV,
  children,
}: {
  isFPV: boolean;
  children: EventType[];
}): EventType[] {
  // trigger
  const typeOfAmmunitions: string[] = [
    'Уламковий',
    'Фугасний',
    'Уламково-фугасний',
    'Кумулятивний',
    'Уламково-кумулятивний',
    'Кумулятивно-фугасний',
    'Запалювальний',
    'Термобаричний',
    'Аерозольно-маскувальний',
    'Міна протипіхотна',
    'Міна протитанкова',
  ];
  if (isFPV) {
    return typeOfAmmunitions.map((name) => ({
      name,
      type: 'constant',
      displayType: `БЧ: ${name}`,
      canShowEventPath: true,
      startCreatedAtEventPath: true,
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: name,
      },
      children: [
        {
          name: 'Марка та к-сть БК',
          type: 'input-model-count',
          linkedObject: {
            parentObject: 'uavData',
            ammunitionType: name,
          },
          children,
        },
      ],
    }));
  }
  return [
    {
      name: 'БК',
      type: 'input-type-model-count',
      linkedObject: {
        parentObject: 'uavData',
      },
      typeOfAmmunitions,
      children,
      canShowEventPath: true,
      startCreatedAtEventPath: true,
    },
  ];
}

function missionHelper({
  missionType,
  children,
}: {
  missionType: MissionType;
  children: EventType[];
}): EventType {
  switch (missionType) {
    case 'Ударна':
      return {
        name: 'Ударна',
        type: 'constant',
        displayType: 'Місія: Ударна',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Ударна',
        },
        children,
      };
    case 'Мінування':
      return {
        name: 'Мінування',
        type: 'constant',
        displayType: 'Місія: Мінування',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Мінування',
        },
        children,
      };
    case 'Доставка':
      return {
        name: 'Доставка',
        type: 'constant',
        displayType: 'Місія: Доставка',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Доставка',
        },
        children,
      };
    case 'РКС':
      return {
        name: 'РКС',
        type: 'constant',
        displayType: 'Місія: РКС',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'РКС',
        },
        children,
      };
  }
}

function resultHelper({
  missionType,
  isPersonnel,
  canShowStream,
}: {
  missionType: MissionType;
  isPersonnel?: boolean;
  canShowStream?: boolean;
}): EventType[] {
  const children: EventType[] = canShowStream
    ? [
        {
          name: 'Стрім/запис',
          displayType: 'Стрім/запис: ',
          type: 'input-optional',
          shouldDisplayInputValue: false,
          linkedObject: {
            parentObject: 'uavData',
            stream: null,
          },
        },
      ]
    : [];
  const actionOfREB: EventType[] = [
    {
      name: 'Втрата управління',
      type: 'constant',
      displayType: 'Результат: Втрата управління',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Втрата управління',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
    {
      name: 'Втрата відео',
      type: 'constant',
      displayType: 'Результат: Втрата відео',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Втрата відео',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
    {
      name: 'Не успішно з інших причин',
      type: 'constant',
      displayType: 'Результат: Не успішно з інших причин',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Не успішно з інших причин',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
  ];

  if (isPersonnel) {
    return [
      {
        name: 'Влучання',
        type: 'constant',
        displayType: 'Результат: Успішно, влучання',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Влучання',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Обстріляно',
        type: 'constant',
        displayType: 'Результат: Успішно, обстріляно',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Обстріляно',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Не детон',
        type: 'constant',
        displayType: 'Результат: Не успішно, не детон',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Не детон',
          result: 'Не успішно',
        },
        children,
        startCreatedAtEventPath: true,
      },
      ...actionOfREB,
    ];
  }

  if (missionType === 'Ударна') {
    return [
      {
        name: 'Знищено',
        type: 'constant',
        displayType: 'Результат: Успішно, знищено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Знищено',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Пошкоджено',
        type: 'constant',
        displayType: 'Результат: Успішно, пошкоджено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Пошкоджено',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Влучання',
        type: 'constant',
        displayType: 'Результат: Успішно, влучання',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Влучання',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Обстріляно',
        type: 'constant',
        displayType: 'Результат: Успішно, обстріляно',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Обстріляно',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Не детон',
        type: 'constant',
        displayType: 'Результат: Не успішно, не детон',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Не детон',
          result: 'Не успішно',
        },
        children,
        startCreatedAtEventPath: true,
      },
      ...actionOfREB,
    ];
  }
  if (missionType === 'Мінування') {
    return [
      {
        name: 'Заміновано',
        type: 'constant',
        displayType: 'Результат: Успішно, заміновано',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Заміновано',
          result: 'Успішно',
        },
        children,
        startCreatedAtEventPath: true,
      },

      ...actionOfREB,
    ];
  }

  if (missionType === 'Доставка') {
    return [
      {
        name: 'Доставлено',
        type: 'constant',
        displayType: 'Результат: Успішно, доставлено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Доставлено',
          result: 'Успішно',
        },
        children,
        startCreatedAtEventPath: true,
        canShowEventPath: false,
      },

      ...actionOfREB,
    ];
  }

  return [
    {
      name: 'Виконано',
      type: 'constant',
      displayType: 'Результат: Успішно, виконано',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Виконано',
        result: 'Успішно',
      },
      children,
      startCreatedAtEventPath: true,
      canShowEventPath: false,
    },

    ...actionOfREB,
  ];
}

function handlersWrapper({
  missionType,
  isFPV,
  canShowStream,
}: {
  missionType: MissionType;
  isFPV: boolean;
  canShowStream?: boolean;
}): EventType {
  const result = resultHelper({
    missionType,
    canShowStream,
  }).map((i) => ({
    ...i,
    canShowEventPath: false,
    startCreatedAtEventPath: true,
  })) as EventType[];

  if (missionType === 'Ударна') {
    return missionHelper({
      missionType,
      children: typeOfAmmunitionHelper({
        isFPV: isFPV,
        children: targetHelper({
          children: result,
          canShowStream,
        }),
      }),
    });
  }
  if (missionType === 'Мінування') {
    return missionHelper({
      missionType,
      children: typeOfAmmunitionHelper({
        isFPV: isFPV,
        children: [
          {
            name: 'Координати або квадрат',
            type: 'coordinates-square-target-destruction',
            linkedObject: {
              parentObject: 'uavData',
            },
            children: result,
            canShowEventPath: false,
            startCreatedAtEventPath: true,
          },
        ],
      }),
    });
  }
  if (missionType === 'Доставка') {
    return missionHelper({
      missionType,
      children: [
        {
          name: 'Координати або квадрат',
          type: 'coordinates-square-target-destruction',
          linkedObject: {
            parentObject: 'uavData',
          },
          children: result,
          canShowEventPath: false,
          startCreatedAtEventPath: true,
        },
      ],
    });
  }

  return missionHelper({
    missionType,
    children: [
      {
        name: 'Координати або квадрат',
        type: 'coordinates-square-target-destruction',
        linkedObject: {
          parentObject: 'uavData',
        },
        children: result,
        canShowEventPath: false,
        startCreatedAtEventPath: true,
      },
    ],
  });
}

export const configUAV: EventType = {
  id: 500,
  title: 'Виконано завдання Mavic/Autel',
  name: 'Виконано завдання (БПЛА)',
  displayType: 'Виконано завдання (БПЛА)',
  options: {
    category: 'steps',
    skipMapStep: true,
    saveKey: 'previous-uav-data',
  },
  type: 'constant',
  column: 2,
  steps: [
    {
      name: 'Засіб',
      type: 'constant',
      children: [
        {
          name: 'Mavic 3',
          type: 'constant',
          displayType: 'Засіб: Mavic 3',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3 Pro',
          type: 'constant',
          displayType: 'Засіб: Mavic 3 Pro',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3 Pro',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3E',
          type: 'constant',
          displayType: 'Засіб: Mavic 3E',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3E',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3T',
          type: 'constant',
          displayType: 'Засіб: Mavic 3T',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3T',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3 Classic',
          type: 'constant',
          displayType: 'Засіб: Mavic 3 Classic',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3 Classic',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Autel 4T',
          type: 'constant',
          displayType: 'Засіб: Autel 4T',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Autel 4T',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Інше',
          type: 'input',
          displayType: 'Засіб: ',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Autel',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
      ],
    },
    {
      name: 'Додатково',
      type: 'constant',
      children: [
        {
          name: 'Додатково',
          type: 'notes',
        },
      ],
    },
  ],
};
