import { defineStore } from 'pinia';
import apiClient from '@/services/axios';
import Position from '@/models/Position';

const REMINDER_INTERVALS = [10, 30, 60];
const POSITIONS_POLL_INTERVAL = 10 * 1000; // 10 sec

function playBeepSound() {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  oscillator.type = 'square';
  oscillator.frequency.setValueAtTime(440, audioCtx.currentTime);
  gainNode.gain.setValueAtTime(0.1, audioCtx.currentTime);

  oscillator.start();
  oscillator.stop(audioCtx.currentTime + 0.2);
}

export const usePositionsStore = defineStore('positions', {
  state: () => ({
    positions: [],
    loading: false,
    error: null,
    activePositions: JSON.parse(localStorage.getItem('activePositions')) || [],
    positionTimers: {},
    statusPolling: null,
  }),

  getters: {
    groupedPositions: (state) => {
      return state.positions
        .filter((position) => position.squadron)
        .reduce((acc, position) => {
          const squadronName = position.squadron.name;
          if (!acc[squadronName]) {
            acc[squadronName] = { grouped: [], nonGrouped: [] };
          }
          if (position.isGroup) {
            acc[squadronName].grouped.push(position);
          } else {
            acc[squadronName].nonGrouped.push(position);
          }

          acc[squadronName].grouped.sort((a, b) => sortPositions(a, b));
          acc[squadronName].nonGrouped.sort((a, b) => sortPositions(a, b));

          return acc;
        }, {});
    },
    hasPositions: (state) => state.positions.length > 0,
  },

  actions: {
    // Initial positions fetch
    async fetchPositions() {
      if (this.loading) return;

      this.loading = true;
      try {
        const response = await apiClient.getPositions();
        const newPositions = response.data.map((data) => new Position(data));
        this.positions = newPositions;
        this.error = null;
      } catch (err) {
        this.error = 'Не вдалося отримати позиції';
        console.error('Error fetching positions:', err);
      } finally {
        this.loading = false;
      }
    },

    // Status polling
    async pollPositionStatuses() {
      try {
        const response = await apiClient.getPositions();
        const newPositions = response.data.map((data) => new Position(data));

        this.positions = newPositions;
        // Compare and update only changed positions
        newPositions.forEach((newPosition) => {
          const existingPosition = this.positions.find(
            (p) => p.id === newPosition.id,
          );
          if (!existingPosition) return;

          const statusChanged =
            existingPosition.isUnderAttack !== newPosition.isUnderAttack ||
            existingPosition.isUnderFire !== newPosition.isUnderFire;

          if (statusChanged) {
            // Update the position in the main positions array
            const index = this.positions.findIndex(
              (p) => p.id === newPosition.id,
            );
            this.positions[index] = newPosition;

            // Handle active positions and reminders
            if (newPosition.isUnderAttack || newPosition.isUnderFire) {
              this.addOrUpdatePosition(newPosition);
            } else {
              this.removePosition(newPosition.id);
            }
          }
        });
      } catch (error) {
        console.error('Error polling position statuses:', error);
      }
    },

    // Start status polling
    startStatusPolling() {
      this.stopStatusPolling(); // Clear any existing interval
      this.statusPolling = setInterval(() => {
        this.pollPositionStatuses();
      }, POSITIONS_POLL_INTERVAL);
    },

    // Stop status polling
    stopStatusPolling() {
      if (this.statusPolling) {
        clearInterval(this.statusPolling);
        this.statusPolling = null;
      }
    },

    addOrUpdatePosition(positionData) {
      const existingIndex = this.activePositions.findIndex(
        (p) => p.id === positionData.id,
      );
      const position = new Position(positionData);

      if (existingIndex > -1) {
        this.activePositions[existingIndex] = position;
        if (!position.isUnderAttack && !position.isUnderFire) {
          this.clearTimers(position.id);
        }
      } else {
        this.activePositions.push(position);
      }

      if (position.isUnderAttack || position.isUnderFire) {
        this.scheduleReminders(position);
      }

      this.persistToLocalStorage();
    },

    removePosition(positionId) {
      this.clearTimers(positionId);
      const index = this.activePositions.findIndex((p) => p.id === positionId);
      if (index > -1) {
        this.activePositions.splice(index, 1);
        this.persistToLocalStorage();
      }
    },

    scheduleReminders(position) {
      this.clearTimers(position.id);
      const timers = REMINDER_INTERVALS.map((minutes) =>
        setTimeout(() => {
          playBeepSound();
          const status = [
            position.isUnderAttack && 'штурм',
            position.isUnderFire && 'обстріл',
          ]
            .filter(Boolean)
            .join(' або ');
          alert(`Чи припинилися ${status} позиції "${position.name}"`);
        }, minutes * 60 * 1000),
      );
      this.positionTimers[position.id] = timers;
    },

    clearTimers(positionId) {
      if (this.positionTimers[positionId]) {
        this.positionTimers[positionId].forEach((timer) => clearTimeout(timer));
        delete this.positionTimers[positionId];
      }
    },

    persistToLocalStorage() {
      localStorage.setItem(
        'activePositions',
        JSON.stringify(this.activePositions),
      );
    },

    createCustomPosition(positionName) {
      // Generate a unique temporary ID for the custom position
      const customId = null;
      const customPosition = new Position({
        id: customId,
        name: positionName,
        isCustomPosition: true, // Mark this position as custom
        isUnderAttack: false,
        isUnderFire: false,
        squadron: null, // Custom positions don't belong to a squadron by default
        isGroup: false,
      });

      // Add the custom position to the positions array
      this.positions.push(customPosition);

      // Persist changes if needed
      this.persistToLocalStorage();

      return customPosition;
    },
  },
});

function sortPositions(a, b) {
  if (a.isUnderAttack && a.isUnderFire) return -1;
  if (b.isUnderAttack && b.isUnderFire) return 1;
  if (a.isUnderAttack) return -1;
  if (b.isUnderAttack) return 1;
  if (a.isUnderFire) return -1;
  if (b.isUnderFire) return 1;
  return a.name.localeCompare(b.name);
}
