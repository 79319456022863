import { ref, reactive, watch } from 'vue';
import type {
  User,
  Squadron,
  Battalion,
  Position,
  ChatConfigState,
  ChatData,
} from '@/types/admin';

export function useAdminDialogs() {
  // Dialog visibility states
  const dialogStates = reactive({
    userDialog: false,
    squadronDialog: false,
    battalionDialog: false,
    positionDialog: false,
    chatConfigDialog: false,
  });

  // Edit mode states
  const isEditMode = reactive({
    user: false,
    squadron: false,
    battalion: false,
    position: false,
  });

  // Form states
  const userForm = reactive({
    id: null as string | null,
    username: '',
    password: '',
    role: 'user',
    rank: '' as string | undefined,
    surnameAndInitials: '' as string | undefined,
    callsign: '',
    squadronId: '',
  });

  const squadronForm = reactive({
    id: null as string | null,
    name: '',
    battalionId: null as string | null,
  });

  const battalionForm = reactive({
    id: null as string | null,
    name: '',
    signalChatsData: [] as ChatData[],
    whatsappChatsData: [] as ChatData[],
    elementChatsData: [] as ChatData[],
  });

  const positionForm = reactive({
    id: null as string | null,
    name: '',
    description: '',
    squadronId: '',
    isGroup: false,
  });

  // Chat states
  const selectedChat = ref<ChatConfigState | null>(null);
  const newSignalChat = ref('');
  const newWhatsappChat = ref('');

  // Reset functions
  function resetUserForm() {
    userForm.id = null;
    userForm.username = '';
    userForm.password = '';
    userForm.role = 'user';
    userForm.callsign = '';
    userForm.squadronId = '';
    userForm.rank = '';
    userForm.surnameAndInitials = '';
    isEditMode.user = false;
  }

  function resetSquadronForm() {
    squadronForm.id = null;
    squadronForm.name = '';
    squadronForm.battalionId = null;
    isEditMode.squadron = false;
  }

  function resetBattalionForm() {
    battalionForm.id = null;
    battalionForm.name = '';
    battalionForm.signalChatsData = [];
    battalionForm.whatsappChatsData = [];
    battalionForm.elementChatsData = [];
    isEditMode.battalion = false;
    newSignalChat.value = '';
    newWhatsappChat.value = '';
  }

  function resetPositionForm() {
    positionForm.id = null;
    positionForm.name = '';
    positionForm.description = '';
    positionForm.squadronId = '';
    positionForm.isGroup = false;
    isEditMode.position = false;
  }

  // Open dialog functions with proper edit mode handling
  function openUserDialog(user?: User) {
    if (user) {
      // Edit mode
      isEditMode.user = true;
      userForm.id = user.id;
      userForm.username = user.username;
      userForm.rank = user.rank;
      userForm.surnameAndInitials = user.surnameAndInitials;
      userForm.role = user.role;
      userForm.callsign = user.callsign;
      userForm.squadronId = user.squadronId;
      userForm.password = ''; // Clear password in edit mode
    } else {
      // Create mode
      resetUserForm();
    }
    dialogStates.userDialog = true;
  }

  function openSquadronDialog(squadron?: Squadron) {
    if (squadron) {
      // Edit mode
      isEditMode.squadron = true;
      squadronForm.id = squadron.id;
      squadronForm.name = squadron.name;
      squadronForm.battalionId = squadron.battalionId;
    } else {
      // Create mode
      resetSquadronForm();
    }
    dialogStates.squadronDialog = true;
  }

  function openBattalionDialog(battalion?: Battalion) {
    if (battalion) {
      // Edit mode
      isEditMode.battalion = true;
      battalionForm.id = battalion.id;
      battalionForm.name = battalion.name;
      battalionForm.signalChatsData = battalion.signalChatsData || [];
      battalionForm.whatsappChatsData = battalion.whatsappChatsData || [];
      battalionForm.elementChatsData = battalion.elementChatsData || [];
    } else {
      // Create mode
      resetBattalionForm();
    }
    dialogStates.battalionDialog = true;
  }

  function openPositionDialog(position?: Position) {
    if (position) {
      // Edit mode
      isEditMode.position = true;
      Object.keys(position).forEach((key) => {
        //@ts-ignore
        positionForm[key] = position[key];
      });
    } else {
      // Create mode
      resetPositionForm();
    }
    dialogStates.positionDialog = true;
  }

  function openChatConfigDialog(
    chatType: 'signal' | 'whatsapp' | 'element',
    battalion: Battalion,
    chatName: string,
  ) {
    const chatDataMap = {
      signal: battalion.signalChatsData,
      whatsapp: battalion.whatsappChatsData,
      element: battalion.elementChatsData,
    };
    const chatsData = chatDataMap[chatType];
    const chatData = chatsData?.find((chat) => chat.name === chatName);

    if (chatData) {
      selectedChat.value = {
        name: chatName,
        type: chatType,
        battalion,
        config: chatData.config,
      };
      dialogStates.chatConfigDialog = true;
    }
  }

  // Reset all dialogs
  function resetAllDialogs() {
    // Reset visibility
    dialogStates.userDialog = false;
    dialogStates.squadronDialog = false;
    dialogStates.battalionDialog = false;
    dialogStates.positionDialog = false;
    dialogStates.chatConfigDialog = false;

    // Reset forms
    resetUserForm();
    resetSquadronForm();
    resetBattalionForm();
    resetPositionForm();

    // Reset chat state
    selectedChat.value = null;
    newSignalChat.value = '';
    newWhatsappChat.value = '';
  }

  return {
    dialogStates,
    isEditMode,
    // Forms
    userForm,
    squadronForm,
    battalionForm,
    positionForm,
    // Chat states
    selectedChat,
    newSignalChat,
    newWhatsappChat,
    // Open functions
    openUserDialog,
    openSquadronDialog,
    openBattalionDialog,
    openPositionDialog,
    openChatConfigDialog,
    // Reset functions
    resetUserForm,
    resetSquadronForm,
    resetBattalionForm,
    resetPositionForm,
    resetAllDialogs,
  };
}
