import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

import { ref, computed, watch, toRefs } from 'vue';
import { UserRole } from '@/types/admin';
import { useEventsStore } from '@/stores/events';
import { useAdminStore } from '@/stores/admin';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTableUAV',
  props: {
  startDate: {
    type: [String, null],
  },
  endDate: {
    type: [String, null],
  },
},
  emits: ['battalionId', 'reporterId'],
  setup(__props, { emit: __emit }) {

const adminStore = useAdminStore();
const eventsStore = useEventsStore();
const emit = __emit;

const battalionSelected = ref<string[]>([]);
const reporterSelected = ref<string[]>([]);
const itemsPerPageLocal = ref(50);

const props = __props;
const { startDate, endDate } = toRefs(props);

const reporters = computed(() =>
  adminStore.filteredUsers.filter((u) => {
    if (UserRole.uav_pilot !== u.role) return false;

    if (battalionSelected.value[0])
      return u.squadron?.battalionId === battalionSelected.value[0];
    else return true;
  }),
);

const battalions = computed(() =>
  adminStore.battalions.map(({ id, name }) => ({ id, name })),
);

// Watch for changes and trigger data fetch
watch(
  [battalionSelected, reporterSelected, startDate, endDate],
  () => {
    eventsStore.fetchUavEvents({
      page: 1,
      limit: itemsPerPageLocal.value,
      startDate: startDate?.value,
      endDate: endDate?.value,
      battalionId: battalionSelected.value[0],
      reporterId: reporterSelected.value[0],
    });
  },
  { deep: true },
);

watch(battalionSelected, (newVal) => {
  emit('battalionId', newVal[0]);
});
watch(reporterSelected, (newVal) => {
  emit('reporterId', newVal[0]);
});

const fetchFilteredEvents = ({
  page,
  itemsPerPage,
}: {
  page: number;
  itemsPerPage: number;
}) => {
  itemsPerPageLocal.value = itemsPerPage;
  eventsStore.fetchUavEvents({
    page,
    limit: itemsPerPage,
    startDate: startDate?.value,
    endDate: endDate?.value,
    battalionId: battalionSelected.value[0],
    reporterId: reporterSelected.value[0],
  });
};

const headers = [
  { title: 'Системний час (UTC)', key: 'createdAt' },
  { title: 'Час доповіді', key: 'time' },
  { title: 'Місія', key: 'mission' },
  { title: 'Підрозділ', key: 'battalion' },
  { title: 'Пілот', key: 'reporter' },
  { title: 'Засіб', key: 'uavType' },
  { title: 'БК', key: 'ammunitionType' },
  { title: 'Ціль', key: 'target' },
  { title: 'Опис цілі', key: 'targetDetails' },
  { title: '300', key: 'wounded' },
  { title: '~300', key: 'possibleWounded' },
  { title: '200', key: 'killed' },
  { title: '~200', key: 'possibleKilled' },
  { title: 'Результат', key: 'result' },
  { title: 'Деталі', key: 'resultDetails' },
  { title: 'Координати', key: 'coordinates' },
  { title: 'Квадрат', key: 'square' },
  { title: 'Стрім', key: 'stream' },
  { title: 'Нотатки', key: 'notes' },
];

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    headers: headers,
    items: _unref(eventsStore).formattedUavEvents,
    "items-length": _unref(eventsStore).uavEventsCount,
    "items-per-page": itemsPerPageLocal.value,
    loading: _unref(eventsStore).loading,
    "header-props": { style: 'font-weight: bold; background-color: #f5f5f5;' },
    class: "elevation-1",
    "item-value": "id",
    "onUpdate:options": fetchFilteredEvents,
    "items-per-page-options": [10, 25, 50, 100],
    "disable-sort": true
  }, {
    "header.battalion": _withCtx(() => [
      _createVNode(_component_v_menu, { location: "end" }, {
        activator: _withCtx(({ props }) => [
          _createElementVNode("div", _mergeProps({ class: "text-start d-flex ga-1" }, props), [
            _cache[4] || (_cache[4] = _createTextVNode(" Підрозділ ")),
            _createVNode(_component_v_icon, {
              color: battalionSelected.value.length ? 'primary' : 'default'
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("mdi-filter")
              ])),
              _: 1
            }, 8, ["color"])
          ], 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            selected: battalionSelected.value,
            "onUpdate:selected": [
              _cache[0] || (_cache[0] = ($event: any) => ((battalionSelected).value = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (reporterSelected.value = []))
            ]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(battalions.value, (battalion) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: battalion.id,
                  value: battalion.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(battalion.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["selected"])
        ]),
        _: 1
      })
    ]),
    "header.reporter": _withCtx(() => [
      _createVNode(_component_v_menu, { location: "end" }, {
        activator: _withCtx(({ props }) => [
          _createElementVNode("div", _mergeProps({ class: "text-start d-flex ga-1" }, props), [
            _cache[6] || (_cache[6] = _createTextVNode(" Пілот ")),
            _createVNode(_component_v_icon, {
              color: reporterSelected.value.length ? 'primary' : 'default'
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("mdi-filter")
              ])),
              _: 1
            }, 8, ["color"])
          ], 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            selected: reporterSelected.value,
            "onUpdate:selected": _cache[2] || (_cache[2] = ($event: any) => ((reporterSelected).value = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reporters.value, (reporter) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: reporter.id,
                  value: reporter.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(reporter.callsign), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["selected"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["items", "items-length", "items-per-page", "loading"]))
}
}

})