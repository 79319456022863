import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-type", "data-name"]

import type { EventType, LinkedObjectType } from '@/types/events';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';
import EventInput from '@/components/EventCreation/EventInput.vue';
import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';
import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventKilledAndWoundedSummary from '@/components/EventCreation/EventKilledAndWoundedSummary.vue';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventTypeModelCount from '@/components/EventCreation/EventTypeModelCount.vue';
import EventModelCount from '@/components/EventCreation/EventModelCount.vue';
import EventForm300 from '@/components/EventCreation/EventForm300.vue';
import EventForm200 from '@/components/EventCreation/EventForm200.vue';
import { useEventStore } from '@/stores/event';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventItem',
  props: {
    dialog: { type: Boolean },
    item: {}
  },
  emits: ["closeDialog"],
  setup(__props: any) {

const eventStore = useEventStore();

const props = __props;



function handleInput(value: string[], linkedObject?: LinkedObjectType) {
  eventStore.handleInput(value, props.item, linkedObject);
}

return (_ctx: any,_cache: any) => {
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createElementBlock("div", {
    "data-type": _ctx.item.type ?? 'constant',
    "data-name": _ctx.item.name,
    class: _normalizeClass(_unref(eventStore).path.length === 0 ? 'pa-1' : 'event-item pa-0')
  }, [
    (!_ctx.item.type || _ctx.item.type === 'constant')
      ? (_openBlock(), _createBlock(_component_v_list_item, {
          key: 0,
          class: "list-item-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(eventStore).handleSelection(_ctx.item))),
          title: _ctx.item?.title || _ctx.item.name,
          "data-pw": _ctx.item.name
        }, null, 8, ["title", "data-pw"]))
      : (
        _ctx.item.type === 'input' ||
        _ctx.item.type === 'input-optional' ||
        _ctx.item.type === 'input-without-display-type'
      )
        ? (_openBlock(), _createBlock(EventInput, {
            key: 1,
            type: _ctx.item.type,
            displayType: _ctx.item.displayType,
            linkedObject: _ctx.item.linkedObject,
            label: _ctx.item.name!,
            modelValue: _unref(eventStore).inputTypeValue,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((_unref(eventStore).inputTypeValue) = $event)),
              _cache[2] || (_cache[2] = () => (_unref(eventStore).selectedItem = _ctx.item))
            ],
            onInput: handleInput
          }, null, 8, ["type", "displayType", "linkedObject", "label", "modelValue"]))
        : (_ctx.item.type === 'input-number')
          ? (_openBlock(), _createBlock(EventInputNumber, {
              key: 2,
              label: _ctx.item.name!,
              modelValue: _unref(eventStore).inputTypeValue,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_unref(eventStore).inputTypeValue) = $event)),
                _cache[4] || (_cache[4] = () => (_unref(eventStore).selectedItem = _ctx.item))
              ]
            }, null, 8, ["label", "modelValue"]))
          : (
        _ctx.item.type === 'coordinates-square' ||
        _ctx.item.type === 'coordinates-square-target-destruction'
      )
            ? (_openBlock(), _createBlock(EventCoordinatesSquareFrom, {
                key: 3,
                type: _ctx.item.type,
                onInput: handleInput
              }, null, 8, ["type"]))
            : (_ctx.item.type === 'expenditure-balance')
              ? (_openBlock(), _createBlock(EventExpenditureBalance, {
                  key: 4,
                  onInput: handleInput
                }))
              : (_ctx.item.type === 'killed-wounded-summary')
                ? (_openBlock(), _createBlock(EventKilledAndWoundedSummary, {
                    key: 5,
                    onInput: handleInput
                  }))
                : _createCommentVNode("", true),
    (_ctx.item.type === 'input-model-count')
      ? (_openBlock(), _createBlock(EventModelCount, {
          key: 6,
          ammoType: (_ctx.item.linkedObject!.ammunitionType as string),
          onInput: handleInput
        }, null, 8, ["ammoType"]))
      : _createCommentVNode("", true),
    (_ctx.item.type === 'input-type-model-count')
      ? (_openBlock(), _createBlock(EventTypeModelCount, {
          key: 7,
          typeOfAmmunitions: _ctx.item.typeOfAmmunitions,
          onInput: handleInput
        }, null, 8, ["typeOfAmmunitions"]))
      : _createCommentVNode("", true),
    (_ctx.item.type === '300')
      ? (_openBlock(), _createBlock(EventForm300, {
          key: 8,
          onInput: handleInput
        }))
      : _createCommentVNode("", true),
    (_ctx.item.type === '200')
      ? (_openBlock(), _createBlock(EventForm200, {
          key: 9,
          onInput: handleInput
        }))
      : _createCommentVNode("", true),
    (_ctx.item.type === 'map')
      ? (_openBlock(), _createBlock(MapSelectionView, {
          key: 10,
          dialog: _ctx.dialog,
          "onUpdate:dialog": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closeDialog')))
        }, {
          title: _withCtx(() => [
            _createVNode(EventBreadcrumbs, {
              onCloseDialog: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeDialog')))
            })
          ]),
          _: 1
        }, 8, ["dialog"]))
      : (_ctx.item.type === 'notes')
        ? (_openBlock(), _createBlock(EventNotesForm, {
            key: 11,
            position: _unref(eventStore).positionData,
            eventMessage: _unref(eventStore).eventMessage,
            "show-allies": Boolean(_unref(eventStore).options?.allies),
            "show-enemies": Boolean(_unref(eventStore).options?.enemies)
          }, null, 8, ["position", "eventMessage", "show-allies", "show-enemies"]))
        : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})