<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <div class="d-flex align-center ga-3">
        <v-text-field
          v-model.number.trim="model"
          :label="label"
          outlined
          hide-details="auto"
          type="number"
          @keydown="handleKeydown"
          class="numberInput"
          :rules="[rules.isInteger]"
        ></v-text-field>
        <div class="d-flex align-center justify-end ga-3">
          <v-btn
            class="custom-default-btn"
            size="x-small"
            icon="mdi-minus"
            @click="decrement"
            :disabled="!model"
          />
          <v-btn
            class="custom-default-btn"
            size="x-small"
            icon="mdi-plus"
            @click="increment"
            :disabled="model >= MAX_VALUE"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { rules } from '@/utils/rules';

const model = defineModel();
const MIN_VALUE = 0;
const MAX_VALUE = 99999;

defineProps<{
  label: string;
}>();

function handleKeydown(event: KeyboardEvent) {
  if (['e', 'E', '+', '-', '.'].includes(event.key)) {
    event.preventDefault();
  }
}

function increment() {
  if ((model.value as number) < MAX_VALUE) (model.value as number)++;
}

function decrement() {
  if ((model.value as number) > MIN_VALUE) (model.value as number)--;
}

watch(model, (value, prev) => {
  let stringValue = (model.value as number).toString();
  let regex = /^\d{0,5}(?:\.\d{1,5})?$/;
  if (!stringValue.match(regex) && value) {
    model.value = prev;
  }
});
</script>

<style scoped>
.numberInput >>> input[type='number'] {
  -moz-appearance: textfield;
}
.numberInput >>> input::-webkit-outer-spin-button,
.numberInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
