<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Координати"
          v-model.trim="coordinates"
          type="text"
          placeholder="37U DQ 12345 54321"
          clearable
          hide-details
          @input="applyMGRSMask"
          @update:focused="focusCoordinates"
          @click:clear="clearCoordinates"
          :color="coordinatesError ? 'warning' : 'default'"
        />
        <p class="text-warning text-caption mt-1.5 mx-4 mb-2">
          {{ coordinatesError ? 'Введіть координати в форматі MGRS' : '' }}
        </p>
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Квадрат"
          v-model.trim="square"
          type="text"
          :rules="[rules.maxLength(100)]"
        />
      </v-responsive>
      <v-responsive
        class="mx-auto"
        width="100%"
        v-if="type === 'coordinates-square-target-destruction'"
      >
        <v-text-field
          label="Опис цілі"
          v-model="targetDestruction"
          :rules="[rules.maxLength(100)]"
          type="text"
        />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { rules } from '@/utils/rules';
import { useEventStore } from '@/stores/event';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';
import { MGRS_ZONE_AND_SQUARE } from '@/config/constants';

const emit = defineEmits(['input']);

defineProps<{
  type: 'coordinates-square-target-destruction' | 'coordinates-square';
}>();

const eventStore = useEventStore();
const eventSessionStore = useEventsSessionStore();

const coordinatesError = ref(false);

const square = ref('');
const coordinates = ref('');
const targetDestruction = ref('');

const coordinatesSquareComputed = computed(() => {
  try {
    return localStorage.getItem(MGRS_ZONE_AND_SQUARE) ?? '';
  } catch (error) {
    console.error(error);
    return '';
  }
});

const disabled = computed(
  () =>
    !eventSessionStore.valid ||
    (!!coordinates.value &&
      coordinatesSquareComputed.value === coordinates.value) ||
    (!coordinates.value.length && !square.value),
);

function applyMGRSMask(e: Event) {
  const target = e.target as HTMLInputElement;

  let value = target.value.toUpperCase();

  // Видаляємо все, що не є літерою або цифрою
  value = value.replace(/[^A-Z0-9]/g, '');

  // Формуємо маску
  let maskedValue = '';

  if (value.length > 0) maskedValue += value.slice(0, 2); // Перші 2 цифри
  if (value.length > 2) maskedValue += value[2]; // 1 літера
  if (value.length > 3) maskedValue += ' ' + value.slice(3, 5); // 2 літери
  if (value.length > 5) maskedValue += ' ' + value.slice(5, 10); // 5 цифр
  if (value.length > 10) maskedValue += ' ' + value.slice(10, 15); // 5 цифр

  // Оновлюємо значення поля
  coordinates.value = maskedValue;

  // Зберігаємо позицію курсора
  const cursorPosition = target.selectionStart;

  // Обчислюємо нову позицію курсора
  let newCursorPosition = cursorPosition;

  // Якщо ми додали пробіл, то курсор має зсунутися на 1 вправо
  if (maskedValue.length > target.value.length && newCursorPosition) {
    newCursorPosition += 1;
  }

  // Встановлюємо курсор на правильну позицію
  nextTick(() => {
    target.setSelectionRange(newCursorPosition, newCursorPosition);
  });
}

function clearCoordinates() {
  coordinates.value = '';
  localStorage.removeItem(MGRS_ZONE_AND_SQUARE);
}
function focusCoordinates(focused: boolean) {
  // Перевіряємо чи є дані в localStorage, якщо є, то виводимо їх в поле coordinates
  if (!coordinates.value && focused) {
    coordinates.value = coordinatesSquareComputed.value;
  }
  // Якщо дані в полі coordinates співпадають з даними в localStorage, то очищаємо поле
  if (coordinates.value === coordinatesSquareComputed.value && !focused) {
    coordinates.value = '';
  }
}

type LinkedObjectType = {
  coordinates?: string;
  square?: string;
  targetDetails?: string;
};

function proceed() {
  const values = [];
  const linkedObject: LinkedObjectType = {};

  if (targetDestruction.value) {
    values.push(`Опис цілі: ${targetDestruction.value}`);
    linkedObject.targetDetails = targetDestruction.value;
  }
  if (coordinates.value && coordinates.value.length > 6) {
    values.push(`Координати: ${coordinates.value}`);
    linkedObject.coordinates = coordinates.value;
    //Записуємо значення зони так квадрату координат в localStorage
    localStorage.setItem(
      MGRS_ZONE_AND_SQUARE,
      coordinates.value.split(' ').slice(0, 2).join(' '),
    );
    // Перевіряємо чи введені координати
    eventSessionStore.isCoordinatesFilled = true;
  } else {
    // Якщо координати не введені, то видаляємо дані з localStorage після відправки події на кроці "notes"
    eventSessionStore.isCoordinatesFilled = false;
  }

  if (square.value) {
    values.push(`Квадрат: ${square.value}`);
    linkedObject.square = square.value;
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

onMounted(() => {
  // Записуємо введені дані, на попередньому кроці "ціль" в targetDestruction
  const targetDetails = eventStore.path.at(-1)?.linkedObject?.targetDetails;
  if (targetDetails) {
    targetDestruction.value = targetDetails as string;
  }
});

watch(
  () => coordinates.value,
  () => {
    if (!rules.isMGRS(coordinates.value) && coordinates.value.length > 0) {
      coordinatesError.value = true;
    } else {
      coordinatesError.value = false;
    }
  },
);
</script>
