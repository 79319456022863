<template>
  <div class="d-flex justify-space-between align-start pb-3">
    <v-breadcrumbs class="pt-0 pl-0 pb-0">
      <v-breadcrumbs-item class="font-weight-bold">
        {{ eventStore.positionData?.name }}
      </v-breadcrumbs-item>
      <template v-for="(crumb, index) in eventStore.path" :key="index">
        <v-breadcrumbs-item
          class="font-weight-medium"
          @click="eventStore.goTo(index)"
          v-if="!!crumb?.name"
        >
          {{ crumb.name }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-btn
      class="custom-red-btn mb-0"
      @click="$emit('close-dialog')"
      density="compact"
      icon="mdi-close"
    ></v-btn>
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from '@/stores/event';

const eventStore = useEventStore();

defineEmits<{
  'close-dialog': void;
}>();
</script>

<style scoped>
.v-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1px;
}

.v-breadcrumbs-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
}

.v-breadcrumbs-item::after {
  content: '>';
  margin: 0 4px;
}
.v-breadcrumbs-item:first-child {
  cursor: default;
}
.v-breadcrumbs-item:first-child::after {
  content: ':';
  margin: 0 4px 0px 0px;
}

.v-breadcrumbs-item:last-child::after {
  content: '';
}
</style>
