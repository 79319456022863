import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type {
  EventType,
  EventStep,
  EventOptions,
  EventPath,
  LinkedObjectType,
  EventLocation,
} from '@/types/events';
import _omitBy from 'lodash.omitby';
import _isMatch from 'lodash.ismatch';
import { useEventCreationStore } from '@/stores/eventCreation';
import { useEventsSessionStore } from '@/stores/eventSession';

export const useEventStore = defineStore('event', () => {
  const eventStore = useEventCreationStore();
  const eventSessionStore = useEventsSessionStore();

  const positionData = ref();
  const currentOptions = ref<EventType[]>([]);

  const inputTypeValue = ref('');
  const selectedItem = ref<EventType | null>(null);

  const step = ref(0);
  const path = ref<EventPath[]>([]);
  const history = ref<EventType[][]>([]);
  const isShowEventPreviewLastCreatedEventDialog = ref(true);

  const currentType = ref<EventStep | ''>('');
  const options = ref<EventOptions>({
    category: 'single',
  });

  const actionsView = computed(
    () =>
      ![
        'expenditure-balance',
        'coordinates-square',
        'killed-wounded-summary',
        'input-type-model-count',
        'input-model-count',
        'coordinates-square-target-destruction',
        'input-optional',
        '300',
        '200',
      ].includes(currentType.value),
  );

  const lastEventPaths = computed(() => {
    if (options.value.saveKey) {
      return eventSessionStore.lastEventPath(options.value.saveKey);
    }
    return [];
  });

  const eventMessage = computed(() =>
    getDisplayTypeInPaths(path.value.filter((i) => !i.ignoreDisplayType)).join(
      '\n',
    ),
  );

  const inputType = computed(() =>
    currentOptions.value.find((item) =>
      ['input-number', 'input', 'input-without-display-type'].includes(
        item.type,
      ),
    ),
  );

  function handleSelection(item: EventType, isGoTo = false) {
    if (!isGoTo) {
      updatePathAndHistory(item);
    }
    updateStoreState(item);

    if (shouldAddDisplayType(item)) {
      // Обов'язково перевіряємо існування значення selectedDisplayType,
      // Без перевірки отримуємо пусті значення в доповіді подій які мають input type
      const selectedDisplayType = [
        (item as EventType & { selectedDisplayType: string })
          ?.selectedDisplayType ?? item.displayType,
      ];

      if (item?.linkedObject) {
        addFieldToPath({
          selectedDisplayType,
          selectedLinkedObject: item.linkedObject as LinkedObjectType,
        });
      } else {
        addFieldToPath({
          selectedDisplayType,
        });
      }
    }

    if (isStepsCategory(item)) {
      handleStepsCategory(item);
    } else if (hasChildren(item)) {
      handleChildrenCategory(item);
    } else {
      goNextStep();
    }
  }

  function updatePathAndHistory(item: EventType) {
    path.value.push({ ...item, step: step.value });
    history.value.push([...currentOptions.value]);
  }

  function updateStoreState(item: EventType) {
    if (item.id) {
      eventStore.selectedId = item.id;
    }

    if (item.options?.category) {
      updateOptionState(item.options); // Оновлення опцій на основі категорії
      eventStore.type = item.name as string;
    }

    if (item.displayType && step.value === 0) {
      eventStore.$patch({
        eventMessage: item.displayType,
        description: item.displayType,
      });
    }
    // eventMessage використовуємо тільки з category steps
    if (item.eventMessage) {
      eventStore.$patch({
        eventMessage: item.eventMessage,
        description: item.eventMessage,
      });
    }
  }

  function isStepsCategory(item: EventType): boolean {
    return item.options?.category === 'steps';
  }

  function handleStepsCategory(item: EventType) {
    currentOptions.value = item.steps![0]?.children || [];
    step.value = 1;

    if (step.value === 1) {
      eventStore.$patch({ eventMessage: item.name });
    }
  }

  function hasChildren(item: EventType): boolean {
    return !!item.children?.length;
  }

  function handleChildrenCategory(item: EventType) {
    currentOptions.value = item.children!;
  }

  function shouldAddDisplayType(item: EventType): boolean {
    return !!item.displayType && step.value > 0;
  }

  function updateOptionState(optionsProps: EventOptions) {
    if (optionsProps.category) {
      options.value = {
        ...options.value,
        category: optionsProps.category,
      };
    }

    if (optionsProps.skipMapStep) {
      options.value = {
        ...options.value,
        skipMapStep: optionsProps.skipMapStep,
      };
    }

    if (optionsProps.allies) {
      options.value = {
        ...options.value,
        allies: optionsProps.allies,
      };
    }

    if (optionsProps.enemies) {
      options.value = {
        ...options.value,
        enemies: optionsProps.enemies,
      };
    }

    if (
      optionsProps.isUnderAttack !== undefined ||
      optionsProps.isUnderFire !== undefined
    ) {
      positionData.value = {
        ...positionData.value,
        ...(optionsProps.isUnderAttack !== undefined && {
          isUnderAttack: optionsProps.isUnderAttack,
        }),
        ...(optionsProps.isUnderFire !== undefined && {
          isUnderFire: optionsProps.isUnderFire,
        }),
      };
    }

    if (optionsProps.saveKey) {
      options.value = {
        ...options.value,
        saveKey: optionsProps.saveKey,
      };
    }
  }

  function addFieldToPath({
    selectedDisplayType,
    selectedLinkedObject,
  }: {
    selectedLinkedObject?: LinkedObjectType;
    selectedDisplayType: string[];
  }) {
    const pathLastItem = path.value.at(-1)!;
    const pathLength = path.value.length - 1;
    const newPathValue =
      pathLength > 0
        ? [
            ...path.value.slice(0, pathLength),
            { ...pathLastItem, selectedDisplayType, selectedLinkedObject },
          ]
        : [{ ...pathLastItem, selectedDisplayType, selectedLinkedObject }];
    path.value = newPathValue;
  }

  // Обробка введення даних з інпутів та форм вводу
  function handleInput(value: string[], item: EventType, linkedObject?: any) {
    const name = item.shouldDisplayInputValue
      ? inputTypeValue.value
      : item.name;

    if (options.value.category === 'single') {
      handleSelection({ ...item, displayType: value.join('\n'), name });
      return;
    }
    // від зміни порядку виконнання залежить правильність відображення
    handleSelection({ ...item, name });
    if (item?.linkedObject) {
      addFieldToPath({
        selectedDisplayType: value,
        selectedLinkedObject:
          !!linkedObject && item.linkedObject
            ? (_omitBy(
                linkedObject,
                (value) => value === null,
              ) as LinkedObjectType)
            : (_omitBy(
                item.linkedObject,
                (value) => value === null,
              ) as LinkedObjectType),
      });
    } else {
      addFieldToPath({ selectedDisplayType: value });
    }
  }

  function handleNextButtonForInputType() {
    if (!selectedItem.value) return;
    const displayTypeData =
      selectedItem.value.type !== 'input-without-display-type'
        ? inputTypeValue.value
        : '';

    if (Object.keys(selectedItem.value?.linkedObject ?? {}).length) {
      let linkedObject = selectedItem.value.linkedObject!;
      const linkedObjectKey = Object.keys(linkedObject).filter(
        (i) => i !== 'parentObject',
      )[0];

      handleInput([`${selectedItem.value.displayType}${displayTypeData}`], {
        ...selectedItem.value,
        linkedObject: {
          ...linkedObject,
          [linkedObjectKey]: inputTypeValue.value,
        },
      });
      return;
    }

    handleInput(
      [`${selectedItem.value.displayType}${displayTypeData}`],
      selectedItem.value,
    );
  }

  //Скидає поточний стан та відновлює останню створену подію, обробляючи її для подальшої взаємодії
  function reproduceLastCreatedEvent(event: EventType[]) {
    resetToInitialState();
    event.forEach((item) => handleSelection(item));
  }

  function goTo(index: number) {
    if (index === 0) {
      resetToInitialState();
      return;
    }

    path.value = path.value.slice(0, index);
    history.value = history.value.slice(0, index);

    const pathValue = path.value.at(-1)!;

    step.value = pathValue.step;

    handleSelection(pathValue, true);
  }

  function goBack() {
    if (path.value.length > 1) {
      goTo(path.value.length - 1);
    } else {
      resetToInitialState();
    }
  }

  // Перехід до наступного кроку
  function goNextStep() {
    if (path.value.length > 1) {
      isShowEventPreviewLastCreatedEventDialog.value = false;
    }
    if (step.value > 0) {
      const nextStep = path.value[0]?.steps?.[step.value];
      if (nextStep) {
        currentOptions.value = nextStep.children || [];
        step.value++;
      }
    } else {
      //Якщо більше немає кроків,або вкладених елементів children, то переходимо до останнього кроку (notes або map)
      currentOptions.value = [
        { type: options.value?.skipMapStep ? 'notes' : 'map' },
      ];
    }
  }

  function resetToInitialState() {
    path.value = []; // Скидаємо шлях
    history.value = []; // Скидаємо історію
    step.value = 0; // Початковий крок
    currentType.value = ''; // Початковий тип
    options.value = { category: 'single' }; // Початкові опції
    isShowEventPreviewLastCreatedEventDialog.value = true; // Скидаємо стан перегляду попередньої події
    eventSessionStore.resetEventForm(); // Скидаємо форму події 300 та 200 (EventForm300, EventForm200)
  }

  function getDisplayTypeInPaths(
    path: Pick<EventPath, 'selectedDisplayType' | 'children' | 'steps'>[],
  ): string[] {
    const result: string[][] = [];

    function traverse(
      event: Pick<EventPath, 'selectedDisplayType' | 'children' | 'steps'>,
    ): void {
      if (event?.selectedDisplayType?.length) {
        result.push([...event.selectedDisplayType]);
      }
      if (event.children?.length) {
        event.children.forEach(traverse);
      }
      if (event.steps?.length) {
        event.steps.forEach(traverse);
      }
    }

    path.forEach(traverse);

    return result.flat(Infinity) as string[];
  }

  function getLinkedObjectsInPaths(
    path: Pick<EventPath, 'children' | 'steps' | 'selectedLinkedObject'>[],
  ) {
    let result = {};

    function traverse(
      event: Pick<EventPath, 'children' | 'steps' | 'selectedLinkedObject'>,
    ): void {
      if (event.selectedLinkedObject) {
        result = { ...result, ...event.selectedLinkedObject };
      }
      if (event.children?.length) {
        event.children.forEach(traverse);
      }
      if (event.steps?.length) {
        event.steps.forEach(traverse);
      }
    }

    path.forEach(traverse);

    if ('parentObject' in result) {
      const { parentObject, ...linkedObject } = result;
      result = { [parentObject as string]: linkedObject };
    } else {
      result = {};
    }

    return result;
  }

  function handleLocationSelected(location: EventLocation) {
    eventStore.$patch({ location });

    // додавання кроку до шляху
    path.value.push({
      name: 'Координати',
      type: 'map',
      step: step.value,
    });
    history.value.push([...currentOptions.value]);

    // перехід до останнього кроку
    currentOptions.value = [{ type: 'notes' }];
  }

  return {
    // STATES
    step,
    path,
    history,
    options,
    currentType,
    positionData,
    selectedItem,
    currentOptions,
    inputTypeValue,
    isShowEventPreviewLastCreatedEventDialog,
    // COMPUTED
    inputType,
    actionsView,
    eventMessage,
    lastEventPaths,
    // METHOD
    handleNextButtonForInputType,
    handleSelection,
    handleInput,
    goTo,
    goBack,
    reproduceLastCreatedEvent,
    goNextStep,
    getDisplayTypeInPaths,
    getLinkedObjectsInPaths,
    resetToInitialState,
    handleLocationSelected,
  };
});
