import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

import { ref, computed } from 'vue';
import { useAdminStore } from '@/stores/admin';
import { usePositionsStore } from '@/stores/positions';
import EventTypeSelection from '@/views/EventCreationDialog.vue';
import { configUAV } from '@/config/configUAV';
import { EventType } from '@/types/events';


export default /*@__PURE__*/_defineComponent({
  __name: 'UavPilotView',
  setup(__props) {

const alert = ref({
  show: false,
  message: '',
  type: 'info',
});

const adminStore = useAdminStore();
const positionsStore = usePositionsStore();

const showEventTypeDialog = ref(false);
const currentOption = ref<EventType>();

const events = [{ ...configUAV }];

const resolveUserName = computed(() =>
  positionsStore.createCustomPosition(adminStore.currentUser!.callsign),
);

function handleEventSelection(event: EventType) {
  currentOption.value = event;
  showEventTypeDialog.value = true;
}

function handleEventSuccess(message: string) {
  showAlert(message, 'success');
}

function showAlert(message: string, type: 'success' | 'error') {
  alert.value = {
    show: true,
    message,
    type,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

return (_ctx: any,_cache: any) => {
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_dialog, {
      modelValue: showEventTypeDialog.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showEventTypeDialog).value = $event)),
      "max-width": "600px"
    }, {
      default: _withCtx(() => [
        _createVNode(EventTypeSelection, {
          columnsDisable: "",
          dialog: showEventTypeDialog.value,
          position: resolveUserName.value,
          currentOption: currentOption.value,
          currentOptions: events,
          "onUpdate:dialog": _cache[0] || (_cache[0] = ($event: any) => (showEventTypeDialog.value = $event)),
          onEventSuccess: handleEventSuccess,
          onEventError: _cache[1] || (_cache[1] = ($event: any) => (showAlert($event, 'error'))),
          onClose: _cache[2] || (_cache[2] = ($event: any) => (showEventTypeDialog.value = false))
        }, null, 8, ["dialog", "position", "currentOption"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_list, {
      class: "list-wrapper elevation-3 rounded-sm",
      lines: "one"
    }, {
      default: _withCtx(() => [
        (alert.value.show)
          ? (_openBlock(), _createBlock(_component_v_alert, {
              key: 0,
              class: "mb-3",
              type: alert.value.type,
              dismissible: "",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (alert.value.show = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(alert.value.message), 1)
              ]),
              _: 1
            }, 8, ["type"]))
          : _createCommentVNode("", true),
        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "mb-3 text-center" }, "Оберіть тип події", -1)),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(events, (event) => {
          return _createVNode(_component_v_list_item, {
            key: event.id,
            onClick: ($event: any) => (handleEventSelection(event)),
            title: event.title ?? event.name
          }, null, 8, ["onClick", "title"])
        }), 64))
      ]),
      _: 1
    })
  ]))
}
}

})