import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onMounted, computed } from 'vue';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';
import EventModelCount from '@/components/EventCreation/EventInputModelCount.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventTypeModelCount',
  props: {
  typeOfAmmunitions: {
    type: Array<string>,
    default: () => [],
  },
},
  emits: ['input'],
  setup(__props, { emit: __emit }) {

const eventSessionStore = useEventsSessionStore();

const emit = __emit;

const props = __props;

const models = ref<
  Array<{
    ammoType: string;
    ammoModel: string;
    ammoAmount: number;
    isCollapsed: boolean;
  }>
>([]);

const disabled = computed(
  () =>
    !models.value.some((model) => model.isCollapsed) ||
    !eventSessionStore.valid,
);

function proceed() {
  const filteredModels = models.value.filter(
    (model) => model.ammoAmount > 0 && model.isCollapsed,
  );

  const ammunitionDetails = filteredModels.map((model) => ({
    ammoModel: model.ammoModel,
    ammoAmount: model.ammoAmount,
    ammoType: model.ammoType,
  }));

  emit(
    'input',
    [
      `БЧ: ${ammunitionDetails
        .map(
          (model) =>
            `${model.ammoType}${
              model.ammoModel ? ` (${model.ammoModel}) ` : ' '
            }${model.ammoAmount} шт`,
        )
        .join(', ')}`,
    ],
    {
      parentObject: 'uavData',
      ammunitionType: ammunitionDetails
        .map((model) => model.ammoType)
        .join(', '),
      ammunitionDetails,
    },
  );
}
onMounted(() => {
  models.value = props.typeOfAmmunitions.map((ammoType) => ({
    ammoType,
    ammoModel: '',
    ammoAmount: 1,
    isCollapsed: false,
  }));
});

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(models.value, (model) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: model.name,
              class: "list-item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, {
                      class: "list-item-title",
                      onClick: ($event: any) => (model.isCollapsed = !model.isCollapsed)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(model.ammoType) + " ", 1),
                        _createVNode(_component_v_icon, {
                          icon: model.isCollapsed ? 'mdi-minus' : 'mdi-plus'
                        }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    (model.isCollapsed)
                      ? (_openBlock(), _createBlock(EventModelCount, {
                          key: 0,
                          label: "Модель",
                          ammoModel: model.ammoModel,
                          "onUpdate:ammoModel": ($event: any) => ((model.ammoModel) = $event),
                          ammoAmount: model.ammoAmount,
                          "onUpdate:ammoAmount": ($event: any) => ((model.ammoAmount) = $event)
                        }, null, 8, ["ammoModel", "onUpdate:ammoModel", "ammoAmount", "onUpdate:ammoAmount"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})