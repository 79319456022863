import Keycloak from 'keycloak-js';
import { loadEnv } from '@/services/envService';
import { useStore } from '@/services/store';

const INACTIVITY_LIMIT = 3 * 60 * 60 * 1000; // 3 hours

let keycloak = null;
let keycloakInitPromise = null;
let inactivityTimeout;

export async function getKeycloak() {
  if (keycloak) return keycloak;
  if (keycloakInitPromise) return keycloakInitPromise;

  keycloakInitPromise = (async () => {
    console.log('Initializing Keycloak...');
    const config = await loadEnv();

    const initOptions = {
      url: config.VUE_APP_KEYCLOAK_LOGIN_REDIRECT_URI,
      redirectUri: window.location.origin,
      realm: config.VUE_APP_KEYCLOAK_REALM,
      clientId: config.VUE_APP_KEYCLOAK_CLIENT_ID,
      pkceMethod: config.VUE_APP_KEYCLOAK_PKCE_METHOD,
    };

    keycloak = new Keycloak(initOptions);

    return keycloak;
  })();

  return keycloakInitPromise;
}

export async function authenticateUser() {
  const keycloak = await getKeycloak();
  const authenticated = await keycloak.init({
    onLoad: 'login-required',
  });
  try {
    if (authenticated) {
      console.log('Authenticated successfully!');
      sessionStorage.setItem('accessToken', keycloak.token);

      return;
    }

    console.warn('Authentication failed, redirecting to login...');
    await keycloak.login();
  } catch (error) {
    console.error('Error during Keycloak initialization or login:', error);
    throw error;
  }
}

export async function logout() {
  console.log('Logging out...');
  sessionStorage.removeItem('accessToken');

  const store = useStore();
  store.clearAll();

  try {
    const keycloak = await getKeycloak();
    if (keycloak.idToken) {
      await keycloak.logout({
        redirectUri: window.location.origin,
        id_token_hint: keycloak.idToken,
      });
    } else {
      await keycloak.logout({ redirectUri: window.location.origin });
    }
  } catch (error) {
    console.error('Logout failed:', error);
  }
}

export async function startRefreshingToken() {
  const config = await loadEnv();

  setInterval(async () => {
    await refreshToken(config);
  }, config.VUE_APP_KEYCLOAK_REFRESH_INTERVAL);
}

export async function refreshToken(config) {
  try {
    const keycloak = await getKeycloak();
    const res = await keycloak.updateToken(
      config.VUE_APP_KEYCLOAK_REFRESH_MINIMUM_VALIDITY,
    );
    if (res) console.log('Token refreshed!');
    sessionStorage.setItem('accessToken', keycloak.token);
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
}

function resetInactivityTimer() {
  clearTimeout(inactivityTimeout);
  inactivityTimeout = setTimeout(async () => {
    await logout();
  }, INACTIVITY_LIMIT);
}

window.addEventListener('click', resetInactivityTimer);
resetInactivityTimer();
