import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center ga-3" }
const _hoisted_2 = { class: "d-flex align-center justify-end ga-3" }

import { watch } from 'vue';
import { rules } from '@/utils/rules';

const MIN_VALUE = 0;
const MAX_VALUE = 99999;


export default /*@__PURE__*/_defineComponent({
  __name: 'EventInputNumber',
  props: /*@__PURE__*/_mergeModels({
    label: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const model = _useModel(__props, "modelValue");


function handleKeydown(event: KeyboardEvent) {
  if (['e', 'E', '+', '-', '.'].includes(event.key)) {
    event.preventDefault();
  }
}

function increment() {
  if ((model.value as number) < MAX_VALUE) (model.value as number)++;
}

function decrement() {
  if ((model.value as number) > MIN_VALUE) (model.value as number)--;
}

watch(model, (value, prev) => {
  let stringValue = (model.value as number).toString();
  let regex = /^\d{0,5}(?:\.\d{1,5})?$/;
  if (!stringValue.match(regex) && value) {
    model.value = prev;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_text_field, {
              modelValue: model.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
              modelModifiers: { number: true, trim: true },
              label: _ctx.label,
              outlined: "",
              "hide-details": "auto",
              type: "number",
              onKeydown: handleKeydown,
              class: "numberInput",
              rules: [_unref(rules).isInteger]
            }, null, 8, ["modelValue", "label", "rules"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                class: "custom-default-btn",
                size: "x-small",
                icon: "mdi-minus",
                onClick: decrement,
                disabled: !model.value
              }, null, 8, ["disabled"]),
              _createVNode(_component_v_btn, {
                class: "custom-default-btn",
                size: "x-small",
                icon: "mdi-plus",
                onClick: increment,
                disabled: model.value >= MAX_VALUE
              }, null, 8, ["disabled"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})