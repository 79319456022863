import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue';
import { rules } from '@/utils/rules';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventExpenditureBalance',
  emits: ['input'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const eventSessionStore = useEventsSessionStore();

const expenditure = ref('');
const balance = ref('');
const disabled = computed(
  () => !eventSessionStore.valid || !expenditure.value || !balance.value,
);

function proceed() {
  const values = [];
  if (expenditure.value) {
    values.push(`Витрати: ${expenditure.value}`);
  }

  if (balance.value) {
    values.push(`Залишок: ${balance.value}`);
  }

  emit('input', values);
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Витрати",
                modelValue: expenditure.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((expenditure).value = $event)),
                modelModifiers: { trim: true },
                type: "text",
                rules: [_unref(rules).maxLength(100)]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Залишок",
                modelValue: balance.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((balance).value = $event)),
                modelModifiers: { trim: true },
                type: "text",
                rules: [_unref(rules).maxLength(100)]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})