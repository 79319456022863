import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-3"
}

import { useEventStore } from '@/stores/event';
import { useEventsSessionStore } from '@/stores/eventSession';
import { useEventCreationStore } from '@/stores/eventCreation';
import EventButton from '@/components/EventCreation/EventButton.vue';
import EventPreviewDialog from '@/components/EventCreation/EventPreviewDialog.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCardActions',
  emits: ["handleSubmit"],
  setup(__props) {

const eventStore = useEventStore();

const eventCreationStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();



return (_ctx: any,_cache: any) => {
  const _component_v_card_actions = _resolveComponent("v-card-actions")!

  return (_unref(eventStore).actionsView && _unref(eventStore).path.length)
    ? (_openBlock(), _createBlock(_component_v_card_actions, {
        key: 0,
        class: "px-sm-6 pt-3 pb-2 d-flex justify-end mt-0"
      }, {
        default: _withCtx(() => [
          (_unref(eventStore).currentType === 'notes')
            ? (_openBlock(), _createBlock(EventPreviewDialog, {
                key: 0,
                position: _unref(eventStore).positionData,
                eventMessage: _unref(eventStore).eventMessage
              }, {
                title: _withCtx(() => [
                  _createVNode(EventPreviewContent, {
                    position: _unref(eventStore).positionData,
                    eventMessage: _unref(eventStore).eventMessage,
                    onHandleSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleSubmit')))
                  }, null, 8, ["position", "eventMessage"])
                ]),
                default: _withCtx(() => [
                  (_unref(eventStore).currentType === 'notes')
                    ? (_openBlock(), _createBlock(EventButton, {
                        key: 0,
                        disabled: _unref(eventCreationStore).loading,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleSubmit'))),
                        class: "custom-green-btn"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("Створити подію")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["position", "eventMessage"]))
            : _createCommentVNode("", true),
          (_unref(eventStore).path.length > 0)
            ? (_openBlock(), _createBlock(EventButton, {
                key: 1,
                class: "custom-default-btn",
                back: ""
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Назад")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(eventStore).inputType)
            ? (_openBlock(), _createBlock(EventButton, {
                key: 2,
                class: "custom-green-btn px-10",
                onClick: _unref(eventStore).handleNextButtonForInputType,
                disabled: !_unref(eventSessionStore).valid || !_unref(eventStore).inputTypeValue,
                enterTrigger: ""
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Далі")
                ])),
                _: 1
              }, 8, ["onClick", "disabled"]))
            : _createCommentVNode("", true),
          (_unref(eventStore).currentType === 'notes')
            ? (_openBlock(), _createBlock(EventButton, {
                key: 3,
                disabled: _unref(eventCreationStore).loading,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleSubmit'))),
                class: "custom-green-btn"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Створити подію")
                ])),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})