<template>
  <v-btn
    ref="buttonExposeRef"
    :class="props.class"
    :disabled="props.disabled"
    @click="props.back ? eventStore.goBack() : $emit('click')"
    :size="smAndUp ? 'large' : 'default'"
  >
    <slot />
  </v-btn>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import { useEventStore } from '@/stores/event';
import { onBeforeUnmount, onMounted, onUnmounted } from 'vue';

const eventStore = useEventStore();

const props = defineProps<{
  disabled?: boolean;
  class: string;
  back?: boolean;
  enterTrigger?: boolean;
}>();

const { smAndUp } = useDisplay();

const emit = defineEmits(['click']);

function handleEnterPress(e: KeyboardEvent) {
  if (e.key !== 'Enter') return;
  if (!props.back && props.enterTrigger && !props.disabled) emit('click');
}

onMounted(() => {
  if (props.enterTrigger) {
    window.addEventListener('keypress', handleEnterPress);
  }
});

onBeforeUnmount(() => {
  if (props.enterTrigger) {
    window.removeEventListener('keypress', handleEnterPress);
  }
});

onUnmounted(() => {
  if (props.enterTrigger) {
    window.removeEventListener('keypress', handleEnterPress);
  }
});
</script>
