import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue';
import EventButton from '@/components/EventCreation/EventButton.vue';
import EventModelCount from '@/components/EventCreation/EventInputModelCount.vue';

type LinkedObjectType = {
  ammunitionDetails?: Array<{
    ammoType: string;
    ammoModel: string;
    ammoAmount: number;
  }>;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EventModelCount',
  props: {
    ammoType: {}
  },
  emits: ['input'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const ammoModel = ref('');
const ammoAmount = ref(1);
const disabled = computed(() => !(ammoAmount.value > 0));

function proceed() {
  const values: string[] = [];
  const linkedObject: LinkedObjectType = {};

  linkedObject.ammunitionDetails = [
    {
      ammoAmount: ammoAmount.value,
      ammoModel: ammoModel.value,
      ammoType: props.ammoType,
    },
  ];

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(EventModelCount, {
            label: "Модель",
            ammoModel: ammoModel.value,
            "onUpdate:ammoModel": _cache[0] || (_cache[0] = ($event: any) => ((ammoModel).value = $event)),
            ammoAmount: ammoAmount.value,
            "onUpdate:ammoAmount": _cache[1] || (_cache[1] = ($event: any) => ((ammoAmount).value = $event))
          }, null, 8, ["ammoModel", "ammoAmount"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})