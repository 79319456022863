<template>
  <v-dialog
    :model-value="dialogVisible"
    @update:model-value="$emit('update:dialogVisible', $event)"
    max-width="500px"
    @click:outside="handleClose"
  >
    <v-card>
      <v-card-title>
        {{ isEditing ? 'Редагувати Підрозділ' : 'Створити Підрозділ' }}
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="alert.show"
          :type="alert.type"
          dismissible
          @input="alert.show = false"
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>

        <v-form ref="formRef" v-model="formValid">
          <v-text-field
            v-model="localForm.name"
            label="Назва"
            hide-details
            :rules="[(v) => !!v || 'Назва обовязкова']"
            required
          />

          <div class="chat-section">
            <h4>
              Signal чати
              <v-icon @click="showSignalInput = !showSignalInput">
                {{
                  showSignalInput
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'
                }}
              </v-icon>
            </h4>
            <v-text-field
              v-if="showSignalInput"
              v-model="newSignalChat.name"
              label="Назва чату"
              hide-details
              @keyup.enter="handleAddSignalChat"
              @blur="handleAddSignalChat"
              @click:append-inner="handleAddSignalChat"
              append-inner-icon="mdi-plus"
            />
            <div v-if="localForm.signalChatsData.length" class="chat-chips">
              <v-chip
                v-for="chat in localForm.signalChatsData"
                :key="`signal-${chat.name}`"
                :color="`${chat.systemChat ? 'primary' : ''}`"
                closable
                @click:close="() => handleRemoveChat('signalChatsData', chat)"
              >
                <template v-slot:default>
                  {{ chat.name }}
                  <v-checkbox
                    v-if="adminStore.currentUser?.role === UserRole.admin"
                    :label="'системний'"
                    class="small-checkbox compact-checkbox"
                    dense
                    hide-details
                    :model-value="chat.systemChat"
                    @update:model-value="
                      (newVal) => makeSignalChatSystem(newVal, chat)
                    "
                  />
                </template>
              </v-chip>
            </div>
          </div>

          <!-- WhatsApp Chats Section -->
          <div class="chat-section">
            <h4>
              WhatsApp чати
              <v-icon @click="showWhatsappInput = !showWhatsappInput">
                {{
                  showWhatsappInput
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'
                }}
              </v-icon>
            </h4>
            <v-text-field
              v-if="showWhatsappInput"
              v-model="newWhatsappChat.name"
              label="Назва чату"
              hide-details
              @keyup.enter="handleAddWhatsappChat"
              @blur="handleAddWhatsappChat"
              @click:append-inner="handleAddWhatsappChat"
              append-inner-icon="mdi-plus"
            />
            <div v-if="localForm.whatsappChatsData.length" class="chat-chips">
              <v-chip
                v-for="chat in localForm.whatsappChatsData"
                :key="`whatsapp-${chat.name}`"
                :color="`${chat.systemChat ? 'primary' : ''}`"
                closable
                @click:close="handleRemoveChat('whatsappChatsData', chat)"
              >
                <template v-slot:default>
                  {{ chat.name }}
                  <v-checkbox
                    v-if="adminStore.currentUser?.role === UserRole.admin"
                    :label="'системний'"
                    class="small-checkbox compact-checkbox"
                    dense
                    hide-details
                    :model-value="chat.systemChat"
                    @update:model-value="
                      (newVal) => makeWhatsAppChatSystem(newVal, chat)
                    "
                  />
                </template>
              </v-chip>
            </div>
          </div>
          <!-- Element Chats Section* -->
          <div class="chat-section">
            <h4>
              Element чати
              <v-icon @click="showElementInput = !showElementInput">
                {{
                  showElementInput
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'
                }}
              </v-icon>
            </h4>
            <v-text-field
              v-if="showElementInput"
              v-model="newElementChat.name"
              label="Назва чату"
              hide-details
            />
            <v-text-field
              v-if="showElementInput"
              v-model="newElementChat.roomId"
              label="Room ID"
              hide-details
              @keyup.enter="handleAddElementChat"
              @blur="
                () => newElementChat.roomId.trim() && handleAddElementChat()
              "
              @click:append-inner="handleAddElementChat"
              append-inner-icon="mdi-plus"
            />
            <div v-if="localForm.elementChatsData.length" class="chat-chips">
              <v-chip
                v-for="chat in localForm.elementChatsData"
                :key="`element-${chat.roomId}`"
                :color="`${chat.systemChat ? 'primary' : ''}`"
                closable
                @click:close="() => handleRemoveChat('elementChatsData', chat)"
              >
                <template v-slot:default>
                  {{ chat.name }}
                  <v-checkbox
                    v-if="adminStore.currentUser?.role === UserRole.admin"
                    :label="'системний'"
                    class="small-checkbox compact-checkbox"
                    dense
                    hide-details
                    :model-value="chat.systemChat"
                    @update:model-value="
                      (newVal) => makeElementChatSystem(newVal, chat)
                    "
                  />
                </template>
              </v-chip>
            </div>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn variant="elevated" color="black" @click="handleClose">
          Відмінити
        </v-btn>
        <v-btn
          class="custom-green-btn"
          @click="handleSave"
          :disabled="!formValid"
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';
import { useAdminStore } from '@/stores/admin';
import { UserRole } from '@/types/admin';
const adminStore = useAdminStore();

interface Props {
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['battalion'];
}

type Chat = { name: string; systemChat: boolean; config: any; roomId?: string };

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:dialogVisible', value: boolean): void;
  (e: 'save', isEdit: boolean, formData: FormState['battalion']): void;
  (e: 'close'): void;
}>();

const formRef = ref<any>(null);
const formValid = ref(false);
const showSignalInput = ref(false);
const showWhatsappInput = ref(false);
const showElementInput = ref(false);

const localForm = reactive({
  id: null as string | null,
  name: '',
  signalChatsData: [] as Chat[],
  whatsappChatsData: [] as Chat[],
  elementChatsData: [] as Chat[],
});

watch(
  () => props.form,
  (newForm) => {
    localForm.id = newForm.id;
    localForm.name = newForm.name;
    localForm.signalChatsData = [...newForm.signalChatsData];
    localForm.whatsappChatsData = [...newForm.whatsappChatsData];
    localForm.elementChatsData = [...newForm.elementChatsData];
  },
  { immediate: true, deep: true },
);

const makeSignalChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.signalChatsData.map((c) =>
    c.name === chat.name ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.signalChatsData = updatedChats;
};

const makeWhatsAppChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.whatsappChatsData.map((c) =>
    c.name === chat.name ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.whatsappChatsData = updatedChats;
};

const makeElementChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.elementChatsData.map((c) =>
    c.roomId === chat.roomId ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.elementChatsData = updatedChats;
};

const alert = ref({
  show: false,
  type: 'error' as const,
  message: '',
});

const newSignalChat = ref({
  name: '',
  systemChat: false,
});
const newWhatsappChat = ref({
  name: '',
  systemChat: false,
});
const newElementChat = ref({
  name: '',
  roomId: '',
  systemChat: false,
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.value = {
    show: true,
    type,
    message,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

function handleAddSignalChat() {
  const trimmedChat = newSignalChat.value.name.trim();
  if (
    trimmedChat &&
    !localForm.signalChatsData.some((chat) => chat.name === trimmedChat)
  ) {
    localForm.signalChatsData.push({
      name: removeExtraSybolsFromSignalName(trimmedChat),
      systemChat: newSignalChat.value.systemChat,
      config: null,
    });
    newSignalChat.value = { name: '', systemChat: false };
    console.log('Added to signalChatsData:', localForm.signalChatsData); // Debug log for added chat
  }
}

function handleAddWhatsappChat() {
  const trimmedChat = newWhatsappChat.value.name.trim();
  if (
    trimmedChat &&
    !localForm.whatsappChatsData.some((chat) => chat.name === trimmedChat)
  ) {
    localForm.whatsappChatsData.push({
      name: trimmedChat,
      systemChat: newSignalChat.value.systemChat,
      config: null,
    });
    newWhatsappChat.value = { name: '', systemChat: false };
    console.log('Added to whatsappChatsData:', localForm.whatsappChatsData); // Debug log for added chat
  }
}

function handleAddElementChat() {
  const trimmedChatName = newElementChat.value.name.trim();
  const trimmedChatRoomId = newElementChat.value.roomId.trim();
  if (
    trimmedChatName &&
    !localForm.elementChatsData.some((chat) => chat.name === trimmedChatName)
  ) {
    localForm.elementChatsData.push({
      name: trimmedChatName,
      systemChat: newElementChat.value.systemChat,
      roomId: trimmedChatRoomId,
      config: null,
    });
    newElementChat.value = { name: '', roomId: '', systemChat: false };
    console.log('Added to element:', localForm.elementChatsData); // Debug log for added chat
  }
}

function handleRemoveChat(
  type: 'signalChatsData' | 'whatsappChatsData' | 'elementChatsData',
  chat: { name: string; config: any },
) {
  localForm[type] = localForm[type].filter((c) => c.name !== chat.name);
  console.log(`Removed from ${type}:`, localForm[type]); // Debug log for removed chat
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  // Convert reactive proxy to plain object
  const plainLocalForm = JSON.parse(JSON.stringify(localForm));

  emit('save', props.isEditing, plainLocalForm);
}

function handleClose() {
  emit('update:dialogVisible', false);
  emit('close');
  formRef.value?.resetValidation();
}

function removeExtraSybolsFromSignalName(name: string) {
  return name.replaceAll('⁩', '');
}
</script>

<style scoped>
.compact-checkbox {
  --v-checkbox-label-spacing: 2px; /* Adjust label spacing */
  transform: scale(0.8); /* Resize the checkbox */
  margin-left: -10px;
  margin-right: -10px;
}
.chat-section {
  margin-top: 20px;
}

.chat-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.custom-green-btn {
  background-color: #4caf50;
  color: white;
}

.custom-red-btn {
  background-color: #d32f2f;
  color: white;
}
</style>
