import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import EventButton from '@/components/EventCreation/EventButton.vue';
import { useEventsSessionStore } from '@/stores/eventSession';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventForm200',
  emits: ['input'],
  setup(__props, { emit: __emit }) {

const eventSessionStore = useEventsSessionStore();

const emit = __emit;

const disabled = computed(() => eventSessionStore.eventForm200.length === 0);

function addCallSign(target: HTMLInputElement) {
  target.blur();
  target.focus();
}

function proceed() {
  const values: string[] = [];

  if (eventSessionStore.eventForm200.length > 1) {
    values.push(`Позивні: ${eventSessionStore.eventForm200.join(', ')}`);
  } else {
    values.push(`Позивний: ${eventSessionStore.eventForm200.at(0)}`);
  }

  emit('input', values);
}

return (_ctx: any,_cache: any) => {
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto mb-2",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_combobox, {
                modelValue: _unref(eventSessionStore).eventForm200,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(eventSessionStore).eventForm200) = $event)),
                chips: "",
                "closable-chips": "",
                label: "Позивні",
                multiple: "",
                "append-inner-icon": "mdi-plus",
                "onClick:appendInner": _cache[1] || (_cache[1] = ($event: any) => (addCallSign(_ctx.$el.querySelector('input'))))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})