import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-warning text-caption mt-1.5 mx-4 mb-2" }

import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { rules } from '@/utils/rules';
import { useEventStore } from '@/stores/event';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';
import { MGRS_ZONE_AND_SQUARE } from '@/config/constants';

type LinkedObjectType = {
  coordinates?: string;
  square?: string;
  targetDetails?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCoordinatesSquareFrom',
  props: {
    type: {}
  },
  emits: ['input'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;



const eventStore = useEventStore();
const eventSessionStore = useEventsSessionStore();

const coordinatesError = ref(false);

const square = ref('');
const coordinates = ref('');
const targetDestruction = ref('');

const coordinatesSquareComputed = computed(() => {
  try {
    return localStorage.getItem(MGRS_ZONE_AND_SQUARE) ?? '';
  } catch (error) {
    console.error(error);
    return '';
  }
});

const disabled = computed(
  () =>
    !eventSessionStore.valid ||
    (!!coordinates.value &&
      coordinatesSquareComputed.value === coordinates.value) ||
    (!coordinates.value.length && !square.value),
);

function applyMGRSMask(e: Event) {
  const target = e.target as HTMLInputElement;

  let value = target.value.toUpperCase();

  // Видаляємо все, що не є літерою або цифрою
  value = value.replace(/[^A-Z0-9]/g, '');

  // Формуємо маску
  let maskedValue = '';

  if (value.length > 0) maskedValue += value.slice(0, 2); // Перші 2 цифри
  if (value.length > 2) maskedValue += value[2]; // 1 літера
  if (value.length > 3) maskedValue += ' ' + value.slice(3, 5); // 2 літери
  if (value.length > 5) maskedValue += ' ' + value.slice(5, 10); // 5 цифр
  if (value.length > 10) maskedValue += ' ' + value.slice(10, 15); // 5 цифр

  // Оновлюємо значення поля
  coordinates.value = maskedValue;

  // Зберігаємо позицію курсора
  const cursorPosition = target.selectionStart;

  // Обчислюємо нову позицію курсора
  let newCursorPosition = cursorPosition;

  // Якщо ми додали пробіл, то курсор має зсунутися на 1 вправо
  if (maskedValue.length > target.value.length && newCursorPosition) {
    newCursorPosition += 1;
  }

  // Встановлюємо курсор на правильну позицію
  nextTick(() => {
    target.setSelectionRange(newCursorPosition, newCursorPosition);
  });
}

function clearCoordinates() {
  coordinates.value = '';
  localStorage.removeItem(MGRS_ZONE_AND_SQUARE);
}
function focusCoordinates(focused: boolean) {
  // Перевіряємо чи є дані в localStorage, якщо є, то виводимо їх в поле coordinates
  if (!coordinates.value && focused) {
    coordinates.value = coordinatesSquareComputed.value;
  }
  // Якщо дані в полі coordinates співпадають з даними в localStorage, то очищаємо поле
  if (coordinates.value === coordinatesSquareComputed.value && !focused) {
    coordinates.value = '';
  }
}

function proceed() {
  const values = [];
  const linkedObject: LinkedObjectType = {};

  if (targetDestruction.value) {
    values.push(`Опис цілі: ${targetDestruction.value}`);
    linkedObject.targetDetails = targetDestruction.value;
  }
  if (coordinates.value && coordinates.value.length > 6) {
    values.push(`Координати: ${coordinates.value}`);
    linkedObject.coordinates = coordinates.value;
    //Записуємо значення зони так квадрату координат в localStorage
    localStorage.setItem(
      MGRS_ZONE_AND_SQUARE,
      coordinates.value.split(' ').slice(0, 2).join(' '),
    );
    // Перевіряємо чи введені координати
    eventSessionStore.isCoordinatesFilled = true;
  } else {
    // Якщо координати не введені, то видаляємо дані з localStorage після відправки події на кроці "notes"
    eventSessionStore.isCoordinatesFilled = false;
  }

  if (square.value) {
    values.push(`Квадрат: ${square.value}`);
    linkedObject.square = square.value;
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

onMounted(() => {
  // Записуємо введені дані, на попередньому кроці "ціль" в targetDestruction
  const targetDetails = eventStore.path.at(-1)?.linkedObject?.targetDetails;
  if (targetDetails) {
    targetDestruction.value = targetDetails as string;
  }
});

watch(
  () => coordinates.value,
  () => {
    if (!rules.isMGRS(coordinates.value) && coordinates.value.length > 0) {
      coordinatesError.value = true;
    } else {
      coordinatesError.value = false;
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Координати",
                modelValue: coordinates.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((coordinates).value = $event)),
                modelModifiers: { trim: true },
                type: "text",
                placeholder: "37U DQ 12345 54321",
                clearable: "",
                "hide-details": "",
                onInput: applyMGRSMask,
                "onUpdate:focused": focusCoordinates,
                "onClick:clear": clearCoordinates,
                color: coordinatesError.value ? 'warning' : 'default'
              }, null, 8, ["modelValue", "color"]),
              _createElementVNode("p", _hoisted_1, _toDisplayString(coordinatesError.value ? 'Введіть координати в форматі MGRS' : ''), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Квадрат",
                modelValue: square.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((square).value = $event)),
                modelModifiers: { trim: true },
                type: "text",
                rules: [_unref(rules).maxLength(100)]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          (_ctx.type === 'coordinates-square-target-destruction')
            ? (_openBlock(), _createBlock(_component_v_responsive, {
                key: 0,
                class: "mx-auto",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Опис цілі",
                    modelValue: targetDestruction.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((targetDestruction).value = $event)),
                    rules: [_unref(rules).maxLength(100)],
                    type: "text"
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})