<template>
  <v-data-table-server
    :headers="headers"
    :items="eventsStore.formattedEvents"
    :items-length="eventsStore.eventsCount"
    :items-per-page="itemsPerPageLocal"
    :loading="eventsStore.loading"
    :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }"
    class="elevation-1"
    item-value="id"
    @update:options="fetchEvents"
    :items-per-page-options="[10, 25, 50, 100]"
    :disable-sort="true"
  >
  </v-data-table-server>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useEventsStore } from '@/stores/events';

const headers = [
  { title: 'Позиція', key: 'position' },
  { title: 'Тип події', key: 'type' },
  { title: 'Позивний репортера', key: 'reporter.callsign' },
  { title: 'Час події', key: 'time' },
  { title: 'Додаткові нотатки', key: 'notes' },
  { title: 'Азимут', key: 'azimuth' },
  { title: 'Дистанція', key: 'distance' },
];

const eventsStore = useEventsStore();
const props = defineProps({
  startDate: {
    type: String || null,
  },
  endDate: {
    type: String || null,
  },
});
const itemsPerPageLocal = ref(50);

watch(
  () => [props.startDate, props.endDate],
  () => {
    eventsStore.fetchEvents(
      1,
      itemsPerPageLocal.value,
      props.startDate,
      props.endDate,
    );
  },
);

const fetchEvents = ({ page, itemsPerPage }) => {
  itemsPerPageLocal.value = itemsPerPage;
  eventsStore.fetchEvents(
    page,
    itemsPerPageLocal.value,
    props.startDate,
    props.endDate,
  );
};
</script>

<style scoped>
.mgrs-column-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.v-data-table-header th) {
  font-weight: bold !important;
  background-color: #f5f5f5;
  text-align: center;
}
</style>
