<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-text-field
        v-model.trim="model"
        :label="label"
        outlined
        hide-details="auto"
        :rules="[rules.maxLength(100)]"
      ></v-text-field>
    </v-card-text>
    <v-card-actions
      class="d-flex justify-end mt-0"
      v-if="type === 'input-optional'"
    >
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button class="custom-green-btn px-10" @click="proceed" enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { rules } from '@/utils/rules';
import EventButton from '@/components/EventCreation/EventButton.vue';

const props = defineProps<{
  label: string;
  type: 'input' | 'input-optional' | 'input-without-display-type';
  displayType: string | undefined;
  linkedObject: LinkedObjectType | undefined;
}>();

import { LinkedObjectType } from '@/types/events';

const model = defineModel({ required: true });

const emit = defineEmits(['input']);

function proceed() {
  const values: string[] = [];
  const linkedObject: Record<string, string> = {};

  const linkedObjectKey = Object.keys(props.linkedObject ?? {}).filter(
    (i) => i !== 'parentObject',
  )[0];

  if (model.value) {
    values.push(`${props.displayType}${model.value}`);
    if (linkedObjectKey) {
      linkedObject[linkedObjectKey] = model.value as string;
    }
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}
</script>
