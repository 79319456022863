// types/admin.ts

export enum UserRole {
  'user' = 'user',
  'stat' = 'stat',
  'admin' = 'admin',
  'uav_stat' = 'uav_stat',
  'uav_pilot' = 'uav_pilot',
  'squadron_admin' = 'squadron_admin',
  'battalion_admin' = 'battalion_admin',
}

export interface RoleTranslations {
  user: string;
  stat: string;
  uav_stat: string;
  uav_pilot: string;
  admin: string;
  battalion_admin: string;
  squadron_admin: string;
}

export interface User {
  id: string;
  username: string;
  rank?: string;
  surnameAndInitials?: string;
  role: UserRole;
  callsign: string;
  squadronId: string;
  squadron?: Squadron;
}

export interface Squadron {
  id: string;
  name: string;
  battalionId: string;
  battalionName?: string;
  isService?: boolean;
  battalion?: Battalion;
}

export interface Battalion {
  id: string;
  name: string;
  isService?: boolean;
  signalChatsData: ChatData[];
  whatsappChatsData: ChatData[];
  elementChatsData: ChatData[];
}

export interface Position {
  id: string;
  name: string;
  description?: string;
  squadronId: string;
  squadronName?: string;
  battalionName?: string;
  isGroup: boolean;
  squadron?: Squadron;
}

export interface ChatData {
  name: string;
  config: ChatConfig;
  systemChat: boolean;
  roomId?: string;
}

export interface ChatConfig {
  messageTemplate: string;
  messageType: string;
  isActive: boolean;
  interval: number;
}

export interface AlertState {
  show: boolean;
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
}

export interface BattalionMap {
  [key: string]: string;
}

export interface SquadronResponse {
  id: string;
  name: string;
  battalionId: string;
  [key: string]: any;
}

export interface FilterItems {
  id: string;
  name?: string;
  display?: string;
}

export interface FormState {
  user: {
    id: string | null;
    username: string;
    password: string;
    role: UserRole;
    callsign: string;
    rank?: string;
    surnameAndInitials?: string;
    squadronId: string;
  };
  squadron: {
    id: string | null;
    name: string;
    battalionId: string | null;
  };
  battalion: {
    id: string | null;
    name: string;
    signalChatsData: ChatData[];
    whatsappChatsData: ChatData[];
    elementChatsData: ChatData[];
  };
  position: {
    id: string | null;
    name: string;
    description: string;
    squadronId: string;
    isGroup: boolean;
  };
}

export interface TableHeaders {
  title: string;
  key: string;
  sortable?: boolean;
}

export interface DialogState {
  show: boolean;
  isEditing: boolean;
  currentItem: any;
}

export interface ChatConfigState {
  name: string;
  type: 'signal' | 'whatsapp' | 'element';
  battalion: Battalion;
  config: ChatConfig;
}

export interface ConfirmDialogState {
  show: boolean;
  item: any;
  type: 'user' | 'squadron' | 'battalion' | 'position';
}

export enum ActiveView {
  USERS = 'users',
  SQUADRONS = 'squadrons',
  BATTALIONS = 'battalions',
  POSITIONS = 'positions',
}
