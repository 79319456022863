import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "table-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "battalion-name" }
const _hoisted_5 = { class: "actions-container" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "chat-names-container"
}
const _hoisted_8 = {
  key: 0,
  class: "chat-names-container"
}
const _hoisted_9 = {
  key: 0,
  class: "chat-names-container"
}
const _hoisted_10 = { class: "actions-container" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 0,
  class: "description"
}
const _hoisted_13 = { class: "actions-container" }
const _hoisted_14 = {
  key: 0,
  class: "description"
}
const _hoisted_15 = { class: "actions-container" }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = ["onClick"]

import { ref, computed, onMounted, toRaw } from 'vue';
import apiClient from '@/services/axios';
import { useAdminStore } from '@/stores/admin';
import { useAdminDialogs } from '@/composables/useAdminDialogs';
import { User, Battalion, Position, ActiveView } from '@/types/admin';

// Import dialog components
import CreateEditUserDialog from '@/components/Admin/Dialogs/CreateEditUserDialog.vue';
import CreateEditSquadronDialog from '@/components/Admin/Dialogs/CreateEditSquadronDialog.vue';
import CreateEditBattalionDialog from '@/components/Admin/Dialogs/CreateEditBattalionDialog.vue';
import CreateEditPositionDialog from '@/components/Admin/Dialogs/CreateEditPositionDialog.vue';
import ChatConfigDialog from '@/components/Admin/Dialogs/ChatConfigDialog.vue';

import NotificationAlert from '@/components/NotificationAlert.vue';

// Initialize store and composables

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminView',
  setup(__props) {

const adminStore = useAdminStore();
const dialogs = useAdminDialogs();

// Confirm dialog state
const confirmDialog = ref({
  show: false,
  item: null as any,
  type: '' as 'user' | 'squadron' | 'battalion' | 'position',
});

// Computed properties
const showFilters = computed(
  () =>
    adminStore.activeView === ActiveView.SQUADRONS ||
    adminStore.activeView === ActiveView.POSITIONS,
);

const userSquadron = computed(
  () =>
    adminStore.squadrons.find(
      (sq) => sq.id === adminStore.currentUser?.squadronId,
    )?.name || '',
);

const userBattalion = computed(
  () =>
    adminStore.battalions.find(
      (b) => b.id === adminStore.currentUser?.squadron?.battalionId,
    )?.name || '',
);

const regularPositions = computed(() =>
  adminStore.filteredPositions.filter((position) => !position.isGroup),
);

const groupPositions = computed(() =>
  adminStore.filteredPositions.filter((position) => position.isGroup),
);

// Permission computed properties
const canEditSquadrons = computed(
  () => adminStore.currentUser?.role !== 'squadron_admin',
);

const canEditBattalions = computed(
  () => adminStore.currentUser?.role !== 'squadron_admin',
);

const canEditPositions = computed(() =>
  ['admin', 'battalion_admin', 'squadron_admin'].includes(
    adminStore.currentUser?.role || '',
  ),
);

// Table headers
const squadronHeaders = [
  {
    title: 'Ім`я',
    key: 'name',
    width: '80%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const battalionHeaders = [
  {
    title: 'Назва',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Signal чати',
    key: 'signalChatsData',
    width: '30%',
  },
  {
    title: 'WhatsApp чати',
    key: 'whatsappChatsData',
    width: '30%',
  },
  {
    title: 'Element чати',
    key: 'elementChatsData',
    width: '20%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const positionHeaders = [
  {
    title: 'Назва (Опис)',
    key: 'name',
    width: '55%',
  },
  {
    title: 'Підрозділ',
    key: 'squadron',
    width: '25%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const userHeaders = [
  {
    title: 'Ім`я користувача',
    key: 'username',
    width: '20%',
  },
  {
    title: 'Роль',
    key: 'role',
    width: '20%',
  },
  {
    title: 'Позивний',
    key: 'callsign',
    width: '20%',
  },
  {
    title: 'Підрозділ',
    key: 'squadron',
    width: '20%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

// Save handlers - Updated with proper dialog state handling
async function handleUserSave(isEdit: boolean, formData: User) {
  try {
    const userData = {
      username: formData.username,
      password: formData.password,
      role: formData.role,
      callsign: formData.callsign,
      squadronId: formData.squadronId,
      surnameAndInitials: formData.surnameAndInitials,
      rank: formData.rank,
    };

    if (isEdit && formData.id) {
      await adminStore.updateUser(formData.id, userData);
    } else {
      await adminStore.createUser(userData);
    }

    dialogs.dialogStates.userDialog = false;
    dialogs.resetUserForm();
  } catch (error) {
    console.error('Error saving user:', error);
  }
}

async function handleSquadronSave(isEdit: boolean) {
  try {
    if (isEdit) {
      await adminStore.updateSquadron(
        dialogs.squadronForm.id!,
        dialogs.squadronForm,
      );
    } else {
      await adminStore.createSquadron(dialogs.squadronForm);
    }
    dialogs.dialogStates.squadronDialog = false;
    dialogs.resetSquadronForm();
  } catch (error) {
    console.error('Error saving squadron:', error);
  }
}

function formatChatData(chatData) {
  return chatData.map((chat) => {
    if (typeof chat === 'object' && 'name' in chat) {
      return chat; // Already formatted correctly
    } else {
      // Format to match the expected structure
      return { name: chat, config: null };
    }
  });
}

async function handleBattalionSave(isEdit: boolean, formData: Battalion) {
  // Format chats before saving
  const dataToSave = toRaw(formData);
  dataToSave.signalChatsData = formatChatData(dataToSave.signalChatsData);
  dataToSave.whatsappChatsData = formatChatData(dataToSave.whatsappChatsData);

  try {
    if (isEdit && formData.id) {
      await adminStore.updateBattalion(formData.id, dataToSave);
    } else {
      await adminStore.createBattalion(dataToSave);
    }
    dialogs.dialogStates.battalionDialog = false;
    dialogs.resetBattalionForm();

    indexSignalChats();
  } catch (error) {
    console.error('Error saving battalion:', error);
  }
}

async function handlePositionSave(isEdit: boolean, formData: Position) {
  try {
    const positionData = {
      name: formData.name,
      description: formData.description,
      squadronId: formData.squadronId,
      isGroup: formData.isGroup,
    };

    if (isEdit && formData.id) {
      await adminStore.updatePosition(formData.id, positionData);
    } else {
      await adminStore.createPosition(positionData);
    }

    dialogs.dialogStates.positionDialog = false;
    dialogs.resetPositionForm();
  } catch (error) {
    console.error('Error saving position:', error);
  }
}

async function handleChatConfigSave(config: any) {
  if (!dialogs.selectedChat.value) return;

  const { battalion, type, name } = dialogs.selectedChat.value;
  try {
    await adminStore.updateChatConfig(battalion.id, type, name, config);
    dialogs.dialogStates.chatConfigDialog = false;
    dialogs.selectedChat.value = null;
  } catch (error) {
    console.error('Error saving chat config:', error);
  }
}

// Delete confirmation handlers
function confirmDelete(
  type: 'user' | 'squadron' | 'battalion' | 'position',
  item: any,
) {
  confirmDialog.value = {
    show: true,
    item,
    type,
  };
}

async function handleConfirmDelete() {
  const { type, item } = confirmDialog.value;
  try {
    switch (type) {
      case 'user':
        await adminStore.deleteUser(item.id);
        break;
      case 'squadron':
        if (adminStore.currentUser?.squadron?.id == item.id) {
          alert('Неможливо видалити пункт управління, до якого ви належите');
        } else await adminStore.deleteSquadron(item.id);
        break;
      case 'battalion':
        await adminStore.deleteBattalion(item.id);
        break;
      case 'position':
        await adminStore.deletePosition(item.id);
        break;
    }
  } catch (error) {
    console.error(`Error deleting ${type}:`, error);
  } finally {
    confirmDialog.value.show = false;
  }
}

async function indexSignalChats() {
  try {
    const resposne = await apiClient.indexSignalChats();

    if (resposne.data.status !== 200) {
      throw new Error('Failed to index signal chats');
    }
  } catch (error) {
    console.error('Error indexing signal chats:', error);
    adminStore.showAlert('Помилка індексації чатів', 'error');
  }
}

// Lifecycle hooks
onMounted(async () => {
  console.log('AdminView mounted');
  // Make sure all dialogs are closed initially
  dialogs.resetAllDialogs();
  // Fetch users, positions,squadrons,battalions data on mount
  adminStore.fetchAllData();
});

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, {
      class: "admin-container",
      fluid: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                (_unref(adminStore).alert.show)
                  ? (_openBlock(), _createBlock(NotificationAlert, {
                      key: 0,
                      show: _unref(adminStore).alert.show,
                      type: _unref(adminStore).alert.type,
                      message: _unref(adminStore).alert.message,
                      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_unref(adminStore).alert.show = $event))
                    }, null, 8, ["show", "type", "message"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_container, { class: "user-info-create-container" }, {
                  default: _withCtx(() => [
                    (_unref(adminStore).currentUser)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _cache[28] || (_cache[28] = _createElementVNode("h2", { class: "mb-4" }, "Інформація про користувача", -1)),
                          _createVNode(_component_v_form, { class: "user-info-form" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "6",
                                    class: "py-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Ім'я користувача",
                                        modelValue: _unref(adminStore).currentUser.username,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(adminStore).currentUser.username) = $event)),
                                        readonly: ""
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "6",
                                    class: "py-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Позивний",
                                        modelValue: _unref(adminStore).currentUser.callsign,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(adminStore).currentUser.callsign) = $event)),
                                        readonly: ""
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "6",
                                    class: "py-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Пункт управління",
                                        "model-value": userSquadron.value,
                                        readonly: ""
                                      }, null, 8, ["model-value"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "6",
                                    class: "py-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Підрозділ",
                                        "model-value": userBattalion.value,
                                        readonly: ""
                                      }, null, 8, ["model-value"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_container, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "button-group" }, {
                                default: _withCtx(() => [
                                  (
                    _unref(adminStore).currentUser.role === 'admin' ||
                    _unref(adminStore).currentUser.role === 'battalion_admin'
                  )
                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                        key: 0,
                                        cols: "12",
                                        sm: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(dialogs).openUserDialog())),
                                            color: "primary",
                                            block: "",
                                            class: "custom-btn"
                                          }, {
                                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                                              _createTextVNode(" Новий користувач ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (
                    ['admin', 'battalion_admin', 'squadron_admin'].includes(
                      _unref(adminStore).currentUser.role,
                    )
                  )
                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                        key: 1,
                                        cols: "12",
                                        sm: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(dialogs).openPositionDialog())),
                                            color: "primary",
                                            block: "",
                                            class: "custom-btn"
                                          }, {
                                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                                              _createTextVNode(" Нова позиція, група, позивний ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (
                    _unref(adminStore).currentUser.role === 'admin' ||
                    _unref(adminStore).currentUser.role === 'battalion_admin'
                  )
                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                        key: 2,
                                        cols: "12",
                                        sm: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(dialogs).openSquadronDialog())),
                                            color: "primary",
                                            block: "",
                                            class: "custom-btn"
                                          }, {
                                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                                              _createTextVNode(" Новий Пункт управління ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  (_unref(adminStore).currentUser.role === 'admin')
                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                        key: 3,
                                        cols: "12",
                                        sm: "6"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_btn, {
                                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(dialogs).openBattalionDialog())),
                                            color: "primary",
                                            block: "",
                                            class: "custom-btn"
                                          }, {
                                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                                              _createTextVNode(" Новий Підрозділ ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_divider, { class: "my-4" }),
                          _createVNode(_component_v_container, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "button-group" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(adminStore).activeView = _unref(ActiveView).SQUADRONS)),
                                        block: "",
                                        class: _normalizeClass(["custom-btn", {
                      'active-tab':
                        _unref(adminStore).activeView === _unref(ActiveView).SQUADRONS,
                    }])
                                      }, {
                                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                                          _createTextVNode(" Пункти управління ")
                                        ])),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(adminStore).activeView = _unref(ActiveView).BATTALIONS)),
                                        block: "",
                                        class: _normalizeClass(["custom-btn", {
                      'active-tab':
                        _unref(adminStore).activeView === _unref(ActiveView).BATTALIONS,
                    }])
                                      }, {
                                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                                          _createTextVNode(" Підрозділи ")
                                        ])),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(adminStore).activeView = _unref(ActiveView).POSITIONS)),
                                        block: "",
                                        class: _normalizeClass(["custom-btn", {
                      'active-tab':
                        _unref(adminStore).activeView === _unref(ActiveView).POSITIONS,
                    }])
                                      }, {
                                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                                          _createTextVNode(" Позиції, групи, позивні ")
                                        ])),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "3"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(adminStore).activeView = _unref(ActiveView).USERS)),
                                        block: "",
                                        class: _normalizeClass(["custom-btn", {
                      'active-tab': _unref(adminStore).activeView === _unref(ActiveView).USERS,
                    }])
                                      }, {
                                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                                          _createTextVNode(" Користувачі ")
                                        ])),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (showFilters.value)
                  ? (_openBlock(), _createBlock(_component_v_row, {
                      key: 1,
                      class: "my-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              label: "Фільтрувати по Підрозділу",
                              items: _unref(adminStore).battalionsFilterItems,
                              modelValue: _unref(adminStore).selectedBattalion,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(adminStore).selectedBattalion) = $event)),
                              "item-title": "name",
                              "item-value": "id",
                              clearable: "",
                              "hide-details": ""
                            }, null, 8, ["items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        (_unref(adminStore).activeView === _unref(ActiveView).POSITIONS)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  label: "Фільтрувати по Пункту управління",
                                  items: _unref(adminStore).positionsSquadronsFilterItems,
                                  modelValue: _unref(adminStore).selectedSquadron,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(adminStore).selectedSquadron) = $event)),
                                  "item-title": "display",
                                  "item-value": "id",
                                  clearable: "",
                                  "hide-details": ""
                                }, null, 8, ["items", "modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  (_unref(adminStore).activeView === _unref(ActiveView).SQUADRONS)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _cache[31] || (_cache[31] = _createElementVNode("h2", { class: "my-4" }, "Список Пунктів управління", -1)),
                        _createVNode(_component_v_data_table, {
                          headers: squadronHeaders,
                          items: _unref(adminStore).filteredSquadrons,
                          class: "mb-4",
                          "item-value": "id",
                          sticky: ""
                        }, {
                          [`item.name`]: _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(item.name) + " ", 1),
                            _createElementVNode("span", _hoisted_4, " (" + _toDisplayString(item.battalionName ?? '') + ") ", 1)
                          ]),
                          [`item.actions`]: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_5, [
                              (canEditSquadrons.value)
                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                    key: 0,
                                    size: "large",
                                    color: "black",
                                    class: "mr-2",
                                    onClick: ($event: any) => (_unref(dialogs).openSquadronDialog(item))
                                  }, {
                                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                                      _createTextVNode(" mdi-pencil-outline ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (canEditSquadrons.value)
                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                    key: 1,
                                    size: "large",
                                    color: "red",
                                    onClick: ($event: any) => (confirmDelete('squadron', item))
                                  }, {
                                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                                      _createTextVNode(" mdi-delete ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["items"])
                      ]))
                    : (_unref(adminStore).activeView === _unref(ActiveView).BATTALIONS)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _cache[34] || (_cache[34] = _createElementVNode("h2", { class: "my-4" }, "Список Підрозділів", -1)),
                          _createVNode(_component_v_data_table, {
                            headers: battalionHeaders,
                            items: _unref(adminStore).battalions,
                            class: "mb-4",
                            sticky: ""
                          }, {
                            [`item.signalChatsData`]: _withCtx(({ item }) => [
                              (item.signalChatsData?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.signalChatsData, (chat) => {
                                      return (_openBlock(), _createBlock(_component_v_chip, {
                                        key: `signal-${chat.name}`,
                                        class: "chat-name-chip",
                                        color: `${chat.systemChat ? 'primary' : ''}`,
                                        onClick: ($event: any) => (
                      _unref(dialogs).openChatConfigDialog('signal', item, chat.name)
                    )
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(chat.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["color", "onClick"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            [`item.whatsappChatsData`]: _withCtx(({ item }) => [
                              (item.whatsappChatsData?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.whatsappChatsData, (chat) => {
                                      return (_openBlock(), _createBlock(_component_v_chip, {
                                        key: `whatsapp-${chat.name}`,
                                        class: "chat-name-chip",
                                        color: `${chat.systemChat ? 'primary' : ''}`,
                                        onClick: ($event: any) => (
                      _unref(dialogs).openChatConfigDialog('whatsapp', item, chat.name)
                    )
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(chat.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["color", "onClick"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            [`item.elementChatsData`]: _withCtx(({ item }) => [
                              (item.elementChatsData?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.elementChatsData, (chat) => {
                                      return (_openBlock(), _createBlock(_component_v_chip, {
                                        key: `element-${chat.name}`,
                                        class: "chat-name-chip",
                                        color: `${chat.systemChat ? 'primary' : ''}`,
                                        onClick: ($event: any) => (
                      _unref(dialogs).openChatConfigDialog('element', item, chat.name)
                    )
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(chat.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["color", "onClick"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            [`item.actions`]: _withCtx(({ item }) => [
                              _createElementVNode("div", _hoisted_10, [
                                (canEditBattalions.value)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 0,
                                      size: "large",
                                      color: "black",
                                      onClick: ($event: any) => (_unref(dialogs).openBattalionDialog(item))
                                    }, {
                                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                                        _createTextVNode(" mdi-pencil-outline ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (canEditBattalions.value)
                                  ? (_openBlock(), _createBlock(_component_v_icon, {
                                      key: 1,
                                      size: "large",
                                      color: "red",
                                      onClick: ($event: any) => (confirmDelete('battalion', item))
                                    }, {
                                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                                        _createTextVNode(" mdi-delete ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["items"])
                        ]))
                      : (_unref(adminStore).activeView === _unref(ActiveView).POSITIONS)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _cache[39] || (_cache[39] = _createElementVNode("h2", { class: "my-4" }, "Список позицій, груп, позивних", -1)),
                            _cache[40] || (_cache[40] = _createElementVNode("h3", null, "Позиції", -1)),
                            _createVNode(_component_v_data_table, {
                              headers: positionHeaders,
                              items: regularPositions.value,
                              class: "mb-4",
                              "item-value": "id",
                              sticky: ""
                            }, {
                              [`item.name`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(item.name) + " ", 1),
                                (item.description)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, " (" + _toDisplayString(item.description) + ") ", 1))
                                  : _createCommentVNode("", true)
                              ]),
                              [`item.squadron`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(_unref(adminStore).getSquadronWithBattalion(item.squadron)), 1)
                              ]),
                              [`item.actions`]: _withCtx(({ item }) => [
                                _createElementVNode("div", _hoisted_13, [
                                  (canEditPositions.value)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 0,
                                        size: "large",
                                        color: "black",
                                        class: "mr-2",
                                        onClick: ($event: any) => (_unref(dialogs).openPositionDialog(item))
                                      }, {
                                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                                          _createTextVNode(" mdi-pencil-outline ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true),
                                  (canEditPositions.value)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 1,
                                        size: "large",
                                        color: "red",
                                        onClick: ($event: any) => (confirmDelete('position', item))
                                      }, {
                                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                                          _createTextVNode(" mdi-delete ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["items"]),
                            _cache[41] || (_cache[41] = _createElementVNode("h3", null, "Групи та позивні", -1)),
                            _createVNode(_component_v_data_table, {
                              headers: positionHeaders,
                              items: groupPositions.value,
                              class: "mb-4",
                              "item-value": "id",
                              sticky: ""
                            }, {
                              [`item.name`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(item.name) + " ", 1),
                                (item.description)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, " (" + _toDisplayString(item.description) + ") ", 1))
                                  : _createCommentVNode("", true)
                              ]),
                              [`item.squadron`]: _withCtx(({ item }) => [
                                _createTextVNode(_toDisplayString(_unref(adminStore).getSquadronWithBattalion(item.squadron)), 1)
                              ]),
                              [`item.actions`]: _withCtx(({ item }) => [
                                _createElementVNode("div", _hoisted_15, [
                                  (canEditPositions.value)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 0,
                                        size: "large",
                                        color: "black",
                                        class: "mr-2",
                                        onClick: ($event: any) => (_unref(dialogs).openPositionDialog(item))
                                      }, {
                                        default: _withCtx(() => _cache[37] || (_cache[37] = [
                                          _createTextVNode(" mdi-pencil-outline ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true),
                                  (canEditPositions.value)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 1,
                                        size: "large",
                                        color: "red",
                                        onClick: ($event: any) => (confirmDelete('position', item))
                                      }, {
                                        default: _withCtx(() => _cache[38] || (_cache[38] = [
                                          _createTextVNode(" mdi-delete ")
                                        ])),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["items"])
                          ]))
                        : (_unref(adminStore).activeView === _unref(ActiveView).USERS)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _cache[43] || (_cache[43] = _createElementVNode("h2", { class: "my-4" }, "Список користувачів", -1)),
                              _createVNode(_component_v_data_table, {
                                headers: userHeaders,
                                items: _unref(adminStore).filteredUsers,
                                class: "mb-4",
                                "item-value": "id",
                                sticky: ""
                              }, {
                                item: _withCtx(({ item }) => [
                                  _createElementVNode("tr", {
                                    onClick: ($event: any) => (_unref(dialogs).openUserDialog(item)),
                                    style: {"cursor":"pointer"}
                                  }, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(userHeaders, (header) => {
                                      return _createElementVNode("td", {
                                        key: header.key
                                      }, [
                                        (header.key === 'squadron')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createTextVNode(_toDisplayString(_unref(adminStore).getSquadronWithBattalion(item.squadron)), 1)
                                            ], 64))
                                          : (header.key === 'role')
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                _createTextVNode(_toDisplayString(_unref(adminStore).roleTranslations[item.role] || 'Unknown'), 1)
                                              ], 64))
                                            : (header.key === 'actions')
                                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                                  key: 2,
                                                  size: "large",
                                                  color: "red",
                                                  onClick: _withModifiers(($event: any) => (confirmDelete('user', item)), ["stop"])
                                                }, {
                                                  default: _withCtx(() => _cache[42] || (_cache[42] = [
                                                    _createTextVNode(" mdi-delete ")
                                                  ])),
                                                  _: 2
                                                }, 1032, ["onClick"]))
                                              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                                  _createTextVNode(_toDisplayString(item[header.key]), 1)
                                                ], 64))
                                      ])
                                    }), 64))
                                  ], 8, _hoisted_17)
                                ]),
                                _: 1
                              }, 8, ["items"])
                            ]))
                          : (_openBlock(), _createBlock(_component_v_alert, {
                              key: 4,
                              type: "info",
                              prominent: ""
                            }, {
                              default: _withCtx(() => _cache[44] || (_cache[44] = [
                                _createTextVNode(" Завантаження інформації про користувача... ")
                              ])),
                              _: 1
                            }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(dialogs).dialogStates.userDialog)
      ? (_openBlock(), _createBlock(CreateEditUserDialog, {
          key: 0,
          "dialog-visible": _unref(dialogs).dialogStates.userDialog,
          "is-editing": _unref(dialogs).isEditMode.user,
          form: _unref(dialogs).userForm,
          "available-squadrons": _unref(adminStore).allSquadronItems,
          "available-roles": _unref(adminStore).getAvailableRoles,
          "onUpdate:dialogVisible": _cache[13] || (_cache[13] = (val) => (_unref(dialogs).dialogStates.userDialog = val)),
          onSave: handleUserSave
        }, null, 8, ["dialog-visible", "is-editing", "form", "available-squadrons", "available-roles"]))
      : _createCommentVNode("", true),
    (_unref(dialogs).dialogStates.squadronDialog)
      ? (_openBlock(), _createBlock(CreateEditSquadronDialog, {
          key: 1,
          "dialog-visible": _unref(dialogs).dialogStates.squadronDialog,
          "is-editing": _unref(dialogs).isEditMode.squadron,
          form: _unref(dialogs).squadronForm,
          "available-battalions": _unref(adminStore).activeBattalionItems,
          "onUpdate:dialogVisible": _cache[14] || (_cache[14] = 
      (val) => (_unref(dialogs).dialogStates.squadronDialog = val)
    ),
          onSave: handleSquadronSave
        }, null, 8, ["dialog-visible", "is-editing", "form", "available-battalions"]))
      : _createCommentVNode("", true),
    (_unref(dialogs).dialogStates.battalionDialog)
      ? (_openBlock(), _createBlock(CreateEditBattalionDialog, {
          key: 2,
          "dialog-visible": _unref(dialogs).dialogStates.battalionDialog,
          "is-editing": _unref(dialogs).isEditMode.battalion,
          form: _unref(dialogs).battalionForm,
          "new-signal-chat": _unref(dialogs).newSignalChat,
          "new-whatsapp-chat": _unref(dialogs).newWhatsappChat,
          "onUpdate:dialogVisible": _cache[15] || (_cache[15] = 
      (val) => (_unref(dialogs).dialogStates.battalionDialog = val)
    ),
          onSave: handleBattalionSave
        }, null, 8, ["dialog-visible", "is-editing", "form", "new-signal-chat", "new-whatsapp-chat"]))
      : _createCommentVNode("", true),
    (_unref(dialogs).dialogStates.positionDialog)
      ? (_openBlock(), _createBlock(CreateEditPositionDialog, {
          key: 3,
          "dialog-visible": _unref(dialogs).dialogStates.positionDialog,
          "is-editing": _unref(dialogs).isEditMode.position,
          form: _unref(dialogs).positionForm,
          "available-squadrons": _unref(adminStore).activeNonServiceSquadronItems,
          "onUpdate:dialogVisible": _cache[16] || (_cache[16] = 
      (val) => (_unref(dialogs).dialogStates.positionDialog = val)
    ),
          onSave: handlePositionSave
        }, null, 8, ["dialog-visible", "is-editing", "form", "available-squadrons"]))
      : _createCommentVNode("", true),
    (_unref(dialogs).dialogStates.chatConfigDialog)
      ? (_openBlock(), _createBlock(ChatConfigDialog, {
          key: 4,
          "dialog-visible": _unref(dialogs).dialogStates.chatConfigDialog,
          "chat-name": _unref(dialogs).selectedChat?.value?.name,
          "chat-type": _unref(dialogs).selectedChat?.value?.type,
          config: _unref(dialogs).selectedChat?.value?.config,
          "onUpdate:dialogVisible": _cache[17] || (_cache[17] = 
      (val) => (_unref(dialogs).dialogStates.chatConfigDialog = val)
    ),
          onSave: handleChatConfigSave
        }, null, 8, ["dialog-visible", "chat-name", "chat-type", "config"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: confirmDialog.value.show,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((confirmDialog.value.show) = $event)),
      "max-width": "500px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => _cache[45] || (_cache[45] = [
                _createTextVNode("Підтвердити видалення")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => _cache[46] || (_cache[46] = [
                _createTextVNode(" Ви впевнені, що хочете видалити цей елемент? Ця дія не може бути скасована. ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  class: "custom-default-btn",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (confirmDialog.value.show = false))
                }, {
                  default: _withCtx(() => _cache[47] || (_cache[47] = [
                    _createTextVNode(" Скасувати ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "custom-red-btn",
                  onClick: handleConfirmDelete
                }, {
                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                    _createTextVNode(" Видалити ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})