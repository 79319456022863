<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto pb-3" width="100%">
        <event-input-number label="Фактичні 200" v-model="killed" />
      </v-responsive>
      <v-responsive class="mx-auto pb-3" width="100%">
        <event-input-number label="Ймовірні 200" v-model="possibleKilled" />
      </v-responsive>
      <v-responsive class="mx-auto pb-3" width="100%">
        <event-input-number label="Фактичні 300" v-model="wounded" />
      </v-responsive>
      <v-responsive class="mx-auto pb-6" width="100%">
        <event-input-number label="Ймовірні 300" v-model="possibleWounded" />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button
        class="custom-green-btn px-10"
        :disabled="!eventSessionStore.valid"
        @click="proceed"
        enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventButton from '@/components/EventCreation/EventButton.vue';

const eventSessionStore = useEventsSessionStore();

const emit = defineEmits(['input']);

const killed = ref(0);
const possibleKilled = ref(0);

const wounded = ref(0);
const possibleWounded = ref(0);

type LinkedObjectType = {
  killed?: number;
  possibleKilled?: number;
  wounded?: number;
  possibleWounded?: number;
};

function proceed() {
  const values: string[] = [];
  const linkedObject: LinkedObjectType = {};
  let killedString = '';
  let woundedString = '';

  if (killed.value) {
    killedString += `200: ${killed.value}`;
    linkedObject.killed = killed.value;
  }

  if (possibleKilled.value) {
    if (killed.value) {
      killedString += `, ~${possibleKilled.value}`;
    } else {
      killedString += `200: ~${possibleKilled.value}`;
    }
    linkedObject.possibleKilled = possibleKilled.value;
  }

  if (wounded.value) {
    woundedString += `300: ${wounded.value}`;
    linkedObject.wounded = wounded.value;
  }

  if (possibleWounded.value) {
    if (wounded.value) {
      woundedString += `, ~${possibleWounded.value}`;
    } else {
      woundedString += `300: ~${possibleWounded.value}`;
    }
    linkedObject.possibleWounded = possibleWounded.value;
  }

  [killedString, woundedString].forEach((str) => {
    if (str) {
      values.push(str);
    }
  });

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}
</script>
