import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pa-0 mt-3 d-flex align-start justify-end ga-3" }
const _hoisted_2 = {
  class: "d-flex align-center justify-end ga-1",
  style: {"width":"210px"}
}

import { watch } from 'vue';
import { rules } from '@/utils/rules';

const MIN_VALUE = 0;
const MAX_VALUE = 99999;


export default /*@__PURE__*/_defineComponent({
  __name: 'EventInputModelCount',
  props: /*@__PURE__*/_mergeModels({
    label: {}
  }, {
    "ammoAmount": {
  required: true,
  type: Number,
},
    "ammoAmountModifiers": {},
    "ammoModel": {
  required: true,
  type: String,
},
    "ammoModelModifiers": {},
  }),
  emits: ["update:ammoAmount", "update:ammoModel"],
  setup(__props: any) {

const ammoAmount = _useModel(__props, 'ammoAmount');

const ammoModel = _useModel(__props, 'ammoModel');



function handleKeydown(event: KeyboardEvent) {
  if (['e', 'E', '+', '-', '.'].includes(event.key)) {
    event.preventDefault();
  }
}

function increment() {
  if (ammoAmount.value < MAX_VALUE) ammoAmount.value++;
}

function decrement() {
  if (ammoAmount.value > MIN_VALUE) ammoAmount.value--;
}

watch(ammoAmount, (value, prev) => {
  let stringValue = value.toString();
  let regex = /^\d{0,5}(?:\.\d{1,5})?$/;
  if (!stringValue.match(regex) && value) {
    ammoAmount.value = prev;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      label: _ctx.label,
      outlined: "",
      "hide-details": "auto",
      width: "100%",
      density: "comfortable",
      heigth: "48px",
      modelValue: ammoModel.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ammoModel).value = $event)),
      modelModifiers: { trim: true },
      rules: [_unref(rules).maxLength(100)]
    }, null, 8, ["label", "modelValue", "rules"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_btn, {
        class: "custom-default-btn",
        size: "x-small",
        icon: "mdi-minus",
        onClick: decrement,
        disabled: !ammoAmount.value
      }, null, 8, ["disabled"]),
      _createVNode(_component_v_text_field, {
        modelValue: ammoAmount.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ammoAmount).value = $event)),
        modelModifiers: { number: true, trim: true },
        outlined: "",
        type: "number",
        "hide-details": "",
        onKeydown: handleKeydown,
        class: "numberInput",
        density: "comfortable",
        rules: [_unref(rules).isGreaterThanZero]
      }, null, 8, ["modelValue", "rules"]),
      _createVNode(_component_v_btn, {
        class: "custom-default-btn",
        size: "x-small",
        icon: "mdi-plus",
        onClick: increment,
        disabled: ammoAmount.value >= MAX_VALUE
      }, null, 8, ["disabled"])
    ])
  ]))
}
}

})