<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto mb-2" width="100%">
        <v-combobox
          v-model="eventSessionStore.eventForm200"
          chips
          closable-chips
          label="Позивні"
          multiple
          append-inner-icon="mdi-plus"
          @click:append-inner="addCallSign($el.querySelector('input'))"
        >
        </v-combobox>
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import EventButton from '@/components/EventCreation/EventButton.vue';
import { useEventsSessionStore } from '@/stores/eventSession';

const eventSessionStore = useEventsSessionStore();

const emit = defineEmits(['input']);

const disabled = computed(() => eventSessionStore.eventForm200.length === 0);

function addCallSign(target: HTMLInputElement) {
  target.blur();
  target.focus();
}

function proceed() {
  const values: string[] = [];

  if (eventSessionStore.eventForm200.length > 1) {
    values.push(`Позивні: ${eventSessionStore.eventForm200.join(', ')}`);
  } else {
    values.push(`Позивний: ${eventSessionStore.eventForm200.at(0)}`);
  }

  emit('input', values);
}
</script>
