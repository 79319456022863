<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="handleDialogUpdate"
    :content-class="
      eventStore.currentType === 'notes' ? 'dialog-events-content-sizes' : ''
    "
    :max-width="widthDialogComputed"
    class="dialog-wrapper"
    persistent
  >
    <div
      v-if="eventStore.currentType === 'notes'"
      class="bg-white rounded-lg elevation-1 pa-3 desctop-preview"
    >
      <event-preview-content
        :position="eventStore.positionData"
        :eventMessage="eventStore.eventMessage"
        @handleSubmit="handleSubmit"
      />
    </div>

    <event-last-created
      v-if="
        eventStore.path.length === 1 &&
        eventStore.options.saveKey &&
        eventStore.lastEventPaths.length
      "
      v-model:show="eventStore.isShowEventPreviewLastCreatedEventDialog"
      :lastEventPaths="eventStore.lastEventPaths"
    />
    <v-form class="pa-0 ma-0" v-model="eventSessionStore.valid" @submit.prevent>
      <v-card>
        <v-card-text class="pb-0 pt-3">
          <!-- Алерт -->
          <v-alert
            v-if="alert.show"
            :type="alert.type"
            dismissible
            @update:modelValue="alert.show = false"
          >
            {{ alert.message }}
          </v-alert>

          <event-breadcrumbs @close-dialog="closeDialog" />

          <div class="pa-0">
            <template
              v-if="smAndDown || columnsDisable || eventStore.path.length > 0"
            >
              <event-item
                v-for="item in eventStore.currentOptions"
                :key="`${item.name}-${item?.id ?? ''}-${item?.type ?? ''}`"
                :item="item"
                :dialog="dialog"
                @closeDialog="closeDialog"
              />
            </template>
            <div :style="gridColumnsStyleComputed" v-else>
              <template v-for="col in uniqueColumnsComputed" :key="col">
                <div>
                  <template
                    v-for="item in eventStore.currentOptions.filter(
                      (item) => item.column === col,
                    )"
                    :key="`${item.name}-${item?.id ?? ''}-${item?.type ?? ''}`"
                  >
                    <event-item
                      :item="item"
                      :dialog="dialog"
                      @closeDialog="closeDialog"
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
        </v-card-text>
        <event-card-actions @handleSubmit="handleSubmit" /> </v-card
    ></v-form>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  ref,
  watch,
  nextTick,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  computed,
} from 'vue';
import { useDisplay } from 'vuetify';
import { useEventStore } from '@/stores/event';
import type { EventType, Position } from '@/types/events';
import { useEventsSessionStore } from '@/stores/eventSession';
import { useEventCreationStore } from '@/stores/eventCreation';
import EventItem from '@/components/EventCreation/EventItem.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventLastCreated from '@/components/EventCreation/EventLastCreated.vue';
import EventCardActions from '@/components/EventCreation/EventCardActions.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';
import { MGRS_ZONE_AND_SQUARE } from '@/config/constants';

const props = defineProps<{
  dialog: boolean;
  position: Position;
  currentOption?: EventType;
  currentOptions: EventType[];
  columnsDisable?: boolean;
}>();

const emit = defineEmits<{
  'update:dialog': [value: boolean];
  'event-success': [message: string];
  'event-error': [message: string];
  close: [];
  select: [selection: EventType | string[] | string];
}>();

const { smAndDown } = useDisplay();
const eventStore = useEventStore();

const eventCreationStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();
const alert = ref({ show: false, message: '', type: 'info' });

const widthDialogComputed = computed(() =>
  eventStore.path.length === 0 && !props?.columnsDisable ? 800 : 600,
);

const uniqueColumnsComputed = computed(() => [
  ...new Set(eventStore.currentOptions.map((item) => item.column)),
]);

const gridColumnsStyleComputed = computed(() => {
  return {
    display: 'grid',
    gap: '8px',
    'grid-template-columns': Array(uniqueColumnsComputed.value.length)
      .fill('1fr')
      .join(' '),
  };
});

// Показ алерту
function showAlert(message: string, type: string = 'info') {
  alert.value = { show: true, message, type };
}

// Завершення
async function handleSubmit() {
  const linkedObject = eventStore.getLinkedObjectsInPaths(eventStore.path);

  eventCreationStore.$patch({
    chatMessage: eventStore.eventMessage,
  });

  if (eventStore.options.saveKey) {
    eventSessionStore.setPath(eventStore.options.saveKey, eventStore.path);
  }

  const result = await eventCreationStore.createEvent(
    eventStore.positionData,
    linkedObject,
  );

  if (result.success) {
    // Якщо координати не заповнені (введені), то видаляємо дані з localStorage
    if (!eventSessionStore.isCoordinatesFilled) {
      localStorage.removeItem(MGRS_ZONE_AND_SQUARE);
    }
    // Очищаємо форму 300 та 200 від попередніх даних після успішного створення події
    eventSessionStore.resetEventForm();
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

// Оновлення діалогу
function handleDialogUpdate(value: boolean) {
  if (!value) closeDialog();
}

// Закриття діалогу
function closeDialog() {
  nextTick(() => {
    eventCreationStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// --- Спостереження ---
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) closeDialog();
  },
);

watch(
  () => props.position,
  (newValue) => {
    eventStore.positionData = { ...newValue };
  },
);

watch(
  () => eventStore.path,
  (value) => {
    if (value.length === 0) {
      eventStore.currentOptions = props.currentOptions || [];
      eventStore.positionData = { ...props.position };
    }
  },
);

watch(
  () => eventStore.currentOptions,
  (value) => {
    const type = value[0]?.type || '';
    eventStore.currentType = type;
    // Обнуляємо значення поля вводу при зміні опцій
    eventStore.inputTypeValue = '';
    eventStore.selectedItem = null;
  },
);

onMounted(() => {
  eventStore.positionData = { ...props.position };
  if (props.currentOptions) {
    eventStore.currentOptions = props.currentOptions;
  }
  // якщо props.currentOption існує, то починаємо подію з цього елементу
  if (props.currentOption) {
    eventStore.handleSelection(props.currentOption);
  }
});

onBeforeUnmount(() => {
  eventStore.resetToInitialState();
});

onUnmounted(() => {
  eventStore.resetToInitialState();
});
</script>

<style scoped>
.dialog-wrapper {
  max-width: 800px;
  @media (max-width: 960px) {
    max-width: 600px;
  }
}
.headline {
  font-weight: bold;
}

.custom-green-btn {
  background-color: #4caf50;
  color: white;
}

.custom-red-btn {
  background-color: #f44336;
  color: white;
}

.v-breadcrumbs-item:hover {
  text-decoration: underline;
}

.list-item-button {
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 12px 16px;
}

.list-item-button:hover {
  background-color: #d0d0d0;
}

.desctop-preview {
  min-width: 320px;
  max-width: 320px;
  position: absolute;
  left: -332px;
  top: 0;
  @media (max-width: 970px) {
    display: none;
  }
}
</style>
