<template>
  <v-card-actions
    v-if="eventStore.actionsView && eventStore.path.length"
    class="px-sm-6 pt-3 pb-2 d-flex justify-end mt-0"
  >
    <event-preview-dialog
      v-if="eventStore.currentType === 'notes'"
      :position="eventStore.positionData"
      :eventMessage="eventStore.eventMessage"
    >
      <template v-slot:title>
        <event-preview-content
          :position="eventStore.positionData"
          :eventMessage="eventStore.eventMessage"
          @handleSubmit="$emit('handleSubmit')"
        />
      </template>
      <template v-slot:default>
        <event-button
          v-if="eventStore.currentType === 'notes'"
          :disabled="eventCreationStore.loading"
          @click="$emit('handleSubmit')"
          class="custom-green-btn"
          >Створити подію</event-button
        >
      </template>
    </event-preview-dialog>

    <event-button
      v-if="eventStore.path.length > 0"
      class="custom-default-btn"
      back
    >
      Назад</event-button
    >
    <event-button
      v-if="eventStore.inputType"
      class="custom-green-btn px-10"
      @click="eventStore.handleNextButtonForInputType"
      :disabled="!eventSessionStore.valid || !eventStore.inputTypeValue"
      enterTrigger
    >
      Далі</event-button
    >

    <event-button
      v-if="eventStore.currentType === 'notes'"
      :disabled="eventCreationStore.loading"
      @click="$emit('handleSubmit')"
      class="custom-green-btn"
      >Створити подію</event-button
    >
  </v-card-actions>
  <div v-else class="mt-3"></div>
</template>

<script setup lang="ts">
import { useEventStore } from '@/stores/event';
import { useEventsSessionStore } from '@/stores/eventSession';
import { useEventCreationStore } from '@/stores/eventCreation';
import EventButton from '@/components/EventCreation/EventButton.vue';
import EventPreviewDialog from '@/components/EventCreation/EventPreviewDialog.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';

const eventStore = useEventStore();

const eventCreationStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();

defineEmits<{
  handleSubmit: () => void;
}>();
</script>
