<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Витрати"
          v-model.trim="expenditure"
          type="text"
          :rules="[rules.maxLength(100)]"
        />
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Залишок"
          v-model.trim="balance"
          type="text"
          :rules="[rules.maxLength(100)]"
        />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { rules } from '@/utils/rules';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';

const emit = defineEmits(['input']);

const eventSessionStore = useEventsSessionStore();

const expenditure = ref('');
const balance = ref('');
const disabled = computed(
  () => !eventSessionStore.valid || !expenditure.value || !balance.value,
);

function proceed() {
  const values = [];
  if (expenditure.value) {
    values.push(`Витрати: ${expenditure.value}`);
  }

  if (balance.value) {
    values.push(`Залишок: ${balance.value}`);
  }

  emit('input', values);
}
</script>
