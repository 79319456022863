<template>
  <div class="pa-0 mt-3 d-flex align-start justify-end ga-3">
    <v-text-field
      :label="label"
      outlined
      hide-details="auto"
      width="100%"
      density="comfortable"
      heigth="48px"
      v-model.trim="ammoModel"
      :rules="[rules.maxLength(100)]"
    ></v-text-field>
    <div class="d-flex align-center justify-end ga-1" style="width: 210px">
      <v-btn
        class="custom-default-btn"
        size="x-small"
        icon="mdi-minus"
        @click="decrement"
        :disabled="!ammoAmount"
      />
      <v-text-field
        v-model.number.trim="ammoAmount"
        outlined
        type="number"
        hide-details
        @keydown="handleKeydown"
        class="numberInput"
        density="comfortable"
        :rules="[rules.isGreaterThanZero]"
      />
      <v-btn
        class="custom-default-btn"
        size="x-small"
        icon="mdi-plus"
        @click="increment"
        :disabled="ammoAmount >= MAX_VALUE"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { rules } from '@/utils/rules';

const ammoAmount = defineModel('ammoAmount', {
  required: true,
  type: Number,
});

const ammoModel = defineModel('ammoModel', {
  required: true,
  type: String,
});

const MIN_VALUE = 0;
const MAX_VALUE = 99999;

defineProps<{
  label: string;
}>();

function handleKeydown(event: KeyboardEvent) {
  if (['e', 'E', '+', '-', '.'].includes(event.key)) {
    event.preventDefault();
  }
}

function increment() {
  if (ammoAmount.value < MAX_VALUE) ammoAmount.value++;
}

function decrement() {
  if (ammoAmount.value > MIN_VALUE) ammoAmount.value--;
}

watch(ammoAmount, (value, prev) => {
  let stringValue = value.toString();
  let regex = /^\d{0,5}(?:\.\d{1,5})?$/;
  if (!stringValue.match(regex) && value) {
    ammoAmount.value = prev;
  }
});
</script>

<style scoped>
.numberInput {
  max-width: 80px;
}
.numberInput >>> input[type='number'],
.numberInput >>> input::-webkit-outer-spin-button,
.numberInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
}
</style>
