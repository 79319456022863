import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventButton from '@/components/EventCreation/EventButton.vue';

type LinkedObjectType = {
  killed?: number;
  possibleKilled?: number;
  wounded?: number;
  possibleWounded?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EventKilledAndWoundedSummary',
  emits: ['input'],
  setup(__props, { emit: __emit }) {

const eventSessionStore = useEventsSessionStore();

const emit = __emit;

const killed = ref(0);
const possibleKilled = ref(0);

const wounded = ref(0);
const possibleWounded = ref(0);

function proceed() {
  const values: string[] = [];
  const linkedObject: LinkedObjectType = {};
  let killedString = '';
  let woundedString = '';

  if (killed.value) {
    killedString += `200: ${killed.value}`;
    linkedObject.killed = killed.value;
  }

  if (possibleKilled.value) {
    if (killed.value) {
      killedString += `, ~${possibleKilled.value}`;
    } else {
      killedString += `200: ~${possibleKilled.value}`;
    }
    linkedObject.possibleKilled = possibleKilled.value;
  }

  if (wounded.value) {
    woundedString += `300: ${wounded.value}`;
    linkedObject.wounded = wounded.value;
  }

  if (possibleWounded.value) {
    if (wounded.value) {
      woundedString += `, ~${possibleWounded.value}`;
    } else {
      woundedString += `300: ~${possibleWounded.value}`;
    }
    linkedObject.possibleWounded = possibleWounded.value;
  }

  [killedString, woundedString].forEach((str) => {
    if (str) {
      values.push(str);
    }
  });

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

return (_ctx: any,_cache: any) => {
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto pb-3",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(EventInputNumber, {
                label: "Фактичні 200",
                modelValue: killed.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((killed).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto pb-3",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(EventInputNumber, {
                label: "Ймовірні 200",
                modelValue: possibleKilled.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((possibleKilled).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto pb-3",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(EventInputNumber, {
                label: "Фактичні 300",
                modelValue: wounded.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((wounded).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto pb-6",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(EventInputNumber, {
                label: "Ймовірні 300",
                modelValue: possibleWounded.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((possibleWounded).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            disabled: !_unref(eventSessionStore).valid,
            onClick: proceed,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})