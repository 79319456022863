import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "chat-section" }
const _hoisted_2 = {
  key: 1,
  class: "chat-chips"
}
const _hoisted_3 = { class: "chat-section" }
const _hoisted_4 = {
  key: 1,
  class: "chat-chips"
}
const _hoisted_5 = { class: "chat-section" }
const _hoisted_6 = {
  key: 2,
  class: "chat-chips"
}

import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';
import { useAdminStore } from '@/stores/admin';
import { UserRole } from '@/types/admin';
interface Props {
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['battalion'];
}

type Chat = { name: string; systemChat: boolean; config: any; roomId?: string };


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateEditBattalionDialog',
  props: {
    dialogVisible: { type: Boolean },
    isEditing: { type: Boolean },
    form: {}
  },
  emits: ["update:dialogVisible", "save", "close"],
  setup(__props: any, { emit: __emit }) {

const adminStore = useAdminStore();

const props = __props;
const emit = __emit;

const formRef = ref<any>(null);
const formValid = ref(false);
const showSignalInput = ref(false);
const showWhatsappInput = ref(false);
const showElementInput = ref(false);

const localForm = reactive({
  id: null as string | null,
  name: '',
  signalChatsData: [] as Chat[],
  whatsappChatsData: [] as Chat[],
  elementChatsData: [] as Chat[],
});

watch(
  () => props.form,
  (newForm) => {
    localForm.id = newForm.id;
    localForm.name = newForm.name;
    localForm.signalChatsData = [...newForm.signalChatsData];
    localForm.whatsappChatsData = [...newForm.whatsappChatsData];
    localForm.elementChatsData = [...newForm.elementChatsData];
  },
  { immediate: true, deep: true },
);

const makeSignalChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.signalChatsData.map((c) =>
    c.name === chat.name ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.signalChatsData = updatedChats;
};

const makeWhatsAppChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.whatsappChatsData.map((c) =>
    c.name === chat.name ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.whatsappChatsData = updatedChats;
};

const makeElementChatSystem = (newValue: boolean | null, chat: Chat) => {
  const updatedChats = localForm.elementChatsData.map((c) =>
    c.roomId === chat.roomId ? { ...c, systemChat: !!newValue } : c,
  );
  localForm.elementChatsData = updatedChats;
};

const alert = ref({
  show: false,
  type: 'error' as const,
  message: '',
});

const newSignalChat = ref({
  name: '',
  systemChat: false,
});
const newWhatsappChat = ref({
  name: '',
  systemChat: false,
});
const newElementChat = ref({
  name: '',
  roomId: '',
  systemChat: false,
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.value = {
    show: true,
    type,
    message,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

function handleAddSignalChat() {
  const trimmedChat = newSignalChat.value.name.trim();
  if (
    trimmedChat &&
    !localForm.signalChatsData.some((chat) => chat.name === trimmedChat)
  ) {
    localForm.signalChatsData.push({
      name: removeExtraSybolsFromSignalName(trimmedChat),
      systemChat: newSignalChat.value.systemChat,
      config: null,
    });
    newSignalChat.value = { name: '', systemChat: false };
    console.log('Added to signalChatsData:', localForm.signalChatsData); // Debug log for added chat
  }
}

function handleAddWhatsappChat() {
  const trimmedChat = newWhatsappChat.value.name.trim();
  if (
    trimmedChat &&
    !localForm.whatsappChatsData.some((chat) => chat.name === trimmedChat)
  ) {
    localForm.whatsappChatsData.push({
      name: trimmedChat,
      systemChat: newSignalChat.value.systemChat,
      config: null,
    });
    newWhatsappChat.value = { name: '', systemChat: false };
    console.log('Added to whatsappChatsData:', localForm.whatsappChatsData); // Debug log for added chat
  }
}

function handleAddElementChat() {
  const trimmedChatName = newElementChat.value.name.trim();
  const trimmedChatRoomId = newElementChat.value.roomId.trim();
  if (
    trimmedChatName &&
    !localForm.elementChatsData.some((chat) => chat.name === trimmedChatName)
  ) {
    localForm.elementChatsData.push({
      name: trimmedChatName,
      systemChat: newElementChat.value.systemChat,
      roomId: trimmedChatRoomId,
      config: null,
    });
    newElementChat.value = { name: '', roomId: '', systemChat: false };
    console.log('Added to element:', localForm.elementChatsData); // Debug log for added chat
  }
}

function handleRemoveChat(
  type: 'signalChatsData' | 'whatsappChatsData' | 'elementChatsData',
  chat: { name: string; config: any },
) {
  localForm[type] = localForm[type].filter((c) => c.name !== chat.name);
  console.log(`Removed from ${type}:`, localForm[type]); // Debug log for removed chat
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  // Convert reactive proxy to plain object
  const plainLocalForm = JSON.parse(JSON.stringify(localForm));

  emit('save', props.isEditing, plainLocalForm);
}

function handleClose() {
  emit('update:dialogVisible', false);
  emit('close');
  formRef.value?.resetValidation();
}

function removeExtraSybolsFromSignalName(name: string) {
  return name.replaceAll('⁩', '');
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('update:dialogVisible', $event))),
    "max-width": "500px",
    "onClick:outside": handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Редагувати Підрозділ' : 'Створити Підрозділ'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.value.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.value.type,
                    dismissible: "",
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (alert.value.show = false)),
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.value.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_form, {
                ref_key: "formRef",
                ref: formRef,
                modelValue: formValid.value,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formValid).value = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localForm.name) = $event)),
                    label: "Назва",
                    "hide-details": "",
                    rules: [(v) => !!v || 'Назва обовязкова'],
                    required: ""
                  }, null, 8, ["modelValue", "rules"]),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h4", null, [
                      _cache[12] || (_cache[12] = _createTextVNode(" Signal чати ")),
                      _createVNode(_component_v_icon, {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (showSignalInput.value = !showSignalInput.value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(showSignalInput.value
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (showSignalInput.value)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 0,
                          modelValue: newSignalChat.value.name,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newSignalChat.value.name) = $event)),
                          label: "Назва чату",
                          "hide-details": "",
                          onKeyup: _withKeys(handleAddSignalChat, ["enter"]),
                          onBlur: handleAddSignalChat,
                          "onClick:appendInner": handleAddSignalChat,
                          "append-inner-icon": "mdi-plus"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (localForm.signalChatsData.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localForm.signalChatsData, (chat) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: `signal-${chat.name}`,
                              color: `${chat.systemChat ? 'primary' : ''}`,
                              closable: "",
                              "onClick:close": () => handleRemoveChat('signalChatsData', chat)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chat.name) + " ", 1),
                                (_unref(adminStore).currentUser?.role === _unref(UserRole).admin)
                                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                      key: 0,
                                      label: 'системний',
                                      class: "small-checkbox compact-checkbox",
                                      dense: "",
                                      "hide-details": "",
                                      "model-value": chat.systemChat,
                                      "onUpdate:modelValue": 
                      (newVal) => makeSignalChatSystem(newVal, chat)
                    
                                    }, null, 8, ["model-value", "onUpdate:modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["color", "onClick:close"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h4", null, [
                      _cache[13] || (_cache[13] = _createTextVNode(" WhatsApp чати ")),
                      _createVNode(_component_v_icon, {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (showWhatsappInput.value = !showWhatsappInput.value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(showWhatsappInput.value
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (showWhatsappInput.value)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 0,
                          modelValue: newWhatsappChat.value.name,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((newWhatsappChat.value.name) = $event)),
                          label: "Назва чату",
                          "hide-details": "",
                          onKeyup: _withKeys(handleAddWhatsappChat, ["enter"]),
                          onBlur: handleAddWhatsappChat,
                          "onClick:appendInner": handleAddWhatsappChat,
                          "append-inner-icon": "mdi-plus"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (localForm.whatsappChatsData.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localForm.whatsappChatsData, (chat) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: `whatsapp-${chat.name}`,
                              color: `${chat.systemChat ? 'primary' : ''}`,
                              closable: "",
                              "onClick:close": ($event: any) => (handleRemoveChat('whatsappChatsData', chat))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chat.name) + " ", 1),
                                (_unref(adminStore).currentUser?.role === _unref(UserRole).admin)
                                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                      key: 0,
                                      label: 'системний',
                                      class: "small-checkbox compact-checkbox",
                                      dense: "",
                                      "hide-details": "",
                                      "model-value": chat.systemChat,
                                      "onUpdate:modelValue": 
                      (newVal) => makeWhatsAppChatSystem(newVal, chat)
                    
                                    }, null, 8, ["model-value", "onUpdate:modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["color", "onClick:close"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h4", null, [
                      _cache[14] || (_cache[14] = _createTextVNode(" Element чати ")),
                      _createVNode(_component_v_icon, {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (showElementInput.value = !showElementInput.value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(showElementInput.value
                    ? 'mdi-minus-circle-outline'
                    : 'mdi-plus-circle-outline'), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (showElementInput.value)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 0,
                          modelValue: newElementChat.value.name,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((newElementChat.value.name) = $event)),
                          label: "Назва чату",
                          "hide-details": ""
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (showElementInput.value)
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 1,
                          modelValue: newElementChat.value.roomId,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((newElementChat.value.roomId) = $event)),
                          label: "Room ID",
                          "hide-details": "",
                          onKeyup: _withKeys(handleAddElementChat, ["enter"]),
                          onBlur: _cache[9] || (_cache[9] = 
                () => newElementChat.value.roomId.trim() && handleAddElementChat()
              ),
                          "onClick:appendInner": handleAddElementChat,
                          "append-inner-icon": "mdi-plus"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (localForm.elementChatsData.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localForm.elementChatsData, (chat) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: `element-${chat.roomId}`,
                              color: `${chat.systemChat ? 'primary' : ''}`,
                              closable: "",
                              "onClick:close": () => handleRemoveChat('elementChatsData', chat)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chat.name) + " ", 1),
                                (_unref(adminStore).currentUser?.role === _unref(UserRole).admin)
                                  ? (_openBlock(), _createBlock(_component_v_checkbox, {
                                      key: 0,
                                      label: 'системний',
                                      class: "small-checkbox compact-checkbox",
                                      dense: "",
                                      "hide-details": "",
                                      "model-value": chat.systemChat,
                                      "onUpdate:modelValue": 
                      (newVal) => makeElementChatSystem(newVal, chat)
                    
                                    }, null, 8, ["model-value", "onUpdate:modelValue"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["color", "onClick:close"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "elevated",
                color: "black",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode(" Відмінити ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: handleSave,
                disabled: !formValid.value
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Зберегти ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})