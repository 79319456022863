<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <div class="d-flex justify-start align-center gap-1 mb-2">
        <v-chip-group
          v-model="tab"
          mandatory
          selected-class="text-primary"
          center-active
          class="pa-0"
        >
          <template
            v-for="(model, index) in eventSessionStore.eventForm300"
            :key="model.id"
          >
            <v-chip
              class="my-0"
              :class="{
                'text-warning': !model.callSign && tab !== model.id,
              }"
              :value="model.id"
              @click="tab = model.id"
              :closable="eventSessionStore.eventForm300.length > 1"
              @click:close="deleteModel(model.id)"
            >
              {{ model.callSign || `300 ${index + 1}` }}
            </v-chip>
          </template>
        </v-chip-group>
        <v-btn icon size="small" variant="text" @click="addModel">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>

      <template v-for="model in eventSessionStore.eventForm300" :key="model.id">
        <template v-if="tab === model.id">
          <v-responsive class="mx-auto mb-2" width="100%">
            <v-text-field
              label="Позивний"
              v-model.trim="model.callSign"
              type="text"
              :rules="[rules.maxLength(100)]"
              hide-details="auto"
            >
            </v-text-field>
            <p class="call-sign-error-message" v-if="checkCallSign(model)">
              Позивний не заповнений!
            </p>
          </v-responsive>
          <v-responsive class="mx-auto mb-2" width="100%">
            <v-text-field
              label="Характер поранення"
              v-model.trim="model.injuryType"
              type="text"
              :rules="[rules.maxLength(100)]"
              hide-details="auto"
            />
          </v-responsive>
          <v-responsive class="mx-auto mb-2" width="100%">
            <v-select
              label="Тяжкість"
              v-model.trim="model.injurySeverity"
              clearable
              :items="Object.values(InjurySeverity)"
              hide-details="auto"
            />
          </v-responsive>
          <v-responsive class="mx-auto mb-2" width="100%">
            <v-select
              label="Стабільність"
              v-model.trim="model.stability"
              clearable
              :items="Object.values(Stability)"
              hide-details="auto"
            />
          </v-responsive>
          <v-responsive class="mx-auto mb-2" width="100%">
            <v-select
              label="Притомність"
              v-model.trim="model.consciousness"
              clearable
              :items="Object.values(Consciousness)"
              hide-details="auto"
            />
          </v-responsive>
          <v-responsive class="mx-auto" width="100%">
            <v-textarea
              label="Надана допомога"
              v-model.trim="model.assistanceProvided"
              type="text"
              :rules="[rules.maxLength(200)]"
              hide-details="auto"
            />
          </v-responsive>
        </template>
      </template>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <event-button class="custom-default-btn" back>Назад</event-button>
      <event-button
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        enterTrigger
        >Далі</event-button
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { rules } from '@/utils/rules';
import {
  useEventsSessionStore,
  EventForm300,
  InjurySeverity,
  Consciousness,
  Stability,
  DEFAULT_EVENT_FORM_300,
} from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';

const emit = defineEmits(['input']);

const eventSessionStore = useEventsSessionStore();

const tab = ref(0);

const disabled = computed(
  () => !eventSessionStore.eventForm300.some((model) => model.callSign),
);

function addModel() {
  eventSessionStore.eventForm300 = [
    ...eventSessionStore.eventForm300,
    DEFAULT_EVENT_FORM_300(),
  ];
  // Перемикаємо на новий чіп після його створення
  tab.value = eventSessionStore.eventForm300.at(-1)!.id;
}

function checkCallSign(model: EventForm300) {
  return (
    !model.callSign &&
    (model.injuryType ||
      model.injurySeverity ||
      model.stability ||
      model.consciousness ||
      model.assistanceProvided)
  );
}

function deleteModel(id: number) {
  // Видалення чіпа за унікальним id
  eventSessionStore.eventForm300 = eventSessionStore.eventForm300.filter(
    (model) => model.id !== id,
  );

  if (eventSessionStore.eventForm300.length > 0 && tab.value === id) {
    tab.value = eventSessionStore.eventForm300.at(-1)!.id;
  }
}
function proceed() {
  const values: string[] = [];

  // Отримуємо позивні з eventForm300
  const callSigns = eventSessionStore.eventForm300.map(
    (i) => i.callSign || 'Не вказаний',
  );

  // Якщо більше одного позивного, додаємо їх до масиву значень
  if (callSigns.length > 1) {
    values.push(`Позивні: ${callSigns.join(', ')}`);
    values.push('\n');
  }

  // Фільтруємо елементи, які мають дані про допомогу, свідомість, тяжкість поранення, стабільність або тип поранення
  const filteredEventForm300 = eventSessionStore.eventForm300.filter(
    (i) =>
      i.assistanceProvided ||
      i.consciousness ||
      i.injurySeverity ||
      i.stability ||
      i.injuryType,
  );

  if (filteredEventForm300.length) {
    filteredEventForm300.forEach((model) => {
      values.push(
        `Позивний: ${model.callSign ? model.callSign : 'Не вказаний'}`,
      );

      if (model.injuryType) {
        values.push(`Характер поранення: ${model.injuryType}`);
      }
      if (model.injurySeverity) {
        values.push(`Тяжкість: ${model.injurySeverity}`);
      }
      if (model.stability) {
        values.push(`Стабільність: ${model.stability}`);
      }
      if (model.consciousness) {
        values.push(`Притомність: ${model.consciousness}`);
      }
      if (model.assistanceProvided) {
        values.push(`Надана допомога: ${model.assistanceProvided}`);
      }
      // Додаємо роздільник між елементами
      values.push('\n');
    });
  }

  // Якщо є лише один позивний
  else if (callSigns.length === 1) {
    values.push(`Позивний: ${callSigns[0]}`);
  }

  emit('input', values);
}

onMounted(() => {
  tab.value = eventSessionStore.eventForm300[0].id;
});
</script>

<style>
.call-sign-error-message {
  font-size: 12px !important;
  color: rgb(var(--v-theme-error)) !important;
  padding: 6px 16px;
}
</style>
