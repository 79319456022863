<!-- components/EventCreation/EventNotesForm.vue -->
<template>
  <div class="center-content">
    <v-alert
      v-if="isTimeMoreThanOneHourInFutureComputed"
      type="warning"
      :closable="false"
    >
      Час обраний в майбутньому!
    </v-alert>
    <v-textarea
      v-if="showAllies"
      v-model.trim="notesData.allies"
      label="Кількість о/с на позиції"
      outlined
      rows="2"
      class="full-width"
      hide-details
    />

    <v-textarea
      v-if="showEnemies"
      v-model.trim="notesData.enemies"
      label="Кільскість o/c противника"
      outlined
      rows="2"
      class="full-width"
      hide-details
    />

    <v-textarea
      v-model.trim="notesData.details"
      label="Деталі"
      outlined
      rows="2"
      class="full-width"
      hide-details
    />

    <div class="status-section">
      <label>Статус</label>
      <v-radio-group :model-value="notesData.status" hide-details>
        <v-radio
          v-for="status in statuses"
          :key="status"
          :label="status"
          :value="status"
          @click="handleStatusChange(notesData.status === status ? '' : status)"
        />
      </v-radio-group>
    </div>

    <v-tabs
      :model-value="eventStore.dayIndicator"
      align-tabs="center"
      color="var(--default-color)"
      density="compact"
      mandatory="force"
    >
      <v-tab
        :value="DayIndicator.PREVIOUS"
        @click="() => handleDayIndicatorChange(DayIndicator.PREVIOUS)"
        >Вчора</v-tab
      >
      <v-tab
        :value="DayIndicator.CURRENT"
        @click="() => handleDayIndicatorChange(DayIndicator.CURRENT)"
        >Сьогодні</v-tab
      >
    </v-tabs>

    <v-container class="pa-0" fluid>
      <div class="time-picker-container">
        <input
          type="time"
          id="time_picker"
          v-model="time"
          name="time"
          required
        />
      </div>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch, onMounted, onUnmounted, computed } from 'vue';
import { EventNotes, DayIndicator } from '@/types/events';
import {
  getCurrentTime,
  isTimeMoreThanOneHourInFuture,
} from '@/utils/timeUtils';
import { useEventCreationStore } from '@/stores/eventCreation';

interface Props {
  showAllies: boolean;
  showEnemies: boolean;
}

const eventStore = useEventCreationStore();

withDefaults(defineProps<Props>(), {
  showAllies: false,
  showEnemies: false,
});

const statuses = [
  'о/с цілий',
  '4.5.0',
  '4.5.0, о/с цілий',
  '4.5.0, о/с та ОВТ цілі',
] as const;

const time = ref(getCurrentTime());
const notesData = reactive<EventNotes>({
  allies: '',
  enemies: '',
  details: '',
  status: '',
});

const isTimeMoreThanOneHourInFutureComputed = computed(() => {
  return eventStore.dayIndicator === DayIndicator.PREVIOUS
    ? false
    : isTimeMoreThanOneHourInFuture(time.value);
});

function handleDayIndicatorChange(dayIndicator: DayIndicator) {
  eventStore.$patch({
    dayIndicator,
  });
}

function handleStatusChange(status: string | null) {
  notesData.status = status ?? '';
}

function formatNotes(notes: EventNotes): string {
  const lines = [
    notes.allies ? `Кількість о/с на позиції: ${notes.allies.trim()}` : '',
    notes.enemies ? `Кількість о/с противника: ${notes.enemies.trim()}` : '',
    notes.details ? `Додатково: ${notes.details.trim()}` : '',
    notes.status ? `Статус: ${notes.status.trim()}` : '',
  ]
    .filter(Boolean)
    .join('\n');

  return lines;
}

function resetState() {
  notesData.allies = '';
  notesData.enemies = '';
  notesData.details = '';
  notesData.status = '';
  eventStore.$patch({
    notesMessage: '',
    dayIndicator: DayIndicator.CURRENT,
  });
}

onMounted(() => {
  resetState();
});

onUnmounted(() => {
  resetState();
});

watch(notesData, (notes) => {
  eventStore.$patch({
    notesMessage: `\n${formatNotes(notes)}`,
  });
});

watch(time, (time) => {
  eventStore.$patch({
    time,
  });
});
</script>

<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.full-width {
  width: 100%;
}

.status-section {
  width: 100%;
  text-align: left;
}

.time-picker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
}

#time_picker {
  font-size: 40px;
}

.v-radio-group {
  margin-top: 8px;
}

.v-radio {
  margin-bottom: 4px;
  max-width: max-content;
}

label {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}
</style>
