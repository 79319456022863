import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-start align-center gap-1 mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "call-sign-error-message"
}

import { ref, computed, onMounted } from 'vue';
import { rules } from '@/utils/rules';
import {
  useEventsSessionStore,
  EventForm300,
  InjurySeverity,
  Consciousness,
  Stability,
  DEFAULT_EVENT_FORM_300,
} from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventForm300',
  emits: ['input'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const eventSessionStore = useEventsSessionStore();

const tab = ref(0);

const disabled = computed(
  () => !eventSessionStore.eventForm300.some((model) => model.callSign),
);

function addModel() {
  eventSessionStore.eventForm300 = [
    ...eventSessionStore.eventForm300,
    DEFAULT_EVENT_FORM_300(),
  ];
  // Перемикаємо на новий чіп після його створення
  tab.value = eventSessionStore.eventForm300.at(-1)!.id;
}

function checkCallSign(model: EventForm300) {
  return (
    !model.callSign &&
    (model.injuryType ||
      model.injurySeverity ||
      model.stability ||
      model.consciousness ||
      model.assistanceProvided)
  );
}

function deleteModel(id: number) {
  // Видалення чіпа за унікальним id
  eventSessionStore.eventForm300 = eventSessionStore.eventForm300.filter(
    (model) => model.id !== id,
  );

  if (eventSessionStore.eventForm300.length > 0 && tab.value === id) {
    tab.value = eventSessionStore.eventForm300.at(-1)!.id;
  }
}
function proceed() {
  const values: string[] = [];

  // Отримуємо позивні з eventForm300
  const callSigns = eventSessionStore.eventForm300.map(
    (i) => i.callSign || 'Не вказаний',
  );

  // Якщо більше одного позивного, додаємо їх до масиву значень
  if (callSigns.length > 1) {
    values.push(`Позивні: ${callSigns.join(', ')}`);
    values.push('\n');
  }

  // Фільтруємо елементи, які мають дані про допомогу, свідомість, тяжкість поранення, стабільність або тип поранення
  const filteredEventForm300 = eventSessionStore.eventForm300.filter(
    (i) =>
      i.assistanceProvided ||
      i.consciousness ||
      i.injurySeverity ||
      i.stability ||
      i.injuryType,
  );

  if (filteredEventForm300.length) {
    filteredEventForm300.forEach((model) => {
      values.push(
        `Позивний: ${model.callSign ? model.callSign : 'Не вказаний'}`,
      );

      if (model.injuryType) {
        values.push(`Характер поранення: ${model.injuryType}`);
      }
      if (model.injurySeverity) {
        values.push(`Тяжкість: ${model.injurySeverity}`);
      }
      if (model.stability) {
        values.push(`Стабільність: ${model.stability}`);
      }
      if (model.consciousness) {
        values.push(`Притомність: ${model.consciousness}`);
      }
      if (model.assistanceProvided) {
        values.push(`Надана допомога: ${model.assistanceProvided}`);
      }
      // Додаємо роздільник між елементами
      values.push('\n');
    });
  }

  // Якщо є лише один позивний
  else if (callSigns.length === 1) {
    values.push(`Позивний: ${callSigns[0]}`);
  }

  emit('input', values);
}

onMounted(() => {
  tab.value = eventSessionStore.eventForm300[0].id;
});

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_chip_group, {
              modelValue: tab.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tab).value = $event)),
              mandatory: "",
              "selected-class": "text-primary",
              "center-active": "",
              class: "pa-0"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventSessionStore).eventForm300, (model, index) => {
                  return (_openBlock(), _createBlock(_component_v_chip, {
                    key: model.id,
                    class: _normalizeClass(["my-0", {
                'text-warning': !model.callSign && tab.value !== model.id,
              }]),
                    value: model.id,
                    onClick: ($event: any) => (tab.value = model.id),
                    closable: _unref(eventSessionStore).eventForm300.length > 1,
                    "onClick:close": ($event: any) => (deleteModel(model.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(model.callSign || `300 ${index + 1}`), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "value", "onClick", "closable", "onClick:close"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_btn, {
              icon: "",
              size: "small",
              variant: "text",
              onClick: addModel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("mdi-plus")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventSessionStore).eventForm300, (model) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: model.id
            }, [
              (tab.value === model.id)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto mb-2",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Позивний",
                          modelValue: model.callSign,
                          "onUpdate:modelValue": ($event: any) => ((model.callSign) = $event),
                          modelModifiers: { trim: true },
                          type: "text",
                          rules: [_unref(rules).maxLength(100)],
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                        (checkCallSign(model))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Позивний не заповнений! "))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto mb-2",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Характер поранення",
                          modelValue: model.injuryType,
                          "onUpdate:modelValue": ($event: any) => ((model.injuryType) = $event),
                          modelModifiers: { trim: true },
                          type: "text",
                          rules: [_unref(rules).maxLength(100)],
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto mb-2",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          label: "Тяжкість",
                          modelValue: model.injurySeverity,
                          "onUpdate:modelValue": ($event: any) => ((model.injurySeverity) = $event),
                          modelModifiers: { trim: true },
                          clearable: "",
                          items: Object.values(_unref(InjurySeverity)),
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto mb-2",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          label: "Стабільність",
                          modelValue: model.stability,
                          "onUpdate:modelValue": ($event: any) => ((model.stability) = $event),
                          modelModifiers: { trim: true },
                          clearable: "",
                          items: Object.values(_unref(Stability)),
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto mb-2",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          label: "Притомність",
                          modelValue: model.consciousness,
                          "onUpdate:modelValue": ($event: any) => ((model.consciousness) = $event),
                          modelModifiers: { trim: true },
                          clearable: "",
                          items: Object.values(_unref(Consciousness)),
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_responsive, {
                      class: "mx-auto",
                      width: "100%"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_textarea, {
                          label: "Надана допомога",
                          modelValue: model.assistanceProvided,
                          "onUpdate:modelValue": ($event: any) => ((model.assistanceProvided) = $event),
                          modelModifiers: { trim: true },
                          type: "text",
                          rules: [_unref(rules).maxLength(200)],
                          "hide-details": "auto"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                      ]),
                      _: 2
                    }, 1024)
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(EventButton, {
            class: "custom-default-btn",
            back: ""
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Назад")
            ])),
            _: 1
          }),
          _createVNode(EventButton, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            enterTrigger: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})