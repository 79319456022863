<template>
  <v-dialog :model-value="show" opacity="0.9" max-width="600px">
    <v-card>
      <v-card-text class="pt-3 w-auto">
        <v-list>
          <h3 class="mb-3">Використати попередні дані?</h3>
          <template v-for="(item, index) in lastEventPaths" :key="index">
            <template
              v-if="item.selectedDisplayType?.length && item?.canShowEventPath"
            >
              <div
                class="d-flex align-center mb-3"
                v-for="i in item.selectedDisplayType"
                :key="i"
                :value="i"
                @click="goTo(index, item.startCreatedAtEventPath)"
              >
                <v-chip
                  size="large"
                  :text="i"
                  variant="outlined"
                  class="py-1"
                  style="text-wrap: wrap; height: fit-content"
                ></v-chip>
                <v-icon
                  v-if="item.startCreatedAtEventPath"
                  class="cursor-pointer ml-3"
                  >mdi-pencil-outline</v-icon
                >
              </div>
            </template>
            <div
              v-else-if="!!item.displayType?.trim() && item?.canShowEventPath"
              :value="item"
              @click="goTo(index, item.startCreatedAtEventPath)"
            >
              <v-chip
                v-if="item.displayType"
                size="large"
                variant="outlined"
                class="py-1"
                style="text-wrap: wrap; height: fit-content"
                >{{ item.displayType }}</v-chip
              >
            </div>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-0 ga-3 px-sm-6">
        <event-button class="custom-default-btn" back>Назад</event-button>
        <event-button class="custom-gray-btn" @click="reset"
          >Скинути</event-button
        >
        <event-button
          v-if="canShowProceedButton"
          class="custom-green-btn px-10"
          @click="proceed"
          enterTrigger
          >Так</event-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { EventPath } from '@/types/events';
import { useEventStore } from '@/stores/event';
import { useEventsSessionStore } from '@/stores/eventSession';
import EventButton from '@/components/EventCreation/EventButton.vue';

const eventStore = useEventStore();
const eventSessionStore = useEventsSessionStore();

const props = defineProps<{
  lastEventPaths: EventPath[];
}>();

const show = defineModel('show', {
  type: Boolean,
  required: true,
});

const canShowProceedButton = computed(() => {
  return props.lastEventPaths.some(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
});

function goTo(index: number, startCreatedAtEventPath?: boolean) {
  if (startCreatedAtEventPath) {
    const lastEventPaths = [...props.lastEventPaths].splice(0, index);
    eventStore.reproduceLastCreatedEvent(lastEventPaths);
    show.value = false;
  }
}

function proceed() {
  const findIndex = props.lastEventPaths.findIndex(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
  if (findIndex !== -1) {
    goTo(findIndex, true);
  }
}

function reset() {
  eventSessionStore.reset();
  show.value = false;
}
</script>
