import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView.vue';
import PositionsView from '@/views/PositionsView.vue';
import StatsView from '@/views/StatsView.vue';
import AdminView from '@/views/AdminView.vue';
import UavPilotView from '@/views/UavPilotView.vue';
import EventCreationDialog from '@/views/EventCreationDialog.vue';

const routes = [
  {
    path: '/dashboard',
    component: DashboardView,
    children: [
      {
        path: 'event',
        name: 'Event',
        component: PositionsView,
        children: [
          {
            path: 'event-type/:positionId',
            name: 'EventCreationDialog',
            component: EventCreationDialog,
          },
        ],
      },
      {
        path: 'stats',
        name: 'Stats',
        component: StatsView,
      },
      {
        path: 'uav-stats',
        name: 'UAVStats',
        component: StatsView,
      },
      {
        path: 'uav-pilot',
        name: 'UAVEvent',
        component: UavPilotView,
      },
      {
        path: 'admin',
        name: 'Admin',
        component: AdminView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
