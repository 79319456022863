import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white rounded-lg elevation-1 pa-3 desctop-preview"
}
const _hoisted_2 = { class: "pa-0" }

import {
  ref,
  watch,
  nextTick,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  computed,
} from 'vue';
import { useDisplay } from 'vuetify';
import { useEventStore } from '@/stores/event';
import type { EventType, Position } from '@/types/events';
import { useEventsSessionStore } from '@/stores/eventSession';
import { useEventCreationStore } from '@/stores/eventCreation';
import EventItem from '@/components/EventCreation/EventItem.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventLastCreated from '@/components/EventCreation/EventLastCreated.vue';
import EventCardActions from '@/components/EventCreation/EventCardActions.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';
import { MGRS_ZONE_AND_SQUARE } from '@/config/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCreationDialog',
  props: {
    dialog: { type: Boolean },
    position: {},
    currentOption: {},
    currentOptions: {},
    columnsDisable: { type: Boolean }
  },
  emits: ["update:dialog", "event-success", "event-error", "close", "select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { smAndDown } = useDisplay();
const eventStore = useEventStore();

const eventCreationStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();
const alert = ref({ show: false, message: '', type: 'info' });

const widthDialogComputed = computed(() =>
  eventStore.path.length === 0 && !props?.columnsDisable ? 800 : 600,
);

const uniqueColumnsComputed = computed(() => [
  ...new Set(eventStore.currentOptions.map((item) => item.column)),
]);

const gridColumnsStyleComputed = computed(() => {
  return {
    display: 'grid',
    gap: '8px',
    'grid-template-columns': Array(uniqueColumnsComputed.value.length)
      .fill('1fr')
      .join(' '),
  };
});

// Показ алерту
function showAlert(message: string, type: string = 'info') {
  alert.value = { show: true, message, type };
}

// Завершення
async function handleSubmit() {
  const linkedObject = eventStore.getLinkedObjectsInPaths(eventStore.path);

  eventCreationStore.$patch({
    chatMessage: eventStore.eventMessage,
  });

  if (eventStore.options.saveKey) {
    eventSessionStore.setPath(eventStore.options.saveKey, eventStore.path);
  }

  const result = await eventCreationStore.createEvent(
    eventStore.positionData,
    linkedObject,
  );

  if (result.success) {
    // Якщо координати не заповнені (введені), то видаляємо дані з localStorage
    if (!eventSessionStore.isCoordinatesFilled) {
      localStorage.removeItem(MGRS_ZONE_AND_SQUARE);
    }
    // Очищаємо форму 300 та 200 від попередніх даних після успішного створення події
    eventSessionStore.resetEventForm();
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

// Оновлення діалогу
function handleDialogUpdate(value: boolean) {
  if (!value) closeDialog();
}

// Закриття діалогу
function closeDialog() {
  nextTick(() => {
    eventCreationStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// --- Спостереження ---
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) closeDialog();
  },
);

watch(
  () => props.position,
  (newValue) => {
    eventStore.positionData = { ...newValue };
  },
);

watch(
  () => eventStore.path,
  (value) => {
    if (value.length === 0) {
      eventStore.currentOptions = props.currentOptions || [];
      eventStore.positionData = { ...props.position };
    }
  },
);

watch(
  () => eventStore.currentOptions,
  (value) => {
    const type = value[0]?.type || '';
    eventStore.currentType = type;
    // Обнуляємо значення поля вводу при зміні опцій
    eventStore.inputTypeValue = '';
    eventStore.selectedItem = null;
  },
);

onMounted(() => {
  eventStore.positionData = { ...props.position };
  if (props.currentOptions) {
    eventStore.currentOptions = props.currentOptions;
  }
  // якщо props.currentOption існує, то починаємо подію з цього елементу
  if (props.currentOption) {
    eventStore.handleSelection(props.currentOption);
  }
});

onBeforeUnmount(() => {
  eventStore.resetToInitialState();
});

onUnmounted(() => {
  eventStore.resetToInitialState();
});

return (_ctx: any,_cache: any) => {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "onUpdate:modelValue": handleDialogUpdate,
    "content-class": 
      _unref(eventStore).currentType === 'notes' ? 'dialog-events-content-sizes' : ''
    ,
    "max-width": widthDialogComputed.value,
    class: "dialog-wrapper",
    persistent: ""
  }, {
    default: _withCtx(() => [
      (_unref(eventStore).currentType === 'notes')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EventPreviewContent, {
              position: _unref(eventStore).positionData,
              eventMessage: _unref(eventStore).eventMessage,
              onHandleSubmit: handleSubmit
            }, null, 8, ["position", "eventMessage"])
          ]))
        : _createCommentVNode("", true),
      (
        _unref(eventStore).path.length === 1 &&
        _unref(eventStore).options.saveKey &&
        _unref(eventStore).lastEventPaths.length
      )
        ? (_openBlock(), _createBlock(EventLastCreated, {
            key: 1,
            show: _unref(eventStore).isShowEventPreviewLastCreatedEventDialog,
            "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_unref(eventStore).isShowEventPreviewLastCreatedEventDialog) = $event)),
            lastEventPaths: _unref(eventStore).lastEventPaths
          }, null, 8, ["show", "lastEventPaths"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_form, {
        class: "pa-0 ma-0",
        modelValue: _unref(eventSessionStore).valid,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(eventSessionStore).valid) = $event)),
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, { class: "pb-0 pt-3" }, {
                default: _withCtx(() => [
                  (alert.value.show)
                    ? (_openBlock(), _createBlock(_component_v_alert, {
                        key: 0,
                        type: alert.value.type,
                        dismissible: "",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (alert.value.show = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(alert.value.message), 1)
                        ]),
                        _: 1
                      }, 8, ["type"]))
                    : _createCommentVNode("", true),
                  _createVNode(EventBreadcrumbs, { onCloseDialog: closeDialog }),
                  _createElementVNode("div", _hoisted_2, [
                    (_unref(smAndDown) || _ctx.columnsDisable || _unref(eventStore).path.length > 0)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(eventStore).currentOptions, (item) => {
                          return (_openBlock(), _createBlock(EventItem, {
                            key: `${item.name}-${item?.id ?? ''}-${item?.type ?? ''}`,
                            item: item,
                            dialog: _ctx.dialog,
                            onCloseDialog: closeDialog
                          }, null, 8, ["item", "dialog"]))
                        }), 128))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          style: _normalizeStyle(gridColumnsStyleComputed.value)
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uniqueColumnsComputed.value, (col) => {
                            return (_openBlock(), _createElementBlock("div", { key: col }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventStore).currentOptions.filter(
                      (item) => item.column === col,
                    ), (item) => {
                                return (_openBlock(), _createBlock(EventItem, {
                                  key: `${item.name}-${item?.id ?? ''}-${item?.type ?? ''}`,
                                  item: item,
                                  dialog: _ctx.dialog,
                                  onCloseDialog: closeDialog
                                }, null, 8, ["item", "dialog"]))
                              }), 128))
                            ]))
                          }), 128))
                        ], 4))
                  ])
                ]),
                _: 1
              }),
              _createVNode(EventCardActions, { onHandleSubmit: handleSubmit })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["model-value", "content-class", "max-width"]))
}
}

})