<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="handleDialogUpdate"
    max-width="800"
    persistent
  >
    <v-card>
      <v-card-title class="px-6 pb-0 pt-3">
        <slot name="title" />
      </v-card-title>
      <v-card-text class="pt-0 px-6 pb-3">
        <v-expansion-panels v-model="activePanel" accordion>
          <!-- Distance and Azimuth Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Азимут та дистанція події</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div
                class="d-flex align-stretch align-md-start justify-center justify-md-space-between ga-1 ga-md-3 flex-column-reverse flex-md-row"
              >
                <div class="input-section">
                  <div class="text-h6">Азимут</div>
                  <div class="helper-text">
                    Формат азимуту: кілька значень через кому, діапазон через
                    тире, наприклад 70-75, 250, 40-50
                  </div>
                  <v-text-field
                    label="Азимут"
                    v-model.trim="azimuth"
                    type="text"
                    class="azimuth-input"
                    :error="azimuthError"
                    :hint="azimuthError ? 'Азимут некоректний' : ''"
                    hide-details="auto"
                    @input="onAzimuthChange"
                    :disabled="isAzimuthUnknown"
                  />
                  <div data-pw="Не можливо визначити азимут">
                    <v-checkbox
                      v-model="isAzimuthUnknown"
                      label="Не можливо визначити"
                      hide-details
                      @change="handleAzimuthUnknownChange"
                    />
                  </div>
                </div>
                <azimuth-circle
                  :azimuthError="azimuthError"
                  v-model:isAzimuthUnknown="isAzimuthUnknown"
                  v-model:azimuth="azimuth"
                  @validateAzimuth="validateAzimuth"
                />
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="text-h6">Дистанція</div>
              <div class="helper-text">
                Формат дистанції: число, число з + або -, наприклад, 100, 100+,
                100-
              </div>
              <div class="distance-section">
                <v-slider
                  v-model="distance"
                  :disabled="isDistanceUnknown"
                  :max="3000"
                  :step="10"
                  color="rgb(6, 26, 40)"
                  class="mb-4"
                  show-ticks="always"
                  tick-size="4"
                  :ticks="ticks"
                  label=""
                  hide-details
                ></v-slider>
                <div class="input-section"></div>
                <v-text-field
                  label="Дистанція"
                  v-model.trim="distance"
                  type="text"
                  class="distance-input"
                  :error="distanceError"
                  :hint="distanceError ? 'Дистанція некоректна' : ''"
                  hide-details="auto"
                  @input="onDistanceChange"
                  :disabled="isDistanceUnknown"
                />
                <div data-pw="Не можливо визначити дистанцію">
                  <v-checkbox
                    v-model="isDistanceUnknown"
                    label="Не можливо визначити"
                    hide-details
                    @change="handleDistanceUnknownChange"
                  />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- Map Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Вибрати на мапі</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="map-container">
                <l-map
                  v-if="mapVisible"
                  :zoom="zoom"
                  :center="center"
                  ref="map"
                  @ready="onMapReady"
                  @click="onMapClick"
                  style="height: 500px; width: 100%"
                >
                  <l-tile-layer
                    :url="imageryUrl"
                    :attribution="imageryAttribution"
                  ></l-tile-layer>
                  <l-tile-layer
                    :url="labelsUrl"
                    :attribution="labelsAttribution"
                  ></l-tile-layer>
                  <l-marker v-if="marker" :lat-lng="marker"></l-marker>
                </l-map>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions class="pt-0 px-6 pb-3">
        <event-button class="custom-default-btn" back>Назад</event-button>
        <event-button
          class="custom-green-btn px-10"
          @click="proceed"
          :disabled="proceedDisabledButton"
          enterTrigger
          >Далі</event-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { forward } from 'mgrs';
import 'leaflet/dist/leaflet.css';
import { useDisplay } from 'vuetify';
import type { LatLng } from 'leaflet';
import { formatMGRS } from '@/services/utils';
import { useEventStore } from '@/stores/event';
import { ref, watch, nextTick, computed } from 'vue';
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import AzimuthCircle from '@/components/AzimuthCircle.vue';
import { useEventCreationStore } from '@/stores/eventCreation';
import EventButton from '@/components/EventCreation/EventButton.vue';

interface Props {
  dialog: boolean;
}

const { smAndDown } = useDisplay();

const props = defineProps<Props>();
const eventStore = useEventStore();

const eventCreationStore = useEventCreationStore();

const emit = defineEmits<{
  'update:dialog': [value: boolean];
}>();

// Refs
const activePanel = ref(0);
const zoom = ref(15);
const center = ref<[number, number]>([48.0159416, 37.7962839]);
const marker = ref<LatLng | null>(null);
const map = ref<any>(null);
const mapVisible = ref(false);

const distance = ref('');
const azimuth = ref('');
const azimuthError = ref(false);
const distanceError = ref(false);
const isDistanceUnknown = ref(false);
const isAzimuthUnknown = ref(false);
const proceedDisabledButton = ref(false);

const ticks = computed(() => {
  if (smAndDown.value) {
    return {
      0: '0',
      1500: '1000',
      3000: '>3000',
    };
  }

  return {
    0: '0',
    500: '500',
    1000: '1000',
    1500: '1500',
    2000: '2000',
    2500: '2500',
    3000: '>3000',
  };
});

// Map constants
const imageryUrl =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const imageryAttribution =
  'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
const labelsUrl =
  'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}';
const labelsAttribution = 'Tiles &copy; Esri';

// Map methods
const onMapReady = (mapInstance: any) => {
  map.value = mapInstance;
  loadLastLocation();
};

const loadLastLocation = () => {
  try {
    const lastLocationStr = localStorage.getItem('lastLocation');
    if (lastLocationStr) {
      const lastLocation = JSON.parse(lastLocationStr);
      center.value = [lastLocation.lat, lastLocation.lng];
      panToLocation(center.value);
    } else {
      getUserLocation();
    }
  } catch (error) {
    console.error('Error loading last location:', error);
    getUserLocation();
  }
};

const handleDistanceUnknownChange = () => {
  if (isDistanceUnknown.value) {
    distance.value = 'не можливо визначити';
    distanceError.value = false;
  } else {
    distance.value = '';
  }
};

const handleAzimuthUnknownChange = () => {
  if (isAzimuthUnknown.value) {
    azimuth.value = 'не можливо визначити';
    azimuthError.value = false;
  } else {
    azimuth.value = '';
  }
};

const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        center.value = [userLocation.lat, userLocation.lng];
        panToLocation(center.value);
      },
      () => {
        alert('Неможливо отримати ваші координати, оберіть точку вручну');
      },
    );
  }
};

const panToLocation = (location: [number, number]) => {
  if (map.value) {
    map.value.setView(location, zoom.value);
  }
};

const onMapClick = (event: { latlng: LatLng }) => {
  marker.value = event.latlng;
  center.value = [event.latlng.lat, event.latlng.lng];
  localStorage.setItem('lastLocation', JSON.stringify(marker.value));
};

// Validation methods
const validateAzimuth = () => {
  if (isAzimuthUnknown.value) {
    azimuthError.value = false;
    return;
  }
  if (azimuth.value === '') {
    azimuthError.value = false;
    return;
  }

  const azimuthValue = azimuth.value.toString();
  const singleAzimuthPattern = /^\s*\d{1,3}\s*$/;
  const rangeAzimuthPattern = /^\s*\d{1,3}\s*-\s*\d{1,3}\s*$/;

  const azimuthParts = azimuthValue.split(',').map((part) => part.trim());

  azimuthError.value = azimuthParts.some((part) => {
    if (rangeAzimuthPattern.test(part)) {
      const [minAzimuth, maxAzimuth] = part
        .split('-')
        .map((val) => parseFloat(val.trim()));
      return (
        isNaN(minAzimuth) ||
        isNaN(maxAzimuth) ||
        maxAzimuth <= 0 ||
        maxAzimuth > 360 ||
        minAzimuth >= maxAzimuth
      );
    } else if (singleAzimuthPattern.test(part)) {
      const parsedAzimuth = parseFloat(part);
      return isNaN(parsedAzimuth) || parsedAzimuth < 0 || parsedAzimuth > 360;
    }
    return true;
  });
  azimuth.value = azimuthValue.replace(/\s/g, '');
};

const validateDistance = () => {
  if (isDistanceUnknown.value) {
    distanceError.value = false;
    return;
  }

  if (distance.value === '') {
    distanceError.value = false;
    return;
  }

  const parsedDistance = parseFloat(distance.value);
  distanceError.value =
    isNaN(parsedDistance) ||
    parsedDistance < 0 ||
    !/^\d+([+-]?)$/.test(distance.value);
};

const onAzimuthChange = () => {
  validateAzimuth();
};

const onDistanceChange = () => {
  validateDistance();
};

// Dialog methods
const handleDialogUpdate = (value: boolean) => {
  if (!value) {
    resetState();
  }
  emit('update:dialog', value);
};

const resetState = () => {
  distance.value = '';
  azimuth.value = '';
  marker.value = null;
  mapVisible.value = false;
  activePanel.value = 0;
  distanceError.value = false;
  azimuthError.value = false;
  isDistanceUnknown.value = false;
  isAzimuthUnknown.value = false;
};

const proceed = () => {
  const locationMessage = [];
  let selectedAzimuth: number | string = -1;
  const coordinates = marker.value || { lat: 0, lng: 0 };
  const coordinatesExists = coordinates.lat !== 0 || coordinates.lng !== 0;

  if (isAzimuthUnknown.value) {
    locationMessage.push('Азимут: не можливо визначити');
    azimuth.value = '';
  } else if (azimuth.value !== '') {
    locationMessage.push(`Азимут: ${azimuth.value}`);
  }

  if (
    typeof azimuth.value === 'string' &&
    azimuth.value !== '' &&
    isNaN(+azimuth.value)
  ) {
    selectedAzimuth = azimuth.value;
  } else if (azimuth.value !== '' && !isNaN(+azimuth.value)) {
    selectedAzimuth = parseInt(azimuth.value);
  }

  if (isDistanceUnknown.value) {
    locationMessage.push('Дистанція: не можливо визначити');
    distance.value = '';
  } else if (distance.value !== '') {
    locationMessage.push(`Дистанція: ${distance.value} м`);
  }

  if (coordinatesExists) {
    const mgrs = forward([coordinates.lat, coordinates.lng]);
    locationMessage.push(`Координати: ${formatMGRS(mgrs)}`);
  }

  eventCreationStore.$patch({
    locationMessage: locationMessage.join('\n'),
  });

  eventStore.handleLocationSelected({
    distance: distance.value !== '' ? parseInt(distance.value) : -1,
    azimuth: selectedAzimuth,
    lat: coordinates.lat || null,
    lng: coordinates.lng || null,
    mgrs: coordinatesExists
      ? forward([coordinates.lng, coordinates.lat])
      : 'N/A',
  });
};

// Watchers
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) {
      resetState();
    }
  },
);

watch(activePanel, async (newVal) => {
  if (newVal === 1) {
    mapVisible.value = true;
    await nextTick();
    setTimeout(() => {
      if (map.value) {
        map.value.invalidateSize();
      }
    }, 500);
  } else {
    mapVisible.value = false;
  }
});

watch([azimuthError, distanceError], (value) => {
  if (value[0] || value[1]) {
    proceedDisabledButton.value = true;
  } else {
    proceedDisabledButton.value = false;
  }
});

watch(distance, () => {
  distanceError.value = false;
});
</script>

<style scoped>
.map-container {
  height: 500px;
  width: 100%;
}

.distance-section {
  margin-bottom: 16px;
}

.azimuth-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.azimuth-circle {
  width: 100%;
  max-width: 300px;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;

  @media (max-width: 960px) {
    max-width: 220px;
  }
}

.distance-input,
.azimuth-input {
  width: 100%;
}

.header {
  background-color: rgb(6, 26, 40);
  color: white;
}

.header-text {
  font-size: 16px;
  font-weight: bold;
}

.helper-text {
  font-size: 12px;
  color: #757575;
  margin-bottom: 8px;
}

.input-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.distance-input,
.azimuth-input {
  width: 100%;
  opacity: 1;
}

.distance-input:disabled,
.azimuth-input:disabled {
  opacity: 0.7;
}
</style>
