<!-- views/PositionsView.vue -->
<template>
  <div class="map-container">
    <v-row>
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title>
            <h2 class="headline">Список позицій, груп та позивних</h2>
          </v-card-title>

          <v-card-text>
            <!-- Alerts -->
            <v-alert v-if="positionsStore.error" type="error" dense>
              {{ positionsStore.error }}
            </v-alert>

            <!-- Active Positions Alert -->
            <v-alert
              v-if="activePositionsCount > 0"
              class="active-positions-alert mb-4"
              color="warning"
              variant="tonal"
              density="compact"
            >
              <div class="d-flex align-center">
                <div class="warning-icon mr-2">!</div>
                <span class="alert-text"
                  >Актуальні події: {{ activePositionsCount }}</span
                >
              </div>
            </v-alert>

            <template v-if="!positionsStore.hasPositions">
              <v-alert type="warning" dense>
                Позиції не встановлено, зверніться до адміністратора, щоб
                встановити позиції
              </v-alert>
            </template>

            <!-- Position Lists -->
            <template v-if="hasGroupedPositions">
              <template
                v-for="(
                  groups, squadronName
                ) in positionsStore.groupedPositions"
                :key="squadronName"
              >
                <template v-if="groups.nonGrouped.length">
                  <position-list
                    :title="`${squadronName} - Позиції`"
                    :positions="groups.nonGrouped"
                    @select="selectPosition"
                  />
                </template>

                <template v-if="groups.grouped.length">
                  <position-list
                    :title="`${squadronName} - Групи та позивні`"
                    :positions="groups.grouped"
                    @select="selectPosition"
                  />
                </template>
              </template>
            </template>

            <!-- Custom Position Input -->
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="customPositionName"
                  label="Введіть локацію або імʼя того, хто доповідає"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  class="custom-green-btn"
                  :disabled="!customPositionName"
                  @click="handleCustomPosition"
                >
                  ДАЛІ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Event Type Dialog -->
    <v-dialog v-model="showEventTypeDialog" max-width="600px">
      <event-type-selection
        :current-options="eventConfig"
        :dialog="showEventTypeDialog"
        :position="selectedPosition"
        @update:dialog="showEventTypeDialog = $event"
        @event-success="handleEventSuccess"
        @event-error="showAlert($event, 'error')"
        @close="showEventTypeDialog = false"
      />
    </v-dialog>

    <!-- Alert -->
    <v-alert
      v-if="alert.show"
      :type="alert.type"
      dismissible
      @update:modelValue="alert.show = false"
      class="fixed-top"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { usePositionsStore } from '@/stores/positions';
import PositionList from '@/components/PositionList.vue';
import EventTypeSelection from '@/views/EventCreationDialog.vue';
import { eventConfig } from '@/config/configResponse';

const positionsStore = usePositionsStore();
const showEventTypeDialog = ref(false);
const selectedPosition = ref(null);
const customPositionName = ref('');
const alert = ref({
  show: false,
  message: '',
  type: 'info',
});

// Computed properties
const hasGroupedPositions = computed(
  () => Object.keys(positionsStore.groupedPositions).length > 0,
);

const activePositionsCount = computed(() =>
  positionsStore.positions.reduce((count, position) => {
    if (position.squadron) {
      if (position.isUnderAttack) count++;
      if (position.isUnderFire) count++;
    }
    return count;
  }, 0),
);

// Lifecycle hooks
onMounted(async () => {
  await positionsStore.fetchPositions();
  positionsStore.startStatusPolling();
});

onUnmounted(() => {
  positionsStore.stopStatusPolling();
});

// Methods
function selectPosition(position) {
  selectedPosition.value = position;
  showEventTypeDialog.value = true;
}

function handleCustomPosition() {
  if (!customPositionName.value.trim()) return;

  selectedPosition.value = positionsStore.createCustomPosition(
    customPositionName.value,
  );
  showEventTypeDialog.value = true;
  customPositionName.value = ''; // Clear input after selection
}

function handleEventSuccess(message) {
  showAlert(message, 'success');
  positionsStore.fetchPositions(); // Refresh positions after event creation
}

function showAlert(message, type) {
  alert.value = {
    show: true,
    message,
    type,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}
</script>

<style scoped>
.map-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
  background-color: inherit;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  width: 90%;
  max-width: 500px;
}

.custom-green-btn {
  background-color: #4caf50;
  color: white;
  width: 100%;
}

.headline {
  margin-bottom: 16px;
}

.active-positions-alert {
  background-color: #ffb347 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 8px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.warning-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  font-weight: bold;
  font-size: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-text {
  font-size: 16px;
  font-weight: 500;
}
</style>
