import axios from 'axios';
import { logout, refreshToken } from './keycloak';
import { loadEnv } from '@/services/envService';
const MAX_RETRIES = 50; //додатковий запобіжник для уникнення зациклювання
let retryCount = 0;

const axiosClientPromise = loadEnv().then((envData) => {
  const baseURL = envData.VUE_APP_IP;

  const axiosClient = axios.create({
    baseURL: `${baseURL}/api`,
    headers: { 'Content-Type': 'application/json' },
  });

  // Request Interceptor
  axiosClient.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Response Interceptor
  axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;

      if (
        response?.status === 401 &&
        !config._retry &&
        retryCount < MAX_RETRIES
      ) {
        config._retry = true;

        const requestUrl = config?.url || error.request?.responseURL || '';

        if (
          requestUrl.includes('/ui-version') ||
          requestUrl.includes('/positions')
        ) {
          retryCount++;
          console.log('Refreshing token from axios iterceptor...');
          await refreshToken(envData);
          return await axiosClient(config); // Retry request with new token
        }
      } else if (response?.status === 401) {
        console.log('Logging out from axios iterceptor...');
        return logout();
      } else {
        return Promise.reject(error);
      }
    },
  );

  return axiosClient;
});

export class ApiClient {
  constructor(axiosClientPromise) {
    this.axiosClientPromise = axiosClientPromise;
  }

  async getAxiosClient() {
    return await this.axiosClientPromise;
  }

  async getSquadrons() {
    const client = await this.getAxiosClient();
    return client.get('/squadrons');
  }

  async createSquadron(data) {
    const client = await this.getAxiosClient();
    return client.post('/squadrons', data);
  }

  async editSquadron(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/squadrons/${id}`, data);
  }

  async removeSquadron(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/squadrons/${id}`);
  }

  async getBatallions() {
    const client = await this.getAxiosClient();
    return client.get('/battalions');
  }

  async createBatallion(data) {
    const client = await this.getAxiosClient();
    return client.post('/battalions', data);
  }

  async editBatallion(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/battalions/${id}`, data);
  }

  async removeBatallion(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/battalions/${id}`);
  }

  async getPositions() {
    const client = await this.getAxiosClient();
    return client.get('/positions');
  }

  async createPosition(data) {
    const client = await this.getAxiosClient();
    return client.post('/positions', data);
  }

  async editPosition(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/positions/${id}`, data);
  }

  async removePosition(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/positions/${id}`);
  }

  async getAllUsers() {
    const client = await this.getAxiosClient();
    return client.get('/users');
  }

  async createUser(data) {
    const client = await this.getAxiosClient();
    return client.post('/users', data);
  }

  async editUser(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/users/${id}`, data);
  }

  async removeUser(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/users/${id}`);
  }

  async getEvents(page, limit, startDate, endDate) {
    const client = await this.getAxiosClient();
    return client.get('/events', {
      params: {
        page,
        limit,
        startDate,
        endDate,
      },
    });
  }

  async getUavEvents(page, limit, startDate, endDate, battalionId, reporterId) {
    const client = await this.getAxiosClient();
    return client.get('/events/uav', {
      params: {
        page,
        limit,
        startDate,
        endDate,
        battalionId,
        reporterId,
      },
    });
  }

  async createEvent(data) {
    const client = await this.getAxiosClient();
    return client.post('/events', data);
  }

  async indexSignalChats() {
    const client = await this.getAxiosClient();
    return client.get('/index-signal-chats');
  }

  async getMe() {
    const client = await this.getAxiosClient();
    return client.get('/users/me');
  }

  async getVersion() {
    const client = await this.getAxiosClient();
    return client.get('/ui-version');
  }

  async handleExportStats(
    url,
    { startDate, endDate, fileName, reporterId, battalionId },
  ) {
    const client = await this.getAxiosClient();
    try {
      const response = await client.get(url, {
        params: {
          startDate,
          endDate,
          reporterId,
          battalionId,
        },
        responseType: 'blob',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    } catch (e) {
      console.error('Error exporting events:', e);
    }
  }
}

export default new ApiClient(axiosClientPromise);
