import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

import { ref, reactive, watch, computed } from 'vue';
import type { FormState } from '@/types/admin';
import { UserRole } from '@/types/admin';
import { rules } from '@/utils/rules';
import { useAdminStore } from '@/stores/admin';

interface Props {
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['user'];
  availableSquadrons: Array<{ id: string; display: string }>;
  availableRoles: Array<{ value: string; displayName: string }>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateEditUserDialog',
  props: {
    dialogVisible: { type: Boolean },
    isEditing: { type: Boolean },
    form: {},
    availableSquadrons: {},
    availableRoles: {}
  },
  emits: ["update:dialog-visible", "save"],
  setup(__props: any, { emit: __emit }) {

const adminStore = useAdminStore();

const props = __props;

const emit = __emit;

const formRef = ref<any>(null);
const formValid = ref(false);

const localForm = reactive({ ...props.form });

// Watch for external form changes
watch(
  () => props.form,
  (newForm) => {
    Object.keys(newForm).forEach((key) => {
      localForm[key] = newForm[key];
    });
  },
  { deep: true },
);

const passwordRules = computed(() => {
  const baseRules = [
    (v: string) => !!v || 'Пароль обовязковий',
    (v: string) =>
      (v && v.length >= 8) || 'Пароль повинен містити щонайменше 8 символів',
    (v: string) => /[A-Z]/.test(v) || 'Пароль повинен містити великі літери',
    (v: string) => /\d/.test(v) || 'Пароль повинен містити цифри',
  ];

  if (props.isEditing && !localForm.password.length) return [];

  return baseRules;
});
const canEditUsers = computed(() =>
  [UserRole.admin, UserRole.battalion_admin].includes(
    adminStore.currentUser?.role || UserRole.user,
  ),
);

const alert = ref({
  show: false,
  type: 'error' as const,
  message: '',
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.value = {
    show: true,
    type,
    message,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  // Emit the save event with updated form data
  emit('save', props.isEditing, { ...localForm });
}

function handleClose() {
  emit('update:dialog-visible', false);
}

// Reset form validation when dialog opens
watch(
  () => props.dialogVisible,
  (newVal) => {
    if (newVal) {
      formRef.value?.resetValidation();
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update:dialog-visible', $event))),
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          (canEditUsers.value)
            ? (_openBlock(), _createBlock(_component_v_card_title, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Редагування користувача' : 'Створити користувача'), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_v_card_title, { key: 1 }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Перегляд користувача")
                ])),
                _: 1
              })),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.value.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.value.type,
                    dismissible: "",
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (alert.value.show = false)),
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.value.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_form, {
                ref_key: "formRef",
                ref: formRef,
                modelValue: formValid.value,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formValid).value = $event)),
                readonly: !canEditUsers.value
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.username,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localForm.username) = $event)),
                    label: "Нікнейм",
                    rules: [
              _unref(rules).required,
              (v) =>
                /^[a-zA-Z0-9._-]+$/.test(v) ||
                'Дозволені лише літери, цифри, _ - .',
              (v) => (v && v.length >= 3) || 'Мінімальна довжина 3 символи',
              (v) => (v && v.length <= 20) || 'Максимальна довжина 20 символів',
            ],
                    required: "",
                    readonly: _ctx.isEditing
                  }, null, 8, ["modelValue", "rules", "readonly"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.surnameAndInitials,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localForm.surnameAndInitials) = $event)),
                    modelModifiers: { trim: true },
                    label: "Прізвище та ініціали",
                    rules: [_unref(rules).maxLengthForUnrequired(40)]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.callsign,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localForm.callsign) = $event)),
                    modelModifiers: { trim: true },
                    label: "Позивний",
                    rules: [_unref(rules).required, _unref(rules).maxLengthForUnrequired(40)],
                    required: ""
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.rank,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localForm.rank) = $event)),
                    modelModifiers: { trim: true },
                    label: "Посада",
                    rules: [_unref(rules).maxLengthForUnrequired(100)]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.password,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((localForm.password) = $event)),
                    label: "Пароль",
                    type: "password",
                    rules: passwordRules.value,
                    placeholder: 
              _ctx.isEditing ? 'Залиште порожнім для незмінного пароля' : ''
            ,
                    required: ""
                  }, null, 8, ["modelValue", "rules", "placeholder"]),
                  _createVNode(_component_v_select, {
                    modelValue: localForm.role,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((localForm.role) = $event)),
                    label: "Роль",
                    items: _ctx.availableRoles,
                    "item-title": "displayName",
                    "item-value": "value",
                    rules: [_unref(rules).required],
                    required: ""
                  }, null, 8, ["modelValue", "items", "rules"]),
                  _createVNode(_component_v_select, {
                    modelValue: localForm.squadronId,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((localForm.squadronId) = $event)),
                    label: "Пункт управління",
                    items: _ctx.availableSquadrons,
                    "item-title": "display",
                    "item-value": "id",
                    rules: [_unref(rules).required],
                    required: ""
                  }, null, 8, ["modelValue", "items", "rules"])
                ]),
                _: 1
              }, 8, ["modelValue", "readonly"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "elevated",
                color: "black",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" Відмінити ")
                ])),
                _: 1
              }),
              (canEditUsers.value)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    class: "custom-green-btn",
                    onClick: handleSave,
                    disabled: !formValid.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Оновити' : 'Створити'), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})