import { defineStore } from 'pinia';
import { EventPath } from '@/types/events';

export enum InjurySeverity {
  LIGHT = 'Легкий',
  MEDIUM = 'Середній',
  HEAVY = 'Тяжкий',
}

export enum Consciousness {
  CONSCIOUS = 'Притомний',
  UNCONSCIOUS = 'Непритомний',
  CONFUSED = 'Сплутана свідомість',
  RESPONDS_TO_PAIN = 'Реагує тільки на біль',
}

export enum Stability {
  STABLE = 'Стабільний',
  UNSTABLE = 'Нестабільний',
  DETERIORATING = 'Погіршується',
}

export type EventForm300 = {
  id: number;
  callSign: string;
  injuryType: string;
  injurySeverity: InjurySeverity | null;
  stability: Stability | null;
  consciousness: Consciousness | null;
  assistanceProvided: string;
};
// Використовуємо функцію, щоб кожен новий об'єкт був окремим екземпляром,
// а не посиланням на той самий об'єкт у пам'яті.
export const DEFAULT_EVENT_FORM_300 = () => ({
  id: +new Date(),
  callSign: '',
  injuryType: '',
  injurySeverity: null,
  stability: null,
  consciousness: null,
  assistanceProvided: '',
});

interface EventSessionState {
  valid: boolean | null;
  lastEventPaths: {
    [key: string]: EventPath[];
  };
  isCoordinatesFilled: boolean; // Визначає, чи координати були введені (заповнені)
  // Форми 300 та 200
  eventForm300: Array<EventForm300>;
  eventForm200: Array<string>;
}

export const useEventsSessionStore = defineStore('eventsSession', {
  state: (): EventSessionState => ({
    valid: null,
    lastEventPaths: {},
    isCoordinatesFilled: false,
    eventForm300: [DEFAULT_EVENT_FORM_300()],
    eventForm200: [],
  }),

  getters: {
    lastEventPath: (state) => (key: string) => {
      return state.lastEventPaths[key] ?? [];
    },
  },

  actions: {
    setPath(key: string, path: EventPath[]) {
      this.lastEventPaths[key] = path; // Оновлюємо шлях для певного ключа
    },

    reset() {
      this.valid = true;
      this.lastEventPaths = {};
      this.isCoordinatesFilled = false;
      this.eventForm300 = [DEFAULT_EVENT_FORM_300()];
      this.eventForm200 = [];
    },

    resetEventForm() {
      this.eventForm300 = [DEFAULT_EVENT_FORM_300()];
      this.eventForm200 = [];
    },
  },
});
