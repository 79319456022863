export type EventStep =
  | '300'
  | '200'
  | 'constant'
  | 'map'
  | 'notes'
  | 'input'
  | 'input-optional'
  | 'input-without-display-type'
  | 'input-number'
  | 'input-model-count'
  | 'input-type-model-count'
  | 'coordinates-square'
  | 'coordinates-square-target-destruction'
  | 'expenditure-balance'
  | 'killed-wounded-summary';

export type EventCategory = 'single' | 'steps';

export interface EventOptions {
  skipMapStep?: boolean;
  allies?: boolean;
  enemies?: boolean;
  isUnderAttack?: boolean;
  isUnderFire?: boolean;
  category?: EventCategory;
  // Поле saveKey використовуються для збереження значення поля в localStorage по ключу вказаному в saveKey
  saveKey?: string;
}

export interface EventType {
  title?: string;
  name?: string;
  id?: number;
  children?: EventType[];
  type: EventStep;
  displayType?: string;
  options?: EventOptions;
  steps?: Omit<EventType, 'steps'>[];
  // Дозвіл на відображення значення в бредкрамбах замість поля "name"
  shouldDisplayInputValue?: boolean;
  linkedObject?: LinkedObjectType;
  typeOfAmmunitions?: string[];
  // Забезпечує можливість відображення шляху події в компоненті event-last-created, коли вказано значення для displayType
  canShowEventPath?: boolean;
  // Дозволяє почати або продовжити процес створення події, починаючи з поточного етапу.
  startCreatedAtEventPath?: boolean;
  // Ігнорування displayType для повідомлення в чат
  ignoreDisplayType?: boolean;
  // В якій колонці відображати в списку подій
  column?: number;
  // Додає значення до поля в чаті "Подія:", по дефолту використовується значення першого поля "displayType"
  eventMessage?: string;
}

export interface EventLocation {
  distance: number | string;
  azimuth: number | string;
  lat: number | null;
  lng: number | null;
  mgrs: string;
}

export interface EventNotes {
  allies?: string;
  enemies?: string;
  details: string;
  status: string;
}

export interface EventData {
  type: string;
  description: string;
  locationLat: number | null;
  locationLong: number | null;
  notes: string;
  time: string;
  positionId: number;
  distance: number | string;
  azimuth: number | string;
  mgrs: string;
  positionName: string;
  isCustomPosition: boolean;
  selectedId: number;
  chatData: string;
}

export interface Position {
  id: number;
  name: string;
  positionName?: string;
  isCustomPosition?: boolean;
  isUnderAttack?: boolean;
  isUnderFire?: boolean;
  squadron?: {
    name: string;
    battalion?: {
      name: string;
    };
  };
}

export interface Alert {
  show: boolean;
  message: string;
  type: string;
}

export interface Position {
  id: number;
  name: string;
  positionName?: string;
  description?: string;
  isCustomPosition?: boolean;
  isUnderAttack?: boolean;
  isUnderFire?: boolean;
  squadron?: {
    name: string;
    battalion?: {
      name: string;
    };
  };
  mgrs?: string;
}

export interface ApiResponse<T> {
  data: T;
  status: number;
}

export type LinkedObjectType = {
  [key: string]: string | number | null;
  parentObject: string;
};

export type EventPath = {
  step: number;
  selectedDisplayType?: string[];
  selectedLinkedObject?: LinkedObjectType;
} & EventType;

export enum DayIndicator {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

export enum UavPilotEvents {
  'UAV_MISSION_MAVIC_AUTEL_ID' = 500,
}
