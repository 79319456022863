import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useDisplay } from 'vuetify';
import { useEventStore } from '@/stores/event';
import { onBeforeUnmount, onMounted, onUnmounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventButton',
  props: {
    disabled: { type: Boolean },
    class: {},
    back: { type: Boolean },
    enterTrigger: { type: Boolean }
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const eventStore = useEventStore();

const props = __props;

const { smAndUp } = useDisplay();

const emit = __emit;

function handleEnterPress(e: KeyboardEvent) {
  if (e.key !== 'Enter') return;
  if (!props.back && props.enterTrigger && !props.disabled) emit('click');
}

onMounted(() => {
  if (props.enterTrigger) {
    window.addEventListener('keypress', handleEnterPress);
  }
});

onBeforeUnmount(() => {
  if (props.enterTrigger) {
    window.removeEventListener('keypress', handleEnterPress);
  }
});

onUnmounted(() => {
  if (props.enterTrigger) {
    window.removeEventListener('keypress', handleEnterPress);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    ref: "buttonExposeRef",
    class: _normalizeClass(props.class),
    disabled: props.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (props.back ? _unref(eventStore).goBack() : _ctx.$emit('click'))),
    size: _unref(smAndUp) ? 'large' : 'default'
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "disabled", "size"]))
}
}

})