<template>
  <v-dialog
    :model-value="dialogVisible"
    @update:model-value="$emit('update:dialog-visible', $event)"
    max-width="500px"
  >
    <v-card>
      <v-card-title v-if="canEditUsers">
        {{ isEditing ? 'Редагування користувача' : 'Створити користувача' }}
      </v-card-title>
      <v-card-title v-else>Перегляд користувача</v-card-title>

      <v-card-text>
        <v-alert
          v-if="alert.show"
          :type="alert.type"
          dismissible
          @input="alert.show = false"
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>

        <v-form ref="formRef" v-model="formValid" :readonly="!canEditUsers">
          <v-text-field
            v-model="localForm.username"
            label="Нікнейм"
            :rules="[
              rules.required,
              (v) =>
                /^[a-zA-Z0-9._-]+$/.test(v) ||
                'Дозволені лише літери, цифри, _ - .',
              (v) => (v && v.length >= 3) || 'Мінімальна довжина 3 символи',
              (v) => (v && v.length <= 20) || 'Максимальна довжина 20 символів',
            ]"
            required
            :readonly="isEditing"
          />
          <v-text-field
            v-model.trim="localForm.surnameAndInitials"
            label="Прізвище та ініціали"
            :rules="[rules.maxLengthForUnrequired(40)]"
          />

          <v-text-field
            v-model.trim="localForm.callsign"
            label="Позивний"
            :rules="[rules.required, rules.maxLengthForUnrequired(40)]"
            required
          />

          <v-text-field
            v-model.trim="localForm.rank"
            label="Посада"
            :rules="[rules.maxLengthForUnrequired(100)]"
          />

          <v-text-field
            v-model="localForm.password"
            label="Пароль"
            type="password"
            :rules="passwordRules"
            :placeholder="
              isEditing ? 'Залиште порожнім для незмінного пароля' : ''
            "
            required
          />

          <v-select
            v-model="localForm.role"
            label="Роль"
            :items="availableRoles"
            item-title="displayName"
            item-value="value"
            :rules="[rules.required]"
            required
          />

          <v-select
            v-model="localForm.squadronId"
            label="Пункт управління"
            :items="availableSquadrons"
            item-title="display"
            item-value="id"
            :rules="[rules.required]"
            required
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn variant="elevated" color="black" @click="handleClose">
          Відмінити
        </v-btn>
        <v-btn
          v-if="canEditUsers"
          class="custom-green-btn"
          @click="handleSave"
          :disabled="!formValid"
        >
          {{ isEditing ? 'Оновити' : 'Створити' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, watch, computed } from 'vue';
import type { FormState } from '@/types/admin';
import { UserRole } from '@/types/admin';
import { rules } from '@/utils/rules';
import { useAdminStore } from '@/stores/admin';

const adminStore = useAdminStore();

interface Props {
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['user'];
  availableSquadrons: Array<{ id: string; display: string }>;
  availableRoles: Array<{ value: string; displayName: string }>;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:dialog-visible', value: boolean): void;
  (e: 'save', isEdit: boolean, formData: FormState['user']): void;
}>();

const formRef = ref<any>(null);
const formValid = ref(false);

const localForm = reactive({ ...props.form });

// Watch for external form changes
watch(
  () => props.form,
  (newForm) => {
    Object.keys(newForm).forEach((key) => {
      localForm[key] = newForm[key];
    });
  },
  { deep: true },
);

const passwordRules = computed(() => {
  const baseRules = [
    (v: string) => !!v || 'Пароль обовязковий',
    (v: string) =>
      (v && v.length >= 8) || 'Пароль повинен містити щонайменше 8 символів',
    (v: string) => /[A-Z]/.test(v) || 'Пароль повинен містити великі літери',
    (v: string) => /\d/.test(v) || 'Пароль повинен містити цифри',
  ];

  if (props.isEditing && !localForm.password.length) return [];

  return baseRules;
});
const canEditUsers = computed(() =>
  [UserRole.admin, UserRole.battalion_admin].includes(
    adminStore.currentUser?.role || UserRole.user,
  ),
);

const alert = ref({
  show: false,
  type: 'error' as const,
  message: '',
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.value = {
    show: true,
    type,
    message,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  // Emit the save event with updated form data
  emit('save', props.isEditing, { ...localForm });
}

function handleClose() {
  emit('update:dialog-visible', false);
}

// Reset form validation when dialog opens
watch(
  () => props.dialogVisible,
  (newVal) => {
    if (newVal) {
      formRef.value?.resetValidation();
    }
  },
);
</script>
