<template>
  <div
    :data-type="item.type ?? 'constant'"
    :data-name="item.name"
    :class="eventStore.path.length === 0 ? 'pa-1' : 'event-item pa-0'"
  >
    <!-- Рендеримо кнопки -->
    <v-list-item
      v-if="!item.type || item.type === 'constant'"
      class="list-item-button"
      @click="eventStore.handleSelection(item)"
      :title="item?.title || item.name"
      :data-pw="item.name"
    />

    <!-- Поле вводу -->
    <event-input
      v-else-if="
        item.type === 'input' ||
        item.type === 'input-optional' ||
        item.type === 'input-without-display-type'
      "
      :type="item.type"
      :displayType="item.displayType"
      :linkedObject="item.linkedObject"
      :label="item.name!"
      v-model="eventStore.inputTypeValue"
      @update:model-value="() => (eventStore.selectedItem = item)"
      @input="handleInput"
    />

    <event-input-number
      v-else-if="item.type === 'input-number'"
      :label="item.name!"
      v-model="eventStore.inputTypeValue"
      @update:model-value="() => (eventStore.selectedItem = item)"
    />

    <!-- Координати або квадрат -->
    <event-coordinates-square-from
      v-else-if="
        item.type === 'coordinates-square' ||
        item.type === 'coordinates-square-target-destruction'
      "
      :type="item.type"
      @input="handleInput"
    />

    <!-- Витрати та залишок -->
    <event-expenditure-balance
      v-else-if="item.type === 'expenditure-balance'"
      @input="handleInput"
    />

    <event-killed-and-wounded-summary
      v-else-if="item.type === 'killed-wounded-summary'"
      @input="handleInput"
    />

    <event-model-count
      v-if="item.type === 'input-model-count'"
      :ammoType="(item.linkedObject!.ammunitionType as string)"
      @input="handleInput"
    />

    <event-type-model-count
      v-if="item.type === 'input-type-model-count'"
      :typeOfAmmunitions="item.typeOfAmmunitions"
      @input="handleInput"
    />

    <event-form-300 v-if="item.type === '300'" @input="handleInput" />

    <event-form-200 v-if="item.type === '200'" @input="handleInput" />

    <!-- Карта -->
    <map-selection-view
      v-if="item.type === 'map'"
      :dialog="dialog"
      @update:dialog="$emit('closeDialog')"
    >
      <template v-slot:title>
        <event-breadcrumbs @close-dialog="$emit('closeDialog')" />
      </template>
    </map-selection-view>

    <!-- Нотатки -->
    <event-notes-form
      v-else-if="item.type === 'notes'"
      :position="eventStore.positionData"
      :eventMessage="eventStore.eventMessage"
      :show-allies="Boolean(eventStore.options?.allies)"
      :show-enemies="Boolean(eventStore.options?.enemies)"
    />
  </div>
</template>

<script setup lang="ts">
import type { EventType, LinkedObjectType } from '@/types/events';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';
import EventInput from '@/components/EventCreation/EventInput.vue';
import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';
import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventKilledAndWoundedSummary from '@/components/EventCreation/EventKilledAndWoundedSummary.vue';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventTypeModelCount from '@/components/EventCreation/EventTypeModelCount.vue';
import EventModelCount from '@/components/EventCreation/EventModelCount.vue';
import EventForm300 from '@/components/EventCreation/EventForm300.vue';
import EventForm200 from '@/components/EventCreation/EventForm200.vue';
import { useEventStore } from '@/stores/event';

const eventStore = useEventStore();

const props = defineProps<{
  dialog: boolean;
  item: EventType;
}>();

defineEmits<{
  closeDialog: () => void;
}>();

function handleInput(value: string[], linkedObject?: LinkedObjectType) {
  eventStore.handleInput(value, props.item, linkedObject);
}
</script>
<style scoped>
.event-item + .event-item {
  margin-top: 8px;
}

.list-item-button {
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 12px 16px;
}

.list-item-button:hover {
  background-color: #d0d0d0;
}
</style>
