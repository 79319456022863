<template>
  <v-data-table-server
    :headers="headers"
    :items="eventsStore.formattedUavEvents"
    :items-length="eventsStore.uavEventsCount"
    :items-per-page="itemsPerPageLocal"
    :loading="eventsStore.loading"
    :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }"
    class="elevation-1"
    item-value="id"
    @update:options="fetchFilteredEvents"
    :items-per-page-options="[10, 25, 50, 100]"
    :disable-sort="true"
  >
    <template v-slot:header.battalion>
      <v-menu location="end">
        <template v-slot:activator="{ props }">
          <div class="text-start d-flex ga-1" v-bind="props">
            Підрозділ
            <v-icon :color="battalionSelected.length ? 'primary' : 'default'"
              >mdi-filter</v-icon
            >
          </div>
        </template>
        <v-list
          v-model:selected="battalionSelected"
          @update:selected="reporterSelected = []"
        >
          <v-list-item
            v-for="battalion in battalions"
            :key="battalion.id"
            :value="battalion.id"
          >
            <v-list-item-title>{{ battalion.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:header.reporter>
      <v-menu location="end">
        <template v-slot:activator="{ props }">
          <div class="text-start d-flex ga-1" v-bind="props">
            Пілот
            <v-icon :color="reporterSelected.length ? 'primary' : 'default'"
              >mdi-filter</v-icon
            >
          </div>
        </template>
        <v-list v-model:selected="reporterSelected">
          <v-list-item
            v-for="reporter in reporters"
            :key="reporter.id"
            :value="reporter.id"
          >
            <v-list-item-title>{{ reporter.callsign }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRefs, defineEmits } from 'vue';
import { UserRole } from '@/types/admin';
import { useEventsStore } from '@/stores/events';
import { useAdminStore } from '@/stores/admin';

const adminStore = useAdminStore();
const eventsStore = useEventsStore();
const emit = defineEmits(['battalionId', 'reporterId']);

const battalionSelected = ref<string[]>([]);
const reporterSelected = ref<string[]>([]);
const itemsPerPageLocal = ref(50);

const props = defineProps({
  startDate: {
    type: [String, null],
  },
  endDate: {
    type: [String, null],
  },
});
const { startDate, endDate } = toRefs(props);

const reporters = computed(() =>
  adminStore.filteredUsers.filter((u) => {
    if (UserRole.uav_pilot !== u.role) return false;

    if (battalionSelected.value[0])
      return u.squadron?.battalionId === battalionSelected.value[0];
    else return true;
  }),
);

const battalions = computed(() =>
  adminStore.battalions.map(({ id, name }) => ({ id, name })),
);

// Watch for changes and trigger data fetch
watch(
  [battalionSelected, reporterSelected, startDate, endDate],
  () => {
    eventsStore.fetchUavEvents({
      page: 1,
      limit: itemsPerPageLocal.value,
      startDate: startDate?.value,
      endDate: endDate?.value,
      battalionId: battalionSelected.value[0],
      reporterId: reporterSelected.value[0],
    });
  },
  { deep: true },
);

watch(battalionSelected, (newVal) => {
  emit('battalionId', newVal[0]);
});
watch(reporterSelected, (newVal) => {
  emit('reporterId', newVal[0]);
});

const fetchFilteredEvents = ({
  page,
  itemsPerPage,
}: {
  page: number;
  itemsPerPage: number;
}) => {
  itemsPerPageLocal.value = itemsPerPage;
  eventsStore.fetchUavEvents({
    page,
    limit: itemsPerPage,
    startDate: startDate?.value,
    endDate: endDate?.value,
    battalionId: battalionSelected.value[0],
    reporterId: reporterSelected.value[0],
  });
};

const headers = [
  { title: 'Системний час (UTC)', key: 'createdAt' },
  { title: 'Час доповіді', key: 'time' },
  { title: 'Місія', key: 'mission' },
  { title: 'Підрозділ', key: 'battalion' },
  { title: 'Пілот', key: 'reporter' },
  { title: 'Засіб', key: 'uavType' },
  { title: 'БК', key: 'ammunitionType' },
  { title: 'Ціль', key: 'target' },
  { title: 'Опис цілі', key: 'targetDetails' },
  { title: '300', key: 'wounded' },
  { title: '~300', key: 'possibleWounded' },
  { title: '200', key: 'killed' },
  { title: '~200', key: 'possibleKilled' },
  { title: 'Результат', key: 'result' },
  { title: 'Деталі', key: 'resultDetails' },
  { title: 'Координати', key: 'coordinates' },
  { title: 'Квадрат', key: 'square' },
  { title: 'Стрім', key: 'stream' },
  { title: 'Нотатки', key: 'notes' },
];
</script>

<style scoped>
.mgrs-column-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.v-data-table-header th) {
  font-weight: bold !important;
  background-color: #f5f5f5;
  text-align: center;
}
</style>
