import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateEditPositionDialog',
  props: {
    dialogVisible: { type: Boolean },
    isEditing: { type: Boolean },
    form: {},
    availableSquadrons: {}
  },
  emits: ["update:dialog-visible", "save"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const formRef = ref<any>(null);
const formValid = ref(false);
const localForm = reactive({ ...props.form });

const alert = reactive({
  show: false,
  type: 'error' as const,
  message: '',
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.show = true;
  alert.type = type;
  alert.message = message;

  setTimeout(() => {
    alert.show = false;
  }, 3000);
}

function handleClose() {
  emit('update:dialog-visible', false);
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  emit('save', props.isEditing, { ...localForm });
}

// Watch for form changes
watch(
  () => props.form,
  (newForm) => {
    Object.keys(newForm).forEach((key) => {
      localForm[key] = newForm[key];
    });
  },
  { deep: true },
);

// Watch for dialog visibility
watch(
  () => props.dialogVisible,
  (newVal) => {
    if (newVal) {
      Object.keys(props.form).forEach((key) => {
        localForm[key] = props.form[key];
      });
      formRef.value?.resetValidation();
    }
  },
);

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:dialog-visible', $event))),
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEditing
            ? 'Редагувати позицію, групу, позивний'
            : 'Створити позицію, групу, позивний'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.type,
                    dismissible: "",
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (alert.show = false)),
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_form, {
                ref_key: "formRef",
                ref: formRef,
                modelValue: formValid.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formValid).value = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localForm.name) = $event)),
                    label: "Назва",
                    rules: [(v) => !!v || 'Назва обовязкова'],
                    required: ""
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.description,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localForm.description) = $event)),
                    label: "Опис"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_select, {
                    modelValue: localForm.squadronId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localForm.squadronId) = $event)),
                    label: "Пункт управління",
                    items: _ctx.availableSquadrons,
                    "item-title": "display",
                    "item-value": "id",
                    rules: [(v) => !!v || 'Пункт управління обовязковий'],
                    required: ""
                  }, null, 8, ["modelValue", "items", "rules"]),
                  _createVNode(_component_v_checkbox, {
                    modelValue: localForm.isGroup,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localForm.isGroup) = $event)),
                    label: "Група чи позивний",
                    "true-value": true,
                    "false-value": false
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "elevated",
                color: "black",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Відмінити ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: handleSave,
                disabled: !formValid.value
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Зберегти ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})