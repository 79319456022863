import { DayIndicator } from '@/types/events';

export function getCurrentTime(): string {
  const now = new Date();
  return `${String(now.getHours()).padStart(2, '0')}:${String(
    now.getMinutes(),
  ).padStart(2, '0')}`;
}

export function getCurrentTimeWithSeconds(): string {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}.${seconds}`;
}

export function formatDate(
  dayIndicator?: DayIndicator,
  locale: string = 'en-CA',
): string {
  const date = new Date();

  if (dayIndicator === DayIndicator.PREVIOUS) {
    date.setDate(date.getDate() - 1);
  }

  return new Intl.DateTimeFormat(locale, {
    timeZone: 'Europe/Kyiv',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
}

export function isTimeMoreThanOneHourInFuture(timeString: string): boolean {
  if (!timeString) return false;
  const currentTime = new Date();
  const [hours, minutes] = timeString.split(':').map(Number);
  if (!hours || !minutes) return false;

  const futureTime = new Date(currentTime);
  futureTime.setHours(hours, minutes, 0, 0);
  currentTime.setMinutes(currentTime.getMinutes() + 60);

  return futureTime > currentTime;
}
